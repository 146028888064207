import Vue from 'vue/dist/vue.esm'
import 'packs/date.js'
import 'packs/star-rating.min.js'
import 'packs/jquery.socialSharers.js'
import moment from 'moment'
const EventBus = new Vue();
import {bus} from './eventBus.js'
import videojs from 'video.js'
import VLazyImage from "v-lazy-image/v2"

var itemMixins = {
  data: function() {
    var product, canceled, in_progress, thumbnail_url,  eid, cancel_url, enrolled_item, available_item, product_question_url, user_display_name, user_designation, user_img_url, participants_img, image_large_url, image_thumbnail_url
    var progress_percent = 0;
    var visibility_hidden = false;
    var edit_url = '';
    product = this.item;
    var added_to_library = product.added_to_library;
    var product_item_id = this.item.id;
    var image_urls = product.image_urls
    while(product_item_id >= 20) {
      product_item_id = product_item_id%20;
    }
    // product_item_id = product_item_id + 1;
    // thumbnail_url = "/images/single_page/nit_"+product_item_id.toString()+'.jpg'
    if(image_urls != ''){
      thumbnail_url = image_urls["medium"]["url"]
      image_large_url = image_urls["large"]["url"]
      image_thumbnail_url = image_urls["thumb"]["url"]
    }
    else{
      if(product.item_type == 'LiveEvent'){
        thumbnail_url = "/images/icons/live_event_thumb.png"
        image_large_url = "/images/icons/live_event_thumb.png"
        image_thumbnail_url = "/images/icons/live_event_thumb.png"
      }
      else if(product.item_type == 'InPersonEvent'){
        thumbnail_url = "/images/icons/in_person_event_thumb.png"
        image_large_url = "/images/icons/in_person_event_thumb.png"
        image_thumbnail_url = "/images/icons/in_person_event_thumb.png"
      }
      else if(product.item_type == 'Course'){
        thumbnail_url = "/images/icons/learnexa-web-explorer.png"
        image_large_url = "/images/icons/learnexa-web-explorer.png"
        image_thumbnail_url = "/images/icons/learnexa-web-explorer.png"
      }
      else if(product.item_type == 'Certification'){
        thumbnail_url = "/images/icons/certification_preview_icon.png"
        image_large_url = "/images/icons/certification_preview_icon.png"
        image_thumbnail_url = "/images/icons/certification_preview_icon.png"
      }
      // else {
      //   thumbnail_url = "/images/single_page/nit_"+product_item_id.toString()+'.jpg'
      //   image_large_url = "/images/single_page/nit_"+product_item_id.toString()+'.jpg'
      //   image_thumbnail_url = "/images/single_page/nit_"+product_item_id.toString()+'.jpg'
      // }
    }
    if (this.shared_item && this.shared_item == this.item.id) {
      var self = this;
    }
    if(Object.keys(product.enrollment).length > 0) {
      if(product.enrollment.canceled) {
        canceled = true;
      } else {
        enrolled_item = true;
      }
      if (product.enrollment.visibility_hidden) {
        visibility_hidden = true;
      }
      // thumbnail_url = "/images/single_page/2.png";
      progress_percent = Math.floor(product.enrollment.progress_percent);
      eid =  product.enrollment.id;
      cancel_url = product.enrollment.cancel_url;
      product_question_url = product.enrollment.product_questions_path;
    } else {
      // thumbnail_url = "/images/single_page/1.png";
      available_item = true;
      edit_url = product.edit_url;
    }
    return {
      product_id: product.id,
      progress_id: "progress_" + product.id + new Date().getTime(),
      //thumbnail_url: product.thumbnail_url,
      thumbnail_url: thumbnail_url,
      image_large_url: image_large_url,
      image_thumbnail_url: image_thumbnail_url,
      title: product.title,
      description: this.strip_html_tag(product.description),
      created_by: 'By' + ' ' + product.creator_firstname + ' ' + product.creator_lastname + ',  ' + moment(new Date(product.created_at)).format('MMM D, YYYY'),
      item_created_by: "item_created_by_" + new Date().getTime(),
      event_start_date: product.start_date,
      enrolled_item: enrolled_item || false,
      canceled: canceled || false,
      item_share_link: product.item_share_link,
      // available_item_more_link_data: product.available_item_more_link_data,
      // edit_privilege: product.edit_privilege,
      // preview_privilege: product.preview_privilege,
      progress_percent: progress_percent,
      enrolled_item_id: "enrolled_item_" + product.id + new Date().getTime(),
      available_item_id: "available_item_" + product.id + new Date().getTime(),
      enrolled_item_more_action_id: "enrolled_item_more_action_" + product.id + new Date().getTime(),
      available_item_more_action_id: "available_item_more_action_" + product.id + new Date().getTime(),
      available_item_more_action_share_id: "available_item_more_action_share_" + product.id + new Date().getTime(),
      enrollment_id: eid,
      cancel_url: cancel_url,
      product_question_url: product_question_url,
      item_id: "item_" + new Date().getTime(),
      item_class: product.item_type + "_" + product.id,
      item_title_id: "item_title_" + new Date().getTime(),
      item_description_id: "item_description_" + new Date().getTime(),
      bookmarks_url: product.bookmarks_url,
      bookmarked: product.bookmarked || false,
      ratings_url: product.ratings_url,
      rates_count: product.rates_count,
      comments_url: product.comments_url,
      ratings_id: "ratings_" + product.id + new Date().getTime(),
      average_rating: product.average_rating,
      rated_by_user: product.rated_by_user,
      user_rating: product.user_rating,
      available_item: available_item || false,
      hide_more_icon: !this.logged_in || canceled,
      permalink: product.permalink,
      modal_id: "modal_" + new Date().getTime(),
      product: product,
      sharePopupOpen: false,
      shareable_id: product.id,
      from_recommendation: this.from == 'recommendation',
      from_search: this.from == 'search',
      newCommentPopupOpen: false,
      item_type: product.item_type,
      visibility_hidden: visibility_hidden,
      contents_list: [],
      content_obj: {},
      refresh_progress_url: '',
      open_questions: [],
      popular_questions: [],
      questions_list: [],
      create_question_url: '',
      best_question_count: 0,
      unanswered_question_count: 0,
      questions_details: {},
      edit_item_details: {},
      best_questions_more_link: '',
      open_questions_more_link: '',
      // edit_url: edit_url,
      // edit_popup: false,
      circular_progress: '',
      show_content: 0,
      result_response: {},
      result_have_response: false,
      select_thumbnail_url : '',
      content_loaded: false,
      enrollments_count: product.enrollments_count,
      no_of_comments: product.no_of_comments,
      add_to_playlist: product.add_to_playlist,
      can_be_bookmarked: product.can_be_bookmarked,
      bookmark_cancel_url: product.bookmark_cancel_url,
      free: product['free?'],
      price: (added_to_library ? 0 : this.number_to_currency(product.price)),
      added_to_library: added_to_library,
      added_to_cart: (added_to_library ? false : product.added_to_cart),
      paid_subscription: (added_to_library ? false : product.paid_subscription),
      valid_subscription: (added_to_library ? false : product.valid_subscription),
      subscribe_only: (added_to_library ? false : product.subscribe_only),
      can_add_to_cart: (added_to_library ? false : product.can_add_to_cart),
      add_to_library_path: (added_to_library ? '' : product.add_to_library_path),
      add_to_cart_path: (added_to_library ? '' : product.add_to_cart_path),
      id: '',
      share_popup_id: "share_popup_" + new Date().getTime(),
    }
  },
  methods: {
    show_details: function(play) {
      var self = this;
      this.$root.show_course_details = true;
      $('#all_items_lightbox').modal('hide');
      if (play) {
        this.$root.$children[0].launch_item = true;
      }
      var data = {item_id: this.item_id, product: this.product, thumbnail_url: this.thumbnail_url,
              bookmarked: this.bookmarked, bookmark_id: this.bookmark_id, catalog: !(this.enrolled_item),
              average_rating: this.average_rating, rated_by_user: this.rated_by_user,
              user_rating: this.user_rating, rates_count: this.rates_count,
              logged_in: this.logged_in, container_name: this.container_name, search: this.search,
              enrollments_count: this.enrollments_count, image_large_url: this.image_large_url, image_thumbnail_url: this.image_thumbnail_url
             }
      setTimeout(function() {
        var obj = self.$store.state.all_items_container;
        obj.$emit('open-details-modal', data);
        window.scrollTo(0,0);
      },500);
    },
    strip_html_tag: function(html) {
      var tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent||tmp.innerText;
    },
    update_progress: function(progress) {
      const element = document.getElementById(this.progress_id);
      if(this.circular_progress == '' && element)
        this.circular_progress = circle_animation("#"+this.progress_id,'#52e471','#c5c5c5',7)
      if (element)
        this.circular_progress.animate(progress/100);
    },
    outside: function(e) {
      if(e.target.id != this.available_item_more_action_share_id && e.target.id != (this.available_item_more_action_share_id+'share_item_filter_popup')
         && e.target.id != (this.available_item_more_action_id + "share") && !hasClass(e.target,'stop_close') && !hasClass(e.target, 'rm')) {
        this.sharePopupOpen = false;
      }
      if(e.target.id != this.item_id+'_new_comment' && e.target.id != (this.available_item_more_action_id + "comment") && !hasClass(e.target,'stop_close')) {
        this.newCommentPopupOpen = false;
      }
    },
    continue_browsing: function() {
      if (jQuery(".animation_started")[0]) {
        jQuery(".animation_started").removeClass("animation_started");
      }
      jQuery('.continue_shopping').first().remove();
      jQuery('.play_progress').first().removeClass('hide');
      var scrollTop = jQuery("#available_items").offset().top;
      jQuery('html, body').animate({ scrollTop: scrollTop },1000);
    },
    close_button: function() {
      if (jQuery(".animation_started")[0]) {
        jQuery(".animation_started").removeClass("animation_started");
      }
      jQuery('.continue_shopping').first().remove();
      jQuery('.play_progress').first().removeClass('hide');
    },
    openSharePopup: function() {
      if (this.from_catalog) {
        this.$parent.$parent.$parent.open(this.product, this.share_popup_id, true)
      } else {
        this.$parent.open(this.product, this.share_popup_id, true)
      }
    },
    play_course: function () {
      this.$root.show_lightbox = false;
      var from_page;
      if(this.from == 'search' || this.container_name == 'available_items_container' || this.container_name == 'recommended_items_container') {
        from_page = 'preview'
      }
      this.$store.state.all_items_container.play_course(this.item, this.item_id, this.progress_percent, from_page);
    },
    add_to_library: function() {
      var self = this;
      if (this.logged_in) {
        if (!this.added_to_cart) {
          if (this.free) {
            self.add_to_my_learning();
          }
          else {
            this.$http.post(this.add_to_cart_path).then(response => {
              if (response.body) {
                jQuery('#badge_cart').show()
                jQuery('#badge_cart').html(response.body.cart_size)
                this.added_to_cart = true
                this.$root.popUpMessage('Added to cart Successfully')
              }
            });
          }
        }
      } else {
        window.scrollTo(0, 0);
        $('#login_modal').modal('show');
      }
    },
    add_to_my_learning: function() {
      var self = this;
      this.$http.post(this.add_to_library_path).then(response => {
        if (response.body) {
          self.$parent.$parent.$parent.items = [];
          this.$root.popUpMessage('Enrolled Successfully');
          var params = self.$parent.$parent.$parent.$children[1].requestParams()
          self.$http.get('/contents.json?'+params).then(function(response) {
            self.$parent.$parent.$parent.items = [];
            self.$parent.$parent.$parent.items = response.body['catalog_items'];
            self.$parent.$parent.$parent.menu_filters = response.body['catalog_tabs'];
          });
          self.added_to_library = true;
          bus.$emit('enrollment',response.body);
          setTimeout(function(){
            truncateItemText("enrolled_items");
          },500);
        }
        else {
          self.added_to_library = true;
        }
      });
    },
    number_to_currency: function(int) {
      return (int).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    cancel_enrollment: function() {
      this.$modal.show('dialog', {
        text: 'Are you sure you want to cancel the enrollment ?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.$http.delete(this.cancel_url).then(response => {
                // jQuery("#"+this.item_id).remove();
                this.$root.popUpMessage('Enrollment Removed');
                bus.$emit('enrollment',response.body);
                jQuery('#All').click();
              });
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    onBookmarkClick: function(event) {
      if (this.logged_in) {
        var self = this.details ? this.$parent.$parent : this.$parent;
        this.$http.post(this.bookmarks_url).then(response => {
          self.bookmark_cancel_url = response.body.bookmark_cancel_url
          self.product.bookmark_cancel_url = response.body.bookmark_cancel_url
          self.bookmarked = true
          self.product.bookmarked = true
          self.$root.popUpMessage('Bookmarked Successfully');
        });
      }
    },
    removeBookmark: function() {
      if (this.logged_in) {
        var self = this.details ? this.$parent.$parent : this.$parent;
        this.$http.delete(this.bookmark_cancel_url).then(response => {
          self.bookmark_cancel_url = ''
          self.bookmarked = false
          self.product.bookmark_cancel_url = ''
          self.product.bookmarked = false
          self.$root.popUpMessage('Bookmark Removed');
        });
      }
    }
  }
}

var itemCourseDetailMixins = {
  data: function() {
    return {
      modal_id: 'item_details_lightbox',
      item_id: '',
      product: {},
      thumbnail_url: '',
      permalink: '',
      items: {},
      similar_products_item: [],
      current_page: 0,
      total_pages: 0,
      next_icon: false,
      prev_icon: false,
      ratings_url: '',
      rates_count: 0,
      ratings_id: "ratings_" + new Date().getTime(),
      average_rating: 0,
      rated_by_user: false,
      user_rating: 0,
      bookmarked: false,
      bookmarks_url: '',
      bookmark_cancel_url: '',
      score_breakup: {},
      total_ratings: 0,
      content_loaded: false,
      comments_url: '',
      comments: [],
      current_user_details: {},
      comments_loaded: false,
      pagination: {page: 1, total_pages: 1},
      comments_count: 0,
      sharePopupOpen: false,
      shareModalOpen: false,
      invite: 'invite',
      share_popup_id: "share_popup_" + new Date().getTime(),
      catalog: '',
      enrollments_count: 0,
      courses_notes: [],
      notes_loaded: false,
      added_to_cart: false,
      paid_subscription: false,
      valid_subscription: false,
      subscribe_only: false,
      can_add_to_cart: false,
      add_to_library_path: false,
      add_to_cart_path: false,
      show_bookmark: false
    }
  },
  methods: {
    open: function(data) {
      this.product = data.product;
      this.permalink = data.product.permalink;
      this.item_id = data.item_id;
      this.thumbnail_url = data.image_large_url;
      this.ratings_url = this.product.ratings_url;
      this.rates_count = data.rates_count;
      this.average_rating = data.average_rating;
      this.rated_by_user = data.rated_by_user;
      this.user_rating = data.user_rating;
      this.bookmarked = data.bookmarked;
      this.bookmarks_url = this.product.bookmarks_url;
      this.bookmark_cancel_url = this.product.bookmark_cancel_url;
      this.comments_url = this.product.comments_url;
      this.catalog = data.catalog;
      this.content_loaded = true;
      this.enrollments_count = data.enrollments_count;
      var added_to_library = data.product.added_to_library;
      this.added_to_cart = (added_to_library ? false : data.product.added_to_cart);
      this.paid_subscription = (added_to_library ? false : data.product.paid_subscription);
      this.valid_subscription = (added_to_library ? false : data.product.valid_subscription);
      this.subscribe_only = (added_to_library ? false : data.product.subscribe_only);
      this.can_add_to_cart = (added_to_library ? false : data.product.can_add_to_cart);
      this.add_to_library_path = (added_to_library ? '' : data.product.add_to_library_path);
      this.add_to_cart_path = (added_to_library ? '' : data.product.add_to_cart_path);
      // jQuery(".loader").hide();
      // jQuery("#"+this.modal_id).modal('show');
      this.load_comments(false);
      this.load_notes();
      this.FetchContent();
      this.image_urls = data.product.image_urls
      if (this.logged_in && (this.catalog || this.product.enrollment.progress_percent == '100') ) {
        this.show_bookmark = true
      }
    },
    load_comments: function(newest, paginate) {
      var self = this;
      var params = newest ? '&order_by=newest' : '&order_by=helpful';
      params += paginate ? '&page='+self.pagination.page : '&page=1';
      self.$http.get(self.comments_url+params).then(response => {
        if(paginate) {
          self.comments = self.comments.concat(response.body.comments);
        } else {
          self.comments = response.body.comments;
          self.comments_count = response.body.comments_count;
          jQuery("#comments_cont").animate({"scrollTop": 0});
        }
        self.current_user_details = response.body.current_user_details;
        self.pagination = response.body.pagination;
        self.comments_loaded = true;
      });
    },
    resetData: function() {
      jQuery.extend(this.$data, this.$options.data.apply(this));
      this.$root.show_lightbox = true;
      if(jQuery("#comments_cont").getNiceScroll()[0])
        jQuery("#comments_cont").getNiceScroll()[0].remove();
    },
    outside: function(e) {
      if(e.target.id != 'item_details_share' && !hasClass(e.target, 'rm') && !hasClass(e.target,'stop_close')) {
        this.sharePopupOpen = false;
      }
    },
    load_notes() {
      var self = this
      self.$http.get('/get_course_details/'+self.product.item_type+'/'+self.product.id).then(function(response) {
        self.courses_notes = response.body.notes
        self.notes_loaded = true
      });
    }
  }
}

var itemShareMixins = {
  data: function() {
    var obj = this.item_share_link;
    return {
      popup_id: 'popup_id_' + new Date().getTime(),
      share_type_open: 'invite',
      link_item: obj.product_share_link,
      shareable_type: obj.shareable_type,
      product_recommendation_path: obj.product_recommendation_path,
      embed_item: obj.embed_code,
      embed_url: obj.embed_url,
      product_permission_path: obj.product_permission_path,
      show_share_for_edit: (obj.product_permission_path ? true : false)
    }
  },
  methods: {
    onClick: function(type, event) {
      var el = jQuery(event.target);
      jQuery('.share_links_cont span').removeClass('selected');
      el.addClass('selected');
      if(!this.logged_in && type == 'invite') {
        show_login_popup(this.$root);
      } else {
        this.share_type_open = type;
      }
      if(type == "social"){
        setTimeout(function() {
          jQuery("#social_media_share_icons").socialSharers();
        }, 50);
      }
    },
    truncate_share_strings: function(){
      jQuery('#'+this.share_popup_id + ' .string_trunk8').each(function(index){
        initTrunk8Faded(this, false, 1);
      });
    }
  }
}

var itemCommentMixins = {
  data: function() {
    return {
      ratings_id: "ratings_" + new Date().getTime(),
      likes_count: this.comment.likes_count,
      liked: this.comment.liked,
      like_unlike_url:this.comment.like_unlike_url,
      delete_url: this.comment.delete_url,
      replies: this.comment.comments,
      comment_id: this.comment.id,
      reply_to_comment: false,
      hide_reply: false,
      truncate: true
    }
  },
  mounted: function() {
    ellipsis('#all_comment_text_'+this.comment_id, 3, {
      responsive: true,
      replaceStr: '<span onClick="ShowMore(\''+'comment_truncate'+this.comment_id+'\')" data-toggle="tooltip" title="Click to see more." class="cursor_pointer">...</span>'
    });
  },
  methods: {
    ToggleContent: function() {
      this.truncate = !this.truncate;
    },
    reply_comment: function(url, liked) {
      var self = this;
      self.reply_to_comment = true;
    },
    like_unlike_comment: function(url) {
      var self = this;
      if(self.logged_in) {
        if(self.liked) {
          self.$http.delete(url).then(response => {
            self.likes_count = response.body.likes_count;
            self.liked = response.body.liked;
            self.like_unlike_url = response.body.like_url;
          });
        } else {
          self.$http.post(url).then(response => {
            if(response.body) {
              self.likes_count = response.body.likes_count;
              self.liked = response.body.liked;
              self.like_unlike_url = response.body.unlike_url;
            }
          });
        }
      } else {
        show_login_popup(self.$root);
      }
    },
    delete_comment: function(){
      this.$modal.show('dialog', {
        text: 'Are you sure you want to delete the Comment ?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.$http.delete(this.delete_url).then(response => {
                if(response.body) {
                  if (this.$parent.comments != undefined) {
                    var index = this.$parent.comments.indexOf(this.comment)
                    this.$parent.comments.splice(index, 1)
                  } else {
                    var index = this.$parent.replies.indexOf(this.comment)
                    this.$parent.replies.splice(index, 1)
                  }
                } else {
                  this.$root.popUpMessage('Comment could not be deleted')
                }
                this.$root.popUpMessage('Deleted Successfully');
              });
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    }
  }
}

Vue.component('item-tile', {
  template: `<div v-bind:id="item_id" :class="[{'col-lg-3 col-md-3 col-sm-4 col-xs-6 px-0 ml-3 mr-2': (!from_recommendation && !from_search && !from_catalog), 'col-lg-3 col-md-4 col-sm-12 lxa_similar_course_wrapper': from_similar_course, 'group_product_tile_width': from_group,'pl-0 pb-4 item': from_catalog, 'col-lg-12': (from_recommendation)}, item_class,thumbnail_class]">
                <div :class="{'thumbnail': !from_catalog, 'thumbnail_catalog': from_catalog, 'similar_course_tile': from_similar_course, 'group_product_tile': (from_catalog && from_group)}" v-on:mouseover="action_items_show" v-on:mouseleave="action_items_hide">
                  <div :class="{'thumbnail_container': !from_catalog, 'thumbnail_container_catalog': from_catalog, 'similar_course_tile_container': ((from_similar_course) || (from_catalog && from_group))}">
                    <span v-on:click="show_details(false)">
                      <v-lazy-image v-bind:src="thumbnail_url" class="thumbnail_img" alt="thumbnail"></v-lazy-image>
                    </span>
                    <catalog-detail v-if="from_catalog && !from_similar_course" :logged_in="logged_in" :product="product" v-bind:share_popup_id="share_popup_id"/>
                    <div v-if="from_catalog && (!free || paid_subscription)" class="price_block">
                      <div v-if="paid_subscription">
                        <div v-if="subscribe_only&&!valid_subscription" class="margin_top_50_percent">
                          <span v-bind:id="'price_bar_'+id+item_id"  class="product_price">{{$t('add_to_cart.subscribers_only')}}</span>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="!free">
                          <div v-show="!added_to_cart">
                            <i class="text-white fad fa-shopping-cart"></i>
                            <span v-bind:id="'price_bar_'+id+item_id" class="text-white pl-1 lxa_font_size_03 lxa_light_font">US&nbsp$ {{price}}</span>
                          </div>
                          <div v-show="added_to_cart">
                            <i class="text-white fad fa-cart-arrow-down"></i>
                            <span v-bind:id="'price_bar_'+id+item_id" class="text-white pl-1 lxa_font_size_03 lxa_light_font">Added to cart</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="section_title lxa_font_size_03 lxa_medium_font py-3 px-2" v-if="from_similar_course"> {{product.title}} </div>
                  <div v-if="from_similar_course" class="col-lg-12 col-md-12 col-sm-12 float_left padding_bottom_15 px-2 font_barlow_semi_condensed_light_14 similar_course_creator">
                    <div>{{'By' +' '+ product.author}}</div>
                    <div>{{product.created_at_format}}</div>
                  </div>
                  <div class="caption d-flex mt-2 px-2" v-on:click="show_details(false)" v-if="!from_catalog">
                    <i v-if="item_type == 'Bundle'" class="fad fa-file-archive font_barlow_semi_condensed_light_16 text-warning" data-toggle="tooltip" data-placement="top" title="Bundle"></i>
                    <i v-else-if="(item_type == 'LiveEvent') || (item_type == 'InPersonEvent')" class="fad fa-calendar-star font_barlow_semi_condensed_light_16 text-success" data-toggle="tooltip" data-placement="top" title="Event"></i>
                    <i v-else class="fad fa-laptop font_barlow_semi_condensed_light_16 text-primary" data-toggle="tooltip" data-placement="top" title="E-learning"></i>
                    <p v-bind:id="item_title_id" class="item_title pl-2" data-toggle="tooltip" data-placement="top" :title="title"> {{title}} </p>
                  </div>
                  <div class="d-flex pt-2 lxa_font_size_03 lxa_light_font px-2" v-if="progress_percent == 100">
                    <i class="fad fa-calendar-alt lxa_text_color_06 mt-1"></i>
                    <span class="pl-1 lxa_font_size_03 lxa_light_font">Completed On {{item.enrollment.completed_at}}</span>
                  </div>
                  <div v-if="!from_catalog">
                    <div v-if="!canceled && progress_percent != 100" class="d-flex enrolled_options w-100 px-2">
                      <div v-on:click="show_details(true)">
                        <i class="fad fa-play-circle play_icon"></i>
                      </div>
                      <div class="lxa_button_title_light_1" v-if="!from_catalog&&(!canceled && progress_percent != 100)">
                        <div v-if="(item_type == 'LiveEvent') || (item_type == 'InPersonEvent')" class="pl-2">Upcoming Event<span class="d-block">{{event_start_date}}</span></div>
                        <div v-else class="pl-2 pt-2">{{$t('item.launch')}}</div>
                      </div>
                      <div class="d-flex ml-auto">
                        <div class="pt-2 cursor_pointer" v-if="product.comments_count > 0" v-on:click="show_details(false)">
                          <i data-toggle="tooltip" data-placement="top" title="Comments" class="fad fa-comments lxa_section_light text-primary"></i>
                          <span class="pl-2 pr-2 lxa_section_light"> {{ product.comments_count}}</span>
                        </div>
                        <div v-bind:id="progress_id" class="play_progress">
                          <span class="_pt-2"> {{progress_percent}}% </span>
                          <!-- <span v-if="item_type != 'Certification' || progress_percent == 100" class="_pt-2"> {{progress_percent}}% </span>-->
                          <!-- <span v-else class="certification_status">{{ item.certification_status }}</span> -->
                        </div>
                      </div>
                    </div>
                    <div v-else class="enrolled_options px-2">
                      <item-rating v-bind:ratings_id="ratings_id" :product="product" :ratings_url="ratings_url" :rates_count="rates_count" :average_rating="average_rating" :rated_by_user="rated_by_user" :logged_in="logged_in" :from_item_tile_detail="true"></item-rating>
                    </div>
                  </div>
                  <div class="action_items" v-if="action_items && !from_catalog">
                    <div class="d-flex pull-right padding_top_13">
                      <span class="share" data-toggle="tooltip" data-placement="top" title="Share" v-on:click="openSharePopup()">
                        <span class="cursor_pointer share-section pl-3" data-toggle="modal" data-target="#share_detail"><i data-toggle="tooltip" data-placement="top" title="Share" class="fad fa-share-alt font_barlow_semi_condensed_light_16 text-white"></i></span>
                      </span>
                      <span class="pl-3" v-if="progress_percent == 100">
                        <enrolled-bookmark v-bind:bookmarked="bookmarked" :bookmarks_url="bookmarks_url" :logged_in="logged_in" :bookmark_cancel_url="product.bookmark_cancel_url" :can_be_bookmarked="product.can_be_bookmarked" :details="false" :list="false"></enrolled-bookmark>
                      </span>
                      <span data-toggle="tooltip" data-placement="top" title="Cancel" v-if="progress_percent!=100" v-on:click="cancel_enrollment" class="pl-3 cursor_pointer"> <i data-toggle="tooltip" data-placement="top" title="Cancel Enrollment" class="fad fa-times-circle font_barlow_semi_condensed_light_16 text-white"></i></span>
                      <div v-if="enrolled_item" class="item_actions" :id="enrolled_item_id">
                        <span data-toggle="tooltip" data-placement="top" title="More" class="item-more-option pl-3 cursor_pointer" v-on:click="show_details(false)">
                        <i class="fad fa-ellipsis-h font_barlow_semi_condensed_light_16 text-white"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <new-comment-popup v-if="(available_item && newCommentPopupOpen && !from_catalog)" v-click-outside="outside" :comments_url="comments_url" :commentable_id="shareable_id" :commentable_type="item_type" :thumbnail_id="item_id"/>
                  <share-popup v-if="(available_item && sharePopupOpen && !from_catalog)" v-click-outside="outside" v-bind:popup_id="available_item_more_action_share_id" :item_share_link="item_share_link" :title_item="title" :shareable_id="shareable_id"/>
                </div>
              </div>`,
  props: {
    item: Object,
    logged_in: Boolean,
    from: String,
    container_name: String,
    shared_item: Number,
    from_catalog: Boolean,
    from_similar_course: Boolean,
    from_group: Boolean,
    index: Number
  },
  mixins: [itemMixins],
  components: {
    VLazyImage
  },
  data: function() {
    var thumbnail_class = "col-lg-3 col-xl-3"
    var index_num = (this.index+1)%4;
    if (index_num == 0 ) {
      thumbnail_class = thumbnail_class + ' pr-0'
    }
    // if ([0,1,5,6,7,9].includes(thumbnail_class)) {
    //   thumbnail_class = 'col-lg-3 col-xl-3 pr-0 pr-lg-3';
    // } else if ([2].includes(thumbnail_class)) {
    //   thumbnail_class = 'col-lg-6 col-xl-6 pr-0';
    // } else if([10].includes(thumbnail_class)){
    //    thumbnail_class = 'col-lg-6 col-xl-6 pr-0 pr-lg-3';
    // } else if ([3].includes(thumbnail_class)) {
    //   thumbnail_class = 'col-lg-9 col-xl-9 pr-0 pr-lg-3';
    // } else if ([4].includes(thumbnail_class)){
    //   thumbnail_class = 'col-lg-3 col-xl-3 pr-0';
    // } else if([8].includes(thumbnail_class)){
    //   thumbnail_class = 'col-lg-3 col-xl-3 pr-0';
    // } else if([11].includes(thumbnail_class)){
    //   thumbnail_class = 'col-lg-3 col-xl-3 pr-0';
    // } else {
    //   thumbnail_class = 'col-lg-12 col-xl-12 pr-0';
    // }
    return {
      action_items: false,
      thumbnail_class: (this.from_catalog && !this.from_similar_course) ? thumbnail_class : '',
      sharePopupOpen: false,
      shareModalOpen: false,
      invite: 'invite',
      share_popup_id: "share_popup_" + new Date().getTime()
    }
  },
  mounted: function() {
    if(!this.from_catalog &&(!this.canceled&&this.progress_percent != 100)) {
      this.update_progress(this.progress_percent);
    }
    else {
      jQuery("#"+this.item_id).addClass('item_' + this.product_id);
    }
    ellipsis('.item_description', 2);
    ellipsis('.item_title', 1);
    ellipsis('.section_title', 1);
    jQuery('.kv-uni-star').rating({
      theme: 'krajee-uni',
      filledStar: '&#x2605;',
      emptyStar: '&#x2606;',
      showClear: false,
      showCaption: false,
      step: 1
    });
    mouse_over_actions(this.enrolled_item_id, this.enrolled_item_more_action_id);
    mouse_over_actions(this.available_item_id, this.available_item_more_action_id);
    // $('#'+this.item_title_id).tooltip({ boundary: 'window' })
  },
  updated: function(){
    this.$nextTick(() => {
      ellipsis('.item_description', 2);
    })
  },
  methods: {
    action_items_show: function() {
      this.action_items=true;
    },
    action_items_hide: function() {
      this.action_items=false;
    },
    open_add_to_playlist(item_id) {
      this.$root.display_playlist = !this.$root.display_playlist
      this.$root.active_enrolled_content = item_id.toString();
    },
  }
});

Vue.component('list-item-tile', {
  template: `<div v-bind:id="item_id" :class="[{'col-sm-12': (from_recommendation), visibility_hidden: visibility_hidden}, 'col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 pl-0 pr-0 item', item_class]">
                <div class="w-100">
                  <div class="border-0">
                    <div class="card_horizontal">
                      <div class="thumbnail_container_catalog cursor_pointer">
                        <img v-bind:src="image_thumbnail_url" class="thumbnail_img listview_thumbnail lxa_border_radius" alt="thumbnail" v-on:click="show_details(false)"/>
                      </div>
                      <div class="card-body p-0 lxa_common_border_b1 ml-3">

                        <div :class="[{'row mb-3 mx-0':(from_catalog)},'row no-gutters flex-nowrap mb-3 mx-0']">
                          <div class="col-md col-lg pr-3">
                          <div class="row mx-0">
                          <div v-bind:id="item_title_id" class="lxa_title_medium_bold col-12 px-0 cursor_pointer text-uppercase" v-on:click="show_details(false)">
                            <i v-if="item_type == 'Bundle'" class="fad fa-file-archive font_barlow_semi_condensed_light_16 text-warning" data-toggle="tooltip" data-placement="top" title="Bundle"></i>
                            <i v-else-if="(item_type == 'LiveEvent') || (item_type == 'InPersonEvent')" class="fad fa-calendar-star font_barlow_semi_condensed_light_16 text-success" data-toggle="tooltip" data-placement="top" title="Event"></i>
                            <i v-else class="fad fa-laptop font_barlow_semi_condensed_light_16 text-primary" data-toggle="tooltip" data-placement="top" title="E-learning"></i>
                            {{title}}
                          </div>

                        </div>
                          <div v-if="from_catalog" class="row mx-0">
                            <div v-bind:id="item_description_id" class="col-12 px-0 lxa_font_size_03 lxa_light_font" v-html="description"></div>
                          </div>
                          <div v-else class="row mx-0">
                            <div class="d-flex pt-2 lxa_font_size_03 lxa_light_font" v-if="canceled">
                              <i class="fad fa-calendar-alt lxa_text_color_06 mt-1"></i>
                              <span class="pl-1">Cancelled On {{item.enrollment.canceled_at}}</span>
                            </div>
                            <div class="d-flex pt-2 lxa_font_size_03 lxa_light_font" v-else-if="progress_percent==100">
                              <i class="fad fa-calendar-alt lxa_text_color_06 mt-1"></i>
                              <span class="pl-1">Completed On {{item.enrollment.completed_at}}</span>
                            </div>
                            <div class="d-flex pt-2 lxa_font_size_03 lxa_light_font" v-else>
                            </div>
                          </div>
                          <div class="d-flex justify-content-start align-items-center mt-3 lxa_font_size_03 lxa_light_font">
                          <div v-if="from_catalog" class="lxa-attributes-left">
                            <span v-if="product.enrollments_count > 0">{{$tc('item.enrollments',product.enrollments_count, {count: product.enrollments_count})}} </span>
                            <span v-if="product.comments_count > 0" class="pl-2"> {{$tc('item.comments', product.comments_count, {count: product.comments_count})}}</span>
                            <span v-if="product.average_rating > 0" class="pl-2">
                              <i data-toggle="tooltip" data-placement="top" title="Rating" class="fad fa-star lxa_icon_size"></i>
                            </span>
                            <span v-if="product.average_rating > 0" class="pl-1">
                                {{ product.average_rating }}
                            </span>
                          </div>
                          <div v-else class="lxa-attributes-left">
                            <div v-if="item.enrollment.canceled">
                            </div>
                            <div v-else-if="progress_percent==100">
                              <span>
                                <item-rating v-bind:ratings_id="ratings_id" :product="product" :ratings_url="ratings_url" :rates_count="rates_count" :average_rating="average_rating" :rated_by_user="rated_by_user" :logged_in="logged_in" :from_item_tile_detail="true"></item-rating>
                              </span>
                            </div>
                            <div v-else>
                              <span v-on:click="cancel_enrollment" class="cursor_pointer"> <i class="fad fa-times-circle lxa_icon_size lxa_text_color_01"></i></span>
                              <span class="pl-1 cursor_pointer" v-on:click="cancel_enrollment"> Cancel Enrollment  </span>
                              <span v-if="product.comments_count > 0" class="pl-2 cursor_pointer" v-on:click="show_details(false)"> {{$tc('item.comments', product.comments_count, {count: product.comments_count})}}  </span>
                            </div>
                          </div>
                          <div class="ml-auto lxa-attributes-right d-flex justify-content-end align-items-center">
                            <div v-if="from_catalog || !canceled" class="d-flex">
                              <div v-if="logged_in" v-on:click="openSharePopup()" class="cursor_pointer">
                                <i class="fad fa-share-alt lxa_icon_size lxa_text_color_04" data-toggle="modal" data-target="#share_detail"></i>
                                <span class="lxa_font_size_03 lxa_light_font pl-1" data-toggle="modal" data-target="#share_detail">{{$t('available_item_more_link.share')}}</span>
                              </div>
                              <div v-if="progress_percent == 100" class="cursor_pointer pl-3">
                                <enrolled-bookmark v-bind:bookmarked="bookmarked" :bookmarks_url="bookmarks_url" :logged_in="logged_in" :bookmark_cancel_url="product.bookmark_cancel_url" :can_be_bookmarked="product.can_be_bookmarked" :details="false" :list="true"></enrolled-bookmark>
                              </div>
                            </div>
                            <div v-on:click="show_details(false)" class="cursor_pointer">
                              <span class="item-more-option pl-3">
                              <i class="fad fa-ellipsis-h lxa_icon_size align-middle lxa_text_color_05"></i>
                              </span>
                              <span class="lxa_font_size_03 lxa_light_font pl-1 cursor_pointer">{{$t('enrolled_item_question_lightbox.more')}}</span>
                            </div>
                          </div>
                         </div>
                          </div>
                          <div class="lxa-catalog-action-container d-flex flex-column justify-content-end align-items-center">

                            <div v-if="from_catalog && (!free || paid_subscription)" class="price_block_list mb-3">
                            <div v-if="paid_subscription">
                              <div v-if="subscribe_only&&!valid_subscription">
                                <span v-bind:id="'price_bar_'+id+item_id"  class="product_price">{{$t('add_to_cart.subscribers_only')}}</span>
                              </div>
                            </div>
                            <div v-else>
                              <div v-if="!free" class="font_barlow_semi_condensed_medium_16">
                                <i class="fad fa-shopping-cart"></i>
                                <span v-if="!added_to_cart" v-bind:id="'price_bar_'+id+item_id" class="pl-1 font-weight-bold">US&nbsp;$ {{price}}</span>
                              </div>
                            </div>
                          </div>

                            <div v-if="from_catalog" class="btn m-0 enroll_button pointer text-uppercase" v-on:click="add_to_library">
                              <i class="fad fa-money-check-edit lxa_btn_txt_01 lxa_icon_size"></i>
                              <span v-if="free" class="ml-1 text-uppercase font_barlow_semi_condensed_medium_16 lxa_btn_txt_01">Enroll</span>
                              <span v-else class="ml-1 text-uppercase font_barlow_semi_condensed_medium_16 lxa_btn_txt_01">Add to Cart</span>
                            </div>
                            <div v-else-if="!canceled && progress_percent != 100" v-on:click="show_details(true)" class="btn m-0 enroll_button pointer">
                              <i class="fad fa-play-circle lxa_btn_txt_01 lxa_icon_size"></i>
                              <span class="ml-1 text-uppercase font_barlow_semi_condensed_medium_16 lxa_btn_txt_01">Launch</span>
                            </div>
                         </div>
                        </div>
                        <!--<div class="row mr-0">
                        <div class="col-12 px-0">
                          <hr class="w-100 m-0 p-0 line-separator"/>
                        </div>
                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>`,
  props: {
    item: Object,
    logged_in: Boolean,
    from: String,
    container_name: String,
    shared_item: Number,
    from_catalog: Boolean
  },
  mixins: [itemMixins],
  data: function() {
    return {
      list_action_items: true,
    }
  },
  mounted: function() {
    if(this.enrolled_item) {
      this.progress_percent;
    }
    else {
      jQuery("#"+this.item_id).addClass('item_' + this.product_id);
    }
    jQuery('.kv-uni-star').rating({
      theme: 'krajee-uni',
      filledStar: '&#x2605;',
      emptyStar: '&#x2606;',
      showClear: false,
      showCaption: false,
      step: 1
    });
    mouse_over_actions(this.enrolled_item_id, this.enrolled_item_more_action_id);
    mouse_over_actions(this.available_item_id, this.available_item_more_action_id);
    var self = this;
    setTimeout(function() {
      ellipsis('#'+self.item_title_id, 1);
      ellipsis('#'+self.item_description_id, 1);
    },500);
  },
  methods: {
    open_add_to_playlist(item_id) {
      this.$root.display_playlist = !this.$root.display_playlist
      this.$root.active_enrolled_content = item_id.toString();
    }
  }
});

Vue.component('item-player-lightbox', {
  template: `<div class="modal fade preview_modal position_fixed" v-bind:id="modal_id" data-backdrop="static" data-keyboard="false" role="dialog">
              <div class="modal-dialog float_none">
                <div v-bind:class="'modal-content '+current_content_type+'-content'" id="lightbox_container">
                  <input id="course_id" name="course_id" type="hidden" v-bind:value="product.id">
                  <div class="modal-header">
                    <div v-if="show_title" class="modal-title col-lg-11 col-md-11 col-sm-11 col-xs-11">{{product.title}}</div>
                    <div class="pull-right" style="margin-right: -4px;">
                      <img v-on:click="showPlaylist" src="/images/single_page/playlist.png" class="playlist_icon cursor_pointer" />
                      <div class="playlist hide"></div>
                      <img v-show="!fullscreen" v-on:click="onFullscreen" src="/images/single_page/fullscreen_enter.svg" class="fullscreen_icon cursor_pointer" />
                      <img v-show="fullscreen" v-on:click="onFullscreen" src="/images/single_page/fullscreen_exit.svg" class="fullscreen_icon cursor_pointer" />
                    </div>
                  </div>
                  <div v-bind:id="modal_id+'_1'" class="col-sm-12 playlist_container hide">
                    <ul class="width_max_content padding_left_0 list-inline height_max_content">
                      <item-player-playlist v-for="(item,index) in contents_list" v-bind:key="item.associated_id + '_' + new Date().getTime()" :item="item" :modal_id="modal_id" :index="index" :show_content_no="show_content_no" />
                    </ul>
                  </div>
                  <div class="modal-body">
                    <fullscreen-alert-message v-if="fullscreen&&fullscreen_alert"></fullscreen-alert-message>
                    <video-component ref="player" v-for="(item,index) in contents_list" v-if="index==show_content_no && (item.content_type == 'video' || item.content_type == 'weblink')" v-bind:video_obj="content_obj" :modal_id="modal_id" :preview="preview" />
                    <quiz-component ref="player" v-for="(item,index) in contents_list" v-if="index==show_content_no && (item.content_type == 'quiz' || item.content_type == 'survey') && content_loaded" v-bind:quiz_obj="content_obj" :item="item" :modal_id="modal_id" :preview="preview" :result_response="result_response" :result_have_response="result_have_response" />
                    <document-component ref="player" v-for="(item,index) in contents_list" v-if="index==show_content_no && (item.content_type == 'document' || item.content_type == 'slideshow')" v-bind:document_obj="content_obj" :modal_id="modal_id" />
                    <tincan-scorm-common-component ref="player" v-for="(item,index) in contents_list" v-if="index==show_content_no && (item.content_type == 'tincan_package' || item.content_type == 'scorm_package') && content_loaded" v-bind:modal_id="modal_id" :tincan_obj="content_obj"/>
                    <webtext-component ref="player" v-for="(item,index) in contents_list" v-if="index==show_content_no && item.content_type == 'webtext' && content_loaded" v-bind:web_text_obj="content_obj" :modal_id="modal_id" :preview="preview" />
                  </div>
                  <div id="last_content_notice_container">
                    <div v-bind:class="'last_content_notice content_type_'+current_content_type" style="display: none">
                      <div class="message">Continue to next item.</div>
                      <div class="arrow"><img alt="Content_next_action_arrow" src="/images/content_next_action_arrow.png"></div>
                      <div class="dismiss_notice"><img alt="Close" src="/images/icons/close.png"></div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="font_open_sans_regular_12_blue btn btn-default btn-round-sm" v-on:click="close" data-dismiss="modal">{{$t('common.done')}}</button>
                  </div>
                </div>
              </div>
            </div>`,
  data: function() {
    return{
      modal_id: 'preview_lightbox',
      product: {},
      contents_list: [],
      content_obj: {},
      preview: true,
      show_content_no: 0,
      content_loaded: false,
      refresh_progress_url: '',
      result_response: {},
      result_have_response: false,
      current_content_type: '',
      title_id: 'header_title_' + new Date().getTime(),
      fullscreen: false,
      fullscreen_alert: false,
      data: {},
      show_title: false,
      show_playlist: true
    }
  },
  mounted: function() {
    var self = this;
    this.$nextTick(function () {
      self.$store.state.all_items_container.$on('set-player-data', this.set);
      self.$store.state.all_items_container.$on('open-player-modal', this.open);
    }.bind(this));
    jQuery('#preview_lightbox').on('hidden.bs.modal', function() {
      jQuery("#"+self.modal_id+" .playlist_container ,.playlist").addClass('hide');
      jQuery("#"+self.modal_id+" .playlist_container ,.playlist").css('transform','scale(1)');
      jQuery("#"+self.modal_id+' .modal-dialog').removeClass('fullscreen');
      self.show_playlist = true;
      self.show_title = false;
      self.product = {};
      self.contents_list = [];
      self.fullscreen = false;
      self.$root.show_lightbox = true;
    });
  },
  watch: {
    show_content: function() {
      this.show_content_no = this.show_content;
    },
    contents_list: function() {
      if(this.contents_list.length > 0) {
        var current_content = this.contents_list[this.show_content_no];
        this.current_content_type = current_content.content_type;
      }
    },
    content_obj: function() {
      if(this.content_obj.is_last_content) {
        jQuery('#last_content_notice_container').hide();
      } else {
        jQuery('#last_content_notice_container').show();
      }
    }
  },
  methods: {
    onFullscreen: function() {
      if(this.fullscreen) {
        this.fullscreen_alert = false;
        jQuery("#"+this.modal_id+' .modal-dialog').removeClass('fullscreen');
        this.fullscreen = false;
      } else {
        jQuery("#"+this.modal_id+' .modal-dialog').addClass('fullscreen');
        this.fullscreen = true;
        this.fullscreen_alert = true;
      }
      switch(this.current_content_type) {
        case "video": case "weblink": case "document": case "slideshow":
          this.$refs.player[0].resizeContent();
        break;
      }
    },
    showPlaylist: function() {
      //var nice =  jQuery("#"+this.modal_id+" .playlist_container").getNiceScroll();
      if (!this.show_playlist) {
        this.show_playlist = true;
        jQuery("#"+this.modal_id+" .playlist_container ,.playlist").css('transform','scale(1)');
        //jQuery("#"+this.modal_id+" .playlist_container").one("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function(){
          //nice.show();
          //nice.resize();
        //});
      }
      else {
        this.show_playlist = false;
        //nice.hide();
        jQuery("#"+this.modal_id+" .playlist_container ,.playlist").css('transform','scale(0)');
        //jQuery("#"+this.modal_id+" .playlist_container").one("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function(){
          //nice.resize();
        //});
      }
    },
    update_progress: function(item_id, progress_percent) {
      var self = this;
      var arr = this.$root.$refs.enrolled_item;
      arr.forEach(function(i) {
        if(i.item_class == self.data.item_class) {
          jQuery('#'+item_id+' .play_progress').find('span').html(progress_percent+'%');
          if(i.circular_progress != '')
            i.circular_progress.animate(progress_percent/100);
          i.content_loaded = false;
        }
      });
    },
    open: function(product) {
      jQuery(".loader").hide();
      this.show_title = true;
      this.product = product;
      jQuery("#preview_lightbox").modal('show');
      jQuery("#preview_lightbox").niceScroll({cursorcolor:"#4184F3",cursorwidth:"7px",autohidemode: "scroll",nativeparentscrolling: false});
    },
    close: function() {
      switch(this.current_content_type) {
        case "video": case "weblink": case "document": case "slideshow":
          this.endResize();
        break;
      }
      var self = this;
      if(!this.preview && this.refresh_progress_url) {
        this.$http.get(this.refresh_progress_url).then(response => {
          this.$parent.progress_percent = response.body.progress_percent;
          this.update_progress(this.data.item_id, response.body.progress_percent);
          if(response.body.progress_percent == 100) {
            setTimeout(function(){
              jQuery("#"+self.data.item_id).fadeOut('slow');
            }, 500);
          }
        });
      }else if(!this.preview){
        this.$parent.updateProgress();
      }
      //var nice =  jQuery("#"+this.modal_id+" .playlist_container").getNiceScroll();
      //nice.hide();
      //jQuery("#"+this.modal_id+" .playlist_container ,.playlist").css('transform','scale(1)');
    },
    set: function(data) {
      var self = this;
      this.data = data;
      this.contents_list = data.contents_list;
      this.content_obj = data.content_obj;
      this.preview = data.preview;
      this.show_content_no = data.show_content;
      this.content_loaded = data.content_loaded;
      this.refresh_progress_url = data.refresh_progress_url;
      this.result_response = data.result_response;
      this.result_have_response = data.result_have_response;
      this.current_content_type = data.contents_list[this.show_content_no].content_type;
      jQuery("#"+this.modal_id+" .playlist_container ,.playlist").removeClass('hide');
      setTimeout(function(){
        initTrunk8Faded("#"+self.title_id, false, 1);
        self.showPlaylist();
      }, 500);
    },
    startResize: function() {
      jQuery(window).resize(this.$refs.player[0].resizeContent);
    },
    endResize: function() {
      jQuery(window).off("resize", this.$refs.player[0].resizeContent);
    }
  }
});

Vue.component('item-player-playlist', {
  template: `<li v-bind:id="'content_'+item.content_id" class="content_list height_95_percent">
              <div class="preview_content cursor_pointer" v-on:click="selectContent">
                <img v-bind:id="'content_thumbnail_'+item.content_id" :src="item.img_src" :class="[index==show_content_no ? 'border_2_blue selected content_thumbnail' : 'content_thumbnail']"></img>
                <div v-bind:id="'content_title_'+item.content_id" :title="item.content_title" class="content_title font_open_sans_regular_10_gray margin_top_7">{{item.content_title}}</div>
              </div>
            </li>`,
  props: {
    item: Object,
    modal_id: String,
    index: Number,
    show_content_no: Number
  },
  mounted: function() {
    initTrunk8Faded(".content_title", false, 1);
  },
  methods: {
    selectContent: function() {
      if (!jQuery("#content_"+this.item.content_id+" .content_thumbnail").hasClass('border_2_blue')) {
        jQuery('.modal-body').removeClass('height_90_percent');
        this.$parent.content_loaded = false;
        var p = jQuery("#content_thumbnail_"+this.item.content_id)[0].getAttribute('autoplay');
        var url = p ? this.item.select_thumbnail_url+'&autoplay='+p : this.item.select_thumbnail_url;
        this.$http.get(url).then(response => {
          this.$parent.current_content_type = response.body.content_type;
          switch(this.$parent.current_content_type) {
            case "video": case "weblink":
              this.videoContent(response.body);
              jQuery('.modal-body').removeAttr("style");
              jQuery('.modal-body').addClass('height_90_percent');
              break;
            case "quiz": case "survey":
              this.quizContent(response.body);
              jQuery('.modal-body').removeAttr("style");
              jQuery('.modal-body').addClass('height_90_percent');
              break;
            case "tincan_package":
              this.tincanPackage(response.body);
              jQuery('.modal-body').removeAttr("style");
              jQuery('.modal-body').addClass('height_90_percent');
              break;
            case "scorm_package":
              this.scormPackage(response.body);
              jQuery('.modal-body').removeAttr("style");
              jQuery('.modal-body').addClass('height_90_percent');
              break;
            case "webtext":
              this.webtextContent(response.body);
              jQuery('.modal-body').addClass('height_90_percent');
              break;
            case "document":
              this.videoContent(response.body);
              jQuery('.modal-body').removeAttr("style");
              jQuery('.modal-body').addClass('height_90_percent');
              break;
          }
        });
      }
    },
    videoContent: function(response_body) {
      this.$parent.content_obj = response_body;
      this.$parent.show_content_no = this.index;
      this.$parent.content_loaded = true;
    },
    documentContent: function(response_body) {
      this.$parent.content_obj = response_body;
      this.$parent.show_content_no = this.index;
      this.$parent.content_loaded = true;
    },
    tincanPackage: function(response_body) {
      this.$http.get(response_body.url).then(response => {
        this.$parent.content_obj = response.body;
        this.$parent.show_content_no = this.index;
        this.$parent.content_loaded = true;
      });
    },
    scormPackage: function(response_body) {
      this.$http.get(response_body.url).then(response => {
        InitializeSessionData(response.body.version, response.body.rt_data);
        InitializeRunTimeApi();
        this.$parent.content_obj = response.body;
        this.$parent.show_content_no = this.index;
        this.$parent.content_loaded = true;
      });
    },
    quizContent: function(response_body) {
      if(this.$parent.preview) {
        this.$http.post(response_body.response_sets_url+'&from_page=preview&product_id='+this.item.product_id+'&is_last_content='+response_body.is_last_content).then(response => {
          this.$parent.content_obj = response.body;
          this.$parent.show_content_no = this.index;
          this.$parent.content_loaded = true;
        });
      } else {
        this.$parent.$parent.result_have_response = false;
        if(response_body.ps_complete) {
          this.$http.get(response_body.response_show_url+'&is_last_content='+response_body.is_last_content).then(response => {
            this.$parent.content_obj = response.body;
            this.$parent.result_response = response.body.responses.length ? response.body.responses[0].response : {};
            this.$parent.result_have_response = true;
            this.$parent.show_content_no = this.index;
            this.$parent.content_loaded = true;
          });
        } else {
          this.$http.post(response_body.response_sets_url+'&from_page=&product_id='+this.item.product_id+'&is_last_content='+response_body.is_last_content).then(response => {
            this.$parent.content_obj = response.body;
            this.$parent.show_content_no = this.index;
            this.$parent.content_loaded = true;
          });
        }
      }
    },
    webtextContent: function(response_body) {
      this.$parent.content_obj = response_body;
      this.$parent.show_content_no = this.index;
      this.$parent.content_loaded = true;
    }
  }
});

Vue.component('enrolled-item-question-lightbox', {
  template: `<div class="modal fade preview_modal" v-bind:id="'questionaire_'+item_id" role="dialog" style="position: fixed !important">
              <div class="modal-dialog float_none">
                <div class="modal-content height_max_content">
                  <div class="modal-header questionaire_header border_bottom_0">
                    <label class="font_open_sans_regular_15 padding_top_5 margin_left_20">{{ $t('enrolled_item_more_link.questions') }}</label>
                    <button type="button" class="new_question font_open_sans_regular_12_blue btn btn-default btn-round-sm right_float" v-on:click="create_questions"><i class="fa fa-user-plus" style="width: 16px;height: 16px;color:#FFFFFF"></i>&nbsp{{$t('enrolled_item_question_lightbox.create_question').toUpperCase()}}</button>
                    <div id="create_question" v-if="createQuestion" v-click-outside="outside" class="custom_popover fade bottom in show">
                      <create_question_popup v-bind:create_question_url="create_question_url"></create_question_popup>
                    </div>
                  </div>
                  <div class="modal-body questionaire_body">
                    <div class="container">
                      <div class="row">
                        <div class="col-sm-8 width_70_percent min_height_450 padding_top_8 border_right_light_grey">
                          <div v-if="questions_list.length == 0" class="text_align_center padding_top_30_percent">{{$t('enrolled_item_question_lightbox.no_questions')}}<button type="button" class="new_question font_open_sans_regular_12_blue btn btn-default btn-round-sm" v-on:click="create_questions">+&nbsp{{$t('enrolled_item_question_lightbox.create_question')}}</button></div>
                          <div v-if="show_questions_list&&questions_list.length>0">
                            <div v-if="best_question_count>0" class="col-sm-6 width_50_percent padding_left_0 left_float">
                              <div>{{$tc('enrolled_item_question_lightbox.best_question',best_question_count, {count: best_question_count})}}</div>
                              <div v-for="item in popular_questions" class="font_open_sans_regular_12_blue cursor_pointer width_max_content" v-on:click="show_questions(item)">{{item.product_question.product_question.comment}}</div>
                              <div v-if="best_question_count>2">
                                <div v-if="!popular_question_show_less&&!popular_question_show_more" class="font_open_sans_regular_11_blue cursor_pointer right_float" style="color: #ff7a24 !important" v-on:click="load_popular_questions">{{$t('enrolled_item_question_lightbox.more')}}</div>
                                <div v-if="popular_question_show_less" class="font_open_sans_regular_11_blue cursor_pointer right_float" style="color: #ff7a24 !important" v-on:click="ShowLess(true)">{{$t('enrolled_item_question_lightbox.less')}}</div>
                                <div v-if="popular_question_show_more" class="font_open_sans_regular_11_blue cursor_pointer right_float" style="color: #ff7a24 !important" v-on:click="ShowMore(true)">{{$t('enrolled_item_question_lightbox.more')}}</div>
                              </div>
                            </div>
                            <div v-if="unanswered_question_count>0" class="col-sm-6 padding_left_0 padding_bottom_11">
                              <div>{{$tc('enrolled_item_question_lightbox.open_question',unanswered_question_count, {count: unanswered_question_count})}}</div>
                              <div v-for="item in open_questions" class="font_open_sans_regular_12_blue cursor_pointer width_max_content" v-on:click="show_questions(item)">{{item.product_question.product_question.comment}}</div>
                              <div v-if="unanswered_question_count>2">
                                <div v-if="!open_question_show_less&&!open_question_show_more" class="font_open_sans_regular_11 cursor_pointer right_float" style="color: #ff7a24 !important" v-on:click="load_open_questions">{{$t('enrolled_item_question_lightbox.more')}}</div>
                                <div v-if="open_question_show_less" class="font_open_sans_regular_11_blue cursor_pointer right_float" style="color: #ff7a24 !important" v-on:click="ShowLess(false)">{{$t('enrolled_item_question_lightbox.less')}}</div>
                                <div v-if="open_question_show_more" class="font_open_sans_regular_11_blue cursor_pointer right_float" style="color: #ff7a24 !important" v-on:click="ShowMore(false)">{{$t('enrolled_item_question_lightbox.more')}}</div>
                              </div>
                            </div>
                            <div class="col-sm-12 margin_top_10 search_box">
                              <div class="search_bar">
                                <div>
                                  <img src="/images/single_page/search.png" v-on:click="search_questions"></img>
                                </div>
                                <div>
                                  <input id="search_question" type="text" class="vertical_align_top input-sm padding_left_0" :placeholder="$t('enrolled_item_question_lightbox.search_questions')" v-on:keyup.enter="search_questions" v-model="search_data"></input>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 question_details padding_top_10 padding_0">
                              <enrolled_item_question_detail v-for="item in questions_list" v-bind:show_answer="false" :item="item" :ans_array="ans_array" :create_answer_url="create_answer_url" :answers_popup="false"></enrolled_item_question_detail>
                            </div>
                          </div>
                          <div v-if="!show_questions_list&&questions_list.length>0">
                            <enrolled_item_question_detail v-bind:show_answer="true" :item="current_item" :ans_array="ans_array" :create_answer_url="create_answer_url" :answers_popup="false"></enrolled_item_question_detail>
                          </div>
                        </div>
                        <div class="col-sm-4 width_30_percent height_inherit">
                          <div class="course_description">
                            <div class="padding_left_10 padding_top_5">
                              <div>{{title}}</div>
                              <div class="item_desc">
                                <div>{{$t('common.description')}}:</div>
                                <div v-html="description"></div>
                              </div>
                            </div>
                            <div class="padding_left_8 background_white info_container display_inline_block">
                              <div class="margin_bottom_5">{{$t('enrolled_item_question_lightbox.about')}}</div>
                              <img v-bind:alt="questions_details.user_display_name" data-toggle="tooltip" data-placement="auto right" :title="questions_details.user_display_name" v-bind:src="questions_details.image_url" class="left_float padding_0 img-thumbnail margin_right_8"></img>
                              <div>{{questions_details.user_display_name}}</div>
                              <div>{{questions_details.user_designation}}</div>
                            </div>
                            <div class="padding_left_8 margin_top_0 background_white info_container display_inline_block">
                              <div class="margin_bottom_5">{{$t('enrolled_item_question_lightbox.participants')}}</div>
                              <img v-bind:alt="questions_details.user_display_name" v-for="img in questions_details.participants_img" v-bind:src="img.participant_img_url" data-toggle="tooltip" data-placement="auto right" :title="img.display_name" class="left_float padding_0 img-thumbnail margin_right_5"></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer border_top_0 position_relative">
                    <button type="button" class="font_open_sans_regular_12_blue btn btn-default btn-round-sm" data-dismiss="modal" id="done_button" v-on:click="close_modal">{{$t('common.done')}}</button>
                  </div>
                </div>
              </div>
            </div>`,
  data: function() {
    return {
      item_id: '',
      open_questions: [],
      popular_questions: [],
      questions_list: [],
      create_question_url: '',
      best_question_count: 0,
      unanswered_question_count: 0,
      title: '',
      description: '',
      questions_details: {},
      open_questions_more_link: '',
      best_questions_more_link: '',
      product_question_url: '',
      show_questions_list: true,
      createQuestion: false,
      current_item: {},
      ans_array: [],
      create_answer_url: '',
      popular_question_show_less: false,
      popular_question_show_more: false,
      open_question_show_less: false,
      open_question_show_more: false,
      open_questions_list: [],
      popular_questions_list: [],
      search_data: ''
    }
  },
  methods: {
    create_questions: function() {
      this.createQuestion = true;
    },
    outside: function(e) {
      if(!hasClass(e.target,'new_question')) {
        this.createQuestion = false;
      }
    },
    show_questions: function(item) {
      var self = this;
      this.$http.get(item.answers_url+'.json?').then(response => {
        self.show_questions_list = false;
        self.current_item = item;
        self.ans_array = response.body.answers;
        self.create_answer_url = response.body.create_url;
      });
    },
    load_popular_questions: function() {
      this.$http.get(this.best_questions_more_link+'.json?').then(response => {
        this.$parent.popular_questions = response.body;
        this.popular_question_show_less = true;
      });
    },
    load_open_questions: function() {
      this.$http.get(this.open_questions_more_link+'.json?').then(response => {
        this.$parent.open_questions = response.body;
        this.open_question_show_less = true;
      });
    },
    ShowMore: function(popular_question) {
      if (popular_question) {
        this.$parent.popular_questions = popular_questions_list;
        this.popular_question_show_less = true;
        this.popular_question_show_more = false;
      }
      else {
        this.$parent.open_questions = open_questions_list;
        this.open_question_show_less = true;
        this.open_question_show_more = false;
      }
    },
    ShowLess: function(popular_question) {
      if (popular_question) {
        var len = this.popular_questions.length;
        popular_questions_list = this.popular_questions;
        new_array = [];
        for (var i = 0; i <= 1; i++) {
          new_array[i] = this.popular_questions[i];
        }
        this.$parent.popular_questions = new_array;
        this.popular_question_show_less = false;
        this.popular_question_show_more = true;
      }
      else{
        var len = this.open_questions.length;
        open_questions_list = this.open_questions;
        new_array = [];
        for (var i = 0; i <= 1; i++) {
          new_array[i] = this.open_questions[i];
        }
        this.$parent.open_questions = new_array;
        this.open_question_show_less = false;
        this.open_question_show_more = true;
      }
    },
    search_questions: function() {
      this.$http.get(this.product_question_url+'?&search_question='+this.search_data).then(response => {
        this.$parent.questions_list = response.body;
      });
    },
    close_modal: function() {
      this.popular_question_show_less = false;
      this.popular_question_show_more = false;
      this.open_question_show_less = false;
      this.open_question_show_more = false;
      this.search_data = '';
    }
  }
});

Vue.component('question-lightbox', {
  template: `<div id="questionaire_modal" class="modal" role="dialog">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-body padding_30">
                  <div class="row margin_0 padding_0">
                    <div class="col-sm-3 font_barlow_semi_condensed_medium_18 padding_0">
                      Questions
                    </div>
                    <div class="col-sm-9 padding_0">
                      <button type="button" class="new_question btn btn-success right_float font_barlow_semi_condensed_medium_14" v-on:click="create_questions"><i class="fa fa-plus" style="width: 16px;height: 16px;color:#FFFFFF"></i>&nbsp{{$t('enrolled_item_question_lightbox.create_question').toUpperCase()}}</button>
                      <div id="create_question" v-if="createQuestion" v-click-outside="outside" class="custom_popover fade bottom in show">
                        <create_question_popup v-bind:create_question_url="create_question_url"></create_question_popup>
                      </div>
                    </div>
                  </div>
                  <div class="row margin_0 padding_0">
                    <div class="col-sm-1 padding_0">
                      <img v-bind:src="thumbnail_url" class="question_img" alt="thumbnail">
                    </div>
                    <div class="col-sm-11 margin_0">
                      <div class="font_barlow_semi_condensed_medium_14"> {{item.title}}</div>
                      <div class="font_barlow_semi_condensed_light_14"> </div>
                      <div class="font_barlow_semi_condensed_light_14" v-html="item.description"> </div>
                    </div>
                  </div>
                  <div class="row padding_0 margin_top_30 margin_0">
                    <div class="col-sm-8 padding_0 question_row">
                      <div class="row padding_0 margin_top_15">
                        <div v-on:click="load_all_questions" v-bind:class="[{'active_tab': active_tab=='all'},'col-sm-2 font_barlow_semi_condensed_medium_18 padding_left_0']">
                          All
                          <span>({{all_question_count}})</span>
                        </div>
                        <div v-on:click="load_popular_questions" v-bind:class="[{'active_tab': active_tab=='popular_question'},'col-sm-3 font_barlow_semi_condensed_medium_18 padding_left_0']">
                          Best Question
                          <span>({{best_question_count}})</span>
                        </div>
                        <div v-on:click="load_open_questions" v-bind:class="[{'active_tab': active_tab=='open_question'},'col-sm-3 font_barlow_semi_condensed_medium_18 padding_left_0']">
                          Open Question
                          <span>({{unanswered_question_count}})</span>
                        </div>
                        <div class="col-sm-1">
                        </div>
                        <div class="col-sm-3 padding_0">
                          <div class="search_bar">
                            <div>
                              <input id="search_question" type="text" class="vertical_align_top font_barlow_semi_condensed_medium_14" :placeholder="$t('enrolled_item_question_lightbox.search_questions')" v-on:keyup.enter="search_questions" v-model="search_data"></input>
                              <span class="search_img" v-on:click="search_questions">
                                <i class="fal fa-search" style="width: 16px;height: 16px;color: #008000"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 question_details padding_top_10 padding_0">
                          <div v-if="active_tab='all'">
                            <enrolled_item_question_detail v-for="item in questions_list" v-bind:show_answer="false" :item="item" :ans_array="ans_array" :create_answer_url="create_answer_url" :answers_popup="false"></enrolled_item_question_detail>
                          </div>
                          <div v-else-if="active_tab='open_question'">
                            <enrolled_item_question_detail v-for="item in open_questions" v-bind:show_answer="false" :item="item" :ans_array="ans_array" :create_answer_url="create_answer_url" :answers_popup="false"></enrolled_item_question_detail>
                          </div>
                          <div v-else>
                            <enrolled_item_question_detail v-for="item in popular_questions" v-bind:show_answer="false" :item="item" :ans_array="ans_array" :create_answer_url="create_answer_url" :answers_popup="false"></enrolled_item_question_detail>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 padding_right_0 padding_left_30">
                      <div class="margin_bottom_5 font_barlow_semi_condensed_light_16">{{$t('enrolled_item_question_lightbox.about')}}</div>
                      <div class="questions_about padding_top_15 margin_bottom_15">
                        <div class="row padding_0">
                          <div class="col-sm-3 padding_0">
                            <img v-bind:alt="questions_details.user_display_name" data-toggle="tooltip" data-placement="auto right" :title="questions_details.user_display_name" v-bind:src="questions_details.image_url" class="padding_0"></img>
                          </div>
                          <div class="col-sm-9">
                            <div class="font_barlow_semi_condensed_light_18 display_inline_block">{{questions_details.user_display_name}}</div>
                            <div class="font_barlow_semi_condensed_light_14 display_inline_block">{{questions_details.user_designation}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="font_barlow_semi_condensed_light_16 margin_bottom_5">{{$t('enrolled_item_question_lightbox.participants')}}</div>
                      <div class="questions_participants padding_top_15 margin_top_10">
                        <div v-for="img in questions_details.participants_img" style="padding: 0px 15px 0px 15px">
                          <img v-bind:alt="questions_details.user_display_name" v-bind:src="img.participant_img_url" data-toggle="tooltip" data-placement="auto right" :title="img.display_name"></img>
                        </div>
                      </div>
                      <div class="question_lightbox_done">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Done</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>`,
  data: function() {
    return {
      open_questions: [],
      popular_questions: [],
      questions_list: [],
      create_question_url: '',
      best_question_count: 0,
      unanswered_question_count: 0,
      questions_details: {},
      edit_item_details: {},
      best_questions_more_link: '',
      open_questions_more_link: '',
      item: {},
      thumbnail_url: '',
      createQuestion: false,
      ans_array: [],
      create_answer_url: '',
      search_data: '',
      open_question: false,
      product_question_url: '',
      active_tab: '',
      all_question_count: 0
    }
  },
  mounted: function() {
    var self = this;
    EventBus.$on("ShowQuestions",function(data,item,thumbnail_url) {
      self.open_questions = data.open_questions;
      self.popular_questions = data.popular_questions;
      self.questions_list = data.product_questions;
      self.create_question_url = data.create_question_path;
      self.unanswered_question_count = data.unanswered_question_count;
      self.best_question_count = data.best_question_count;
      self.best_questions_more_link = data.best_questions_more_link;
      self.open_questions_more_link = data.open_questions_more_link;
      self.questions_details = data.item_details;
      self.product_question_url = data.product_question_url;
      self.all_question_count = data.all_question_count;
      self.item = item;
      self.thumbnail_url = thumbnail_url;
      self.active_tab = 'all';
    });
  },
  methods: {
    create_questions: function() {
      this.createQuestion = true;
    },
    outside: function(e) {
      if(!hasClass(e.target,'new_question')) {
        this.createQuestion = false;
      }
    },
    search_questions: function() {
      this.$http.get(this.product_question_url+'?&search_question='+this.search_data).then(response => {
        this.questions_list = response.body;
      });
    },
    load_open_questions: function() {
      var self = this;
      this.$http.get(this.open_questions_more_link+'.json?').then(response => {
        self.open_questions = response.body;
        self.active_tab = 'open_question';
      });
    },
    load_popular_questions: function() {
      this.$http.get(this.best_questions_more_link+'.json?').then(response => {
        this.popular_questions = response.body;
        this.active_tab = 'popular_question';
      });
    },
    load_all_questions: function() {
      this.$http.get(this.product_question_url+'?&lightbox=true'+'&single_page=true').then(response => {
        this.questions_list = response.body;
        this.active_tab = 'all'
      });
    }
  }

});

Vue.component('create_question_popup', {
  template: `<div>
              <div class="font_barlow_semi_condensed_medium_14">{{$t('enrolled_item_question_lightbox.write_question')}}</div>
              <div class="col-sm-12 padding_0 margin_top_5">
                <textarea id="new_question_content" v-validate="'required'" data-vv-validate-on="none" class="width_100_percent header_section form-control" name="new_question_content" v-on:keyup="charactersRemaining"></textarea>
                <span class ="font_open_sans_regular_11_red" v-show="errors.has('new_question_content')">Comment cant be blank</span>
              </div>
              <div class="right_float characters_remaining">{{$t('common.characters_remaining')}}&nbsp{{remaining_characters}}</div>
              <div class="col-sm-12 text_align_right padding_0 margin_top_7 margin_top_15">
                <button type="text" class="cancel display_inline_block btn btn-secondary" v-on:click="clearPopup">{{$t('common.cancel')}}</button>
                <button type="text" v-on:click="createQuestion" class="btn btn-success margin_left_4">{{$t('common.done')}}</button>
              </div>
            </div>`,
  props: {
    create_question_url: String
  },
  data: function(){
    return {
      remaining_characters: 300
    }
  },
  methods: {
    charactersRemaining: function() {
      var characters_length = jQuery("#new_question_content").val().length;
      this.remaining_characters = 300 - characters_length;
    },
    clearPopup: function() {
      this.$parent.createQuestion = false;
      jQuery("#new_question_content").val('');
      this.remaining_characters = 300;
      this.errors.clear();
    },
    createQuestion: function() {
      var comment = jQuery("#new_question_content").val();
      var self = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post(this.create_question_url+'.json?&product_question[comment]='+comment).then(response => {
            self.$parent.all_question_count = response.body.all_question_count;
            self.$parent.open_questions = response.body.open_questions;
            self.$parent.popular_questions = response.body.popular_questions;
            self.$parent.questions_list = response.body.product_questions;
            self.$parent.create_question_url = response.body.create_question_path;
            self.$parent.unanswered_question_count = response.body.unanswered_question_count;
            self.$parent.best_question_count = response.body.best_question_count;
            self.clearPopup();
            self.$parent.close_modal();
          });
        }
      });
    }
  }
});

Vue.component('enrolled_item_question_detail', {
  template: `<div>
              <div v-if="show_answer&&!answers_popup" class="font_barlow_semi_condensed_medium_14 cursor_pointer" v-on:click="back_to_questions">{{$t('enrolled_item_question_detail.back_to_questions')}}</div>
              <div class="col-sm-12 padding_0 margin_bottom_10">
                <div class="col-sm-1 padding_0 margin_top_3">
                  <img v-bind:alt="item.display_name" v-bind:src="item.user_photo_url" class="left_float width_100_percent height_100_percent missing_img"></img>
                </div>
                <div class="col-sm-11 padding_left_15 question_detail padding_0">
                  <div v-if="show_answer||answers_popup" class="font_barlow_semi_condensed_medium_14">{{item.product_question.comment}}</div>
                  <div v-else class="font_barlow_semi_condensed_medium_14 cursor_pointer display_inline_block" v-on:click="show_question_details">{{item.product_question.comment}}</div>
                  <div class="font_barlow_semi_condensed_light_14">{{$t('enrolled_item_question_detail.asked_by')}}{{item.display_name}}</div>
                  <div class="col-sm-12 font_barlow_semi_condensed_light_14 padding_0">
                    <div class="col-sm-2 padding_0">
                      <span v-if="show_answer||answers_popup" id="answers">{{$tc('enrolled_item_question_detail.answers',item.answers_count, {count: item.answers_count})}}</span>
                      <span v-else id="answers" class="cursor_pointer" v-on:click="open_answer">{{$tc('enrolled_item_question_detail.answers',item.answers_count, {count: item.answers_count})}}</span>
                    </div>
                    <div class="col-sm-10 padding_0">
                      <div class="right_float margin_left_15">
                        <span v-if="(!show_answer||!answers_popup)&&item.delete_question_url" v-bind:id="'delete_'+item.product_question.id" v-on:click="delete_question" class="cursor_pointer float_right"><i class="fad fa-trash-alt" style="width: 16px;height: 16px;color: #FF0000"></i>&nbsp&nbsp</span>
                      </div>
                      <div class="right_float margin_left_5">
                        <span v-if="item.likes.likes_count" class="float_right badge likes_count">{{item.likes.likes_count}}</span>
                      </div>
                      <div class="right_float">
                        <span v-if="item.likes.liked" v-on:click="unlike_question" class="cursor_pointer float_right"><i class="fad fa-thumbs-down" style="width: 16px;height: 16px;color: #FF0000"></i></span>
                        <span v-else class="cursor_pointer float_right" v-on:click="like_question"><i class="fad fa-thumbs-up" style="width: 16px;height: 16px;color: #008000"></i></span>
                      </div>
                    </div>
                    <div class="col-sm-12 padding_0" id="show_answer" v-if="openAnswer">
                      <enrolled_item_answer_detail v-for="answer in ans_array" v-bind:answer="answer" :reply="false" :display_img="item.user_photo_url" :display_name="item.display_name" :answers_popup="answers_popup"></enrolled_item_answer_detail>
                      <enrolled_item_new_answer :create_answer_url="create_answer_url" :display_img="item.user_photo_url" :display_name="item.display_name" :reply="false" :answers_popup="answers_popup"></enrolled_item_new_answer>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="margin_0 padding_bottom_5"></hr>
            </div>`,
  props: {
    show_answer: Boolean,
    item: Object,
    ans_array: Array,
    create_answer_url: String,
    answers_popup: Boolean
  },
  data: function() {
    return {
      openAnswer: false,
    }
  },
  methods: {
    outside: function(e) {
      if (e.target.id != 'answers') {
        this.openAnswer = false;
      }
    },
    show_question_details: function() {
      var self = this;
      this.$http.get(this.item.answers_url+'.json?').then(response => {
        self.$parent.show_questions_list = false;
        self.$parent.current_item = this.item;
        self.$parent.ans_array = response.body.answers;
        self.$parent.create_answer_url = response.body.create_url;
      });
    },
    back_to_questions: function() {
      this.$parent.show_questions_list = true;
    },
    open_answer: function() {
      var self = this;
      if (this.openAnswer) {
        this.openAnswer = false;

      } else {
        this.$http.get(this.item.answers_url+'.json?').then(response => {
          self.openAnswer = true;
          self.$parent.ans_array = response.body.answers;
          self.$parent.create_answer_url = response.body.create_url;
          self.$parent.show_answer = true;
        });}
    },
    delete_question: function() {
      var self = this;
      this.$http.delete(this.item.delete_question_url).then(response => {
        if (response.body) {
          self.$parent.best_question_count = response.body.best_question_count;
          self.$parent.questions_list = response.body.product_questions;
          self.$parent.all_question_count = response.body.all_question_count;
          self.$parent.unanswered_question_count = response.body.unanswered_question_count;
          self.$parent.$parent.open_questions = response.body.open_questions;
          self.$parent.$parent.popular_questions = response.body.popular_questions;
          self.$parent.$parent.questions_list = response.body.product_questions;
          self.$parent.$parent.create_question_url = response.body.create_question_path;
          self.$parent.$parent.unanswered_question_count = response.body.unanswered_question_count;
          self.$parent.$parent.best_question_count = response.body.best_question_count;
        }
      });
    },
    unlike_question: function() {
      this.$http.delete(this.item.likes.unlike_url).then(response => {
        this.item.likes = response.body;
      });
    },
    like_question: function() {
      this.$http.post(this.item.likes.like_url).then(response => {
        this.item.likes = response.body;
      });
    }
  }
});

Vue.component('enrolled_item_new_answer', {
  template: `<div v-bind:class="[{new_reply: reply},'padding_0 col-sm-12 add_answer_container']">
              <div v-if="!reply" class="font_open_sans_regular_14">{{$t('enrolled_item_new_answer.add_your_answer')}}</div>
              <div class="col-sm-12 padding_0 margin_top_5 container">
                <div class="col-sm-1 padding_0">
                  <img v-bind:alt="display_name" v-bind:src="display_img" class="left_float add_answer_container img_50 missing_img"></img>
                </div>
                <div class="col-sm-9">
                  <textarea id="new_answer" v-validate="'required'" data-vv-validate-on="none" class="width_100_percent form-control height_56" name="new_reply" :placeholder="$t('enrolled_item_new_answer.enter_your_answer')" v-on:keyup="charactersRemaining" v-model="new_answer"></textarea>
                  <span class ="font_open_sans_regular_11_red" v-show="errors.has('new_reply')">Reply cant be blank</span>
                  <div class="right_float characters_remaining">{{$t('common.characters_remaining')}}:&nbsp{{remaining_characters}}</div>
                </div>
                <div class="col-sm-2 padding_0">
                  <button type="button" class="btn btn-success vertical_align_top" v-on:click="create_answer">{{$t('enrolled_item_new_answer.add_answer')}}</button>
                </div>
              </div>
            </div>`,
  props: {
    create_answer_url: String,
    reply: Boolean,
    display_img: String,
    display_name: String,
    answers_popup: Boolean
  },
  data: function() {
    return {
      remaining_characters: 483,
      new_answer: ''
    }
  },
  methods: {
    charactersRemaining: function() {
      var characters_length = jQuery("#new_answer").val().length;
      this.remaining_characters = 483 - characters_length;
    },
    create_answer: function() {
      var self = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post(this.create_answer_url+'.json?&product_question[comment]='+this.new_answer+'&reply='+this.reply).then(response => {
            if (!this.reply) {
              var parent = this.$parent.$parent;
              if (this.answers_popup) {
                parent = parent.$parent;
              }
              self.$parent.$parent.ans_array.push(response.body.answers_details.answers[0]);
              self.$parent.item.answers_count = response.body.answers_details.answers_count;
              if (response.body.unanswered_questions.length || !response.body.unanswered_questions_count) {
                parent.open_question_show_less = true;
                parent.open_question_show_more = false;
                parent.$parent.open_questions = response.body.unanswered_questions;
                parent.unanswered_question_count = response.body.unanswered_questions.length;
              }
            }
            else {
              self.$parent.answer.reply_details.answers.push(response.body.answers_details.answers[0]);
              self.$parent.show_new_reply = false;
            }
            self.new_answer = '';
            self.remaining_characters = 483;
          });
        }
      });
    }
  }
});

Vue.component('enrolled_item_answer_detail', {
  template: `<div class="margin_left_23 margin_top_10" v-bind:id="'answer_'+id">
              <div class="col-sm-1 padding_0 margin_top_3">
                <img v-bind:alt="answer.display_name" v-bind:src="answer.display_img" class="img_50 left_float missing_img"></img>
              </div>
              <div class="col-sm-11 padding_0 padding_left_15">
                <div class="font_open_sans_regular_14_blue">{{answer.display_name}}</div>
                <div class="font_open_sans_regular_12">{{answer.answer}}</div>
                <div class="font_open_sans_regular_11_blue">
                  <span>{{answer.time_ago}}</span>
                  <span class="cursor_pointer padding_left_30" v-on:click="newReply"><i class="fad fa-reply" style="width: 16px;height: 16px;color: #0000FF"></i></span>
                  <span class="cursor_pointer padding_left_30" v-on:click="delete_answer"><i class="fad fa-trash-alt" style="width: 16px;height: 16px;color: #FF0000"></i></span>
                  <span v-if="liked" class="cursor_pointer padding_left_30" v-on:click="unlike_question"><i class="fad fa-thumbs-down" style="width: 16px;height: 16px;color: #FF0000"></i></span>
                  <span v-else class="cursor_pointer padding_left_30" v-on:click="like_question"><i class="fad fa-thumbs-up" style="width: 16px;height: 16px;color: #008000"></i></span>
                  <span class="badge likes_count" v-if="answer.likes.likes_count">{{answer.likes.likes_count}}</span>
                </div>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="col-sm-11 padding_0 padding_left_15">
                <enrolled_item_answer_detail v-if="answer.reply_details.answers" v-for="answer in answer.reply_details.answers" v-bind:answer="answer" :reply="true" :display_img="display_img" :display_name="display_name" :answers_popup="false"></enrolled_item_answer_detail>
                <enrolled_item_new_answer v-if="show_new_reply" :create_answer_url="answer.reply_details.create_url" :display_img="display_img" :reply="true" :display_name="display_name" :answers_popup="false"></enrolled_item_new_answer>
              </div>
              <hr v-if="!reply" class="col-sm-11 margin_top_10 margin_bottom_5">
            </div>`,
  props: {
    answer: Object,
    reply: Boolean,
    display_img: String,
    display_name: String,
    answers_popup: Boolean
  },
  data: function() {
    return {
      id: '',
      show_new_reply: false,
      liked: this.answer.likes.liked
    }
  },
  mounted: function() {
    this.id = this._uid;
  },
  methods: {
    delete_answer: function() {
      this.$http.delete(this.answer.delete_url+'.json?').then(response => {
        if (response.body) {
          jQuery("#answer_"+this.id).remove();
          if (!this.reply) {
            this.$parent.item.answers_count = response.body.answer_count;
            if (response.body.answer_count == 0) {
              var parent = this.$parent.$parent;
              if (this.answers_popup) {
                parent = parent.$parent;
              }
              parent.open_question_show_less = true;
              parent.open_question_show_more = false;
              parent.$parent.open_questions = response.body.unanswered_questions;
              parent.unanswered_question_count = response.body.unanswered_questions.length;
            }
          }
        }
      });
    },
    newReply: function() {
      jQuery(".new_reply").hide();
      this.show_new_reply = !this.show_new_reply;
    },
    unlike_question: function() {
      this.$http.delete(this.answer.likes.unlike_url).then(response => {
        this.answer.likes = response.body;
        this.liked = response.body.liked;
      });
    },
    like_question: function() {
      this.$http.post(this.answer.likes.like_url).then(response => {
        this.answer.likes = response.body;
        this.liked = response.body.liked;
      });
    }
  }
});

Vue.component('new-comment-popup', {
  template: `<div v-bind:id="thumbnail_id+'_new_comment'" class="comment-thumbnail-popover">
              <div class="comment-thumbnail-tint"></div>
              <div class="new_comment comment-thumbnail-box">
                <p class="item_title"> {{$t('item.add_your_comment')}} </p>
                <form v-bind:id="thumbnail_id+'post_new_comment'" class="form-horizontal padding_left_0 padding_right_0 col-sm-12" v-on:submit.prevent="onSubmit" role="form">
                  <div id="comment_textarea" class="padding_left_0 padding_right_0 col-sm-12">
                    <textarea class="form-control" name="comment[body]" :placeholder="$t('new_comment.write_a_comment')" v-validate="'required'" data-vv-validate-on="none" v-model="comment"></textarea>
                    <div class="error_container">
                      <span class ="font_open_sans_regular_11_red inline_block" v-show="errors.has('comment[body]')" id="comment_error">{{ $t('new_comment.enter_comment') }}</span>
                    </div>
                  </div>
                  <div v-bind:id="thumbnail_id+'_action_links'" class="padding_left_0 padding_right_0 col-sm-12">
                    <div class="pull-right">
                      <button type="submit" class="button_post font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('new_comment.post').toUpperCase()}}</button>
                    </div>
                    <div class="pull-right">
                      <a id="cancel_comment" v-on:click="cancel" class="cancel no_underline">{{$t('common.cancel')}}</a>
                    </div>
                  </div>
                  <div class="success_msg hide col-sm-12" v-bind:id="thumbnail_id+'_comment_success_msg'">{{$t('new_comment.comment_added')}}</div>
                </form>
              </div>
            </div>`,
  props: {
    comments_url: String,
    commentable_id: Number,
    commentable_type: String,
    thumbnail_id: String
  },
  data: function(){
    return{
      title: this.$parent.title,
      comment: ''
    }
  },
  methods: {
    cancel: function() {
      this.$parent.newCommentPopupOpen = false;
    },
    onSubmit: function() {
      var self = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          var params = jQuery('#'+this.thumbnail_id+'post_new_comment').serialize() + '&commentableable_id='+this.commentable_id +'&commentableable_type='+this.commentable_type;
          this.$http.post(this.comments_url+'&'+params).then(response => {
            if(response.body == true) {
              jQuery('#'+this.thumbnail_id+'_comment_success_msg').removeClass('hide');
              jQuery('#'+this.thumbnail_id+'_action_links').addClass('hide');
              setTimeout(function(){
                self.cancel();
              }, 3000);
            }
          });
        }
      });
    }
  }
});

Vue.component('share-popup', {
  template: `<div v-bind:id="popup_id" class="share-thumbnail-popover ">
              <div class="share-thumbnail-tint invite-height-tint"></div>
              <div class="share-thumbnail-box invite-height-box padding_right_5">
                <ul class="list-inline margin_bottom_5 top_share_box_menu">
                  <li class="padding_right_10">
                    <a id="section-filter-link" v-on:mouseover="openPopupFilter" v-on:mouseleave="closePopupFilter"><img class="padding_left_10" src="/images/single_page/menu.png">
                      <share-item-filter v-bind:popup_id="popup_id" :show_share_for_edit="show_share_for_edit"/>
                    </a>
                  </li>
                  <li id="share_menu_header" class="padding_left_0"><span class="string_trunk8 share_header">{{active_share_filter}}</span></li>
                </ul>
                <share_invite_popup v-if="share_type_open == 'invite'" v-bind:popup_id="popup_id" :shareable_id="shareable_id" :shareable_type="shareable_type" :product_recommendation_path="product_recommendation_path" />

                <share_link_popup v-if="share_type_open == 'link'" v-bind:popup_id="popup_id" :link_item="link_item"/>

                <share_embed_popup v-if="share_type_open == 'embed'" v-bind:popup_id="popup_id" :embed_item="embed_item" :embed_url="embed_url" />

                <share_social_popup v-if="share_type_open == 'social'" v-bind:popup_id="popup_id" :title="title" :link_item="link_item" />

                <share_for_edit_popup v-if="share_type_open == 'share_for_edit'" v-bind:popup_id="popup_id" :shareable_id="shareable_id" :shareable_type="shareable_type" :product_permission_path="product_permission_path"/>
              </div>
            </div>`,
  props: {
    popup_id: String,
    item_share_link: Object,
    title_item: String,
    shareable_id: Number
  },
  mounted: function(){
    var self = this;

    setTimeout(function() {
      self.truncate_share_strings();
    }, 50);

    if(this.$store.state.invite_disabled){
      jQuery("#share_social").trigger("click");
    }else{
      this.share_type_open = "invite";
    }
  },
  created: function () {
    window.addEventListener('resize', this.truncate_share_strings);
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.truncate_share_strings);
  },
  data: function(){
    var links;
    links = this.item_share_link;
    return {
      share_type_open: '',
      active_share_filter: this.$t('share_popup_filter.invite'),
      to_user: '',
      link_item: links.product_share_link,
      embed_item: links.embed_code,
      embed_url: links.embed_url,
      social_share_link: links.share_link,
      shareable_type: links.shareable_type,
      product_recommendation_path: links.product_recommendation_path,
      product_permission_path: links.product_permission_path,
      title: this.title_item,
      list_width: 0,
      first_open: true,
      show_share_for_edit: (links.product_permission_path == '') ? false : true
    }
  },
  methods: {
    openPopupFilter: function() {
      jQuery("#"+this.popup_id+"share_item_filter_popup").removeClass('hide').addClass('show');
      if(this.first_open){
        var max_width = 0;
        jQuery("#share_item_filter_list li").each(function(index){
          if(jQuery(this).width() > max_width)
            max_width = jQuery(this).width();
        });
        this.list_width = max_width + 25;
        jQuery("#share_item_filter_list").css('width',this.list_width);
        this.first_open = false
      }
    },
    closePopupFilter: function() {
      jQuery("#"+this.popup_id+"share_item_filter_popup").removeClass('show').addClass('hide');
    },
    cancel_share: function() {
        this.$parent.sharePopupOpen = false;
    },
    truncate_share_strings: function(){
      jQuery('.share-thumbnail-box .string_trunk8').each(function(index){
        initTrunk8Faded(this, false, 1);
      });
    }
  }
});

Vue.component('share_invite_popup', {
  template:`<div v-bind:id="popup_id+'_share_invite'" class="share_type_body">
              <form id="new_recommendation" class="form-horizontal " v-on:submit.prevent="onSubmit" role="form">
                <div class="share_container mt-3">
                  <div v-if="from_item_tile_detail" class="invite_message_header">To</div>
                  <div class="form-group m-0">
                    <div class="col-sm-12 p-0 control">
                      <input id="invite_to" type="text" class="form-control input-sm tags message_input hide" data-vv-validate-on="none" name="user_ids" :placeholder="$t('message.to')" autocomplete="off">
                      <newTagInput v-bind:recipient_err="errors.has('user_ids')" />
                      <div class="error_container">
                        <span id="error_on_user_list" class ="font_open_sans_regular_11_red inbox_err inline_block hide"></span>
                      </div>
                    </div>
                  </div>
                  <div v-if="from_item_tile_detail" class="invite_message_header mt-3">Message</div>
                  <div id="invite_message" :class="[{'mb-0 padding_bottom_30': from_item_tile_detail, 'padding_bottom_8 margin_bottom_4': !from_item_tile_detail}, 'form-group']">
                    <div class="col-sm-12 p-0">
                      <textarea class="form-control share-text-area-invite lxa_font_size_04 txtarea-resize" name="recommendation[message]" placeholder="Message" v-model="message"></textarea>
                    </div>
                  </div>
                  <div v-bind:id="popup_id+'action_for_invite'">
                    <div v-if="from_item_tile_detail" class="pull-right m-0"><button type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase"> SEND INVITATION</button>
                    </div>
                    <div v-else class="pull-right"><button type="submit" class="button_share font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('common.send').toUpperCase()}}</button>
                    </div>
                    <div class="pull-right pr-3">
                      <a id="cancel_invite" role="button" data-dismiss="modal" v-on:click="cancel_share" class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('common.cancel')}}</a>
                    </div>
                  </div>
                  <div class="success_msg m-0 hide" v-bind:id="popup_id+'_share_invite_success_msg'">{{$t('share_popup.send_invite')}}</div>
                </div>
              </form>
              <div v-if="false" class="float_left" id="copy_share_link_message"><button id="copy_share_link" v-on:click="copyMessage()" class="share_copy btn btn-success btn-round-sm">{{$t('share_popup.copy').toUpperCase()}}  {{$t('share_popup_filter.link').toUpperCase()}}</button>
              </div>
            </div>`,
  props: {
    popup_id: String,
    shareable_id: Number,
    shareable_type: String,
    product_recommendation_path: String,
    from_item_tile_detail: Boolean
  },
  data: function() {
    return {
      message: this.$t('share_popup.message_default'),
      response: '',
      auto_complete_url: this.$store.state.username_autocomplete_url
    }
  },
  mounted: function(){
    self = this;
    jQuery('#newTagsInput').typeahead({
      displayField: "email",
      ajax: {
        url: '/home/auto_complete_for_username.json',
        method: 'get',
        triggerLength: 1
      },
      valueField: "name",
      onSelect: function(e) {updateElementForMessages(e);}
    });
    jQuery("input#newTagsInput").focus();

    jQuery('.newTagsList').on('DOMNodeInserted', '.dropdown-menu', function (){
     self.resize_dropdownlist();
    });
    window.addEventListener('resize', this.resize_dropdownlist);

    jQuery(".newTagsList").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",cursorfixedheight: "10",autohidemode: "scroll"});
    jQuery("#invite_message").find('textarea').niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"});
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resize_dropdownlist);
  },
  methods: {
    cancel_share: function() {
        this.$parent.$parent.sharePopupOpen = false;
        jQuery('#share_detail').modal('toggle');
    },
    resize_dropdownlist: function() {
      var flag = false;
      setTimeout(function() {
        jQuery(".dropdown-menu").removeClass('max_content_dropdown');
        jQuery(".dropdown-menu li").each(function(index) {
          if (this.offsetWidth < this.scrollWidth) {
            flag =  true;
          }
        });
        if(flag){
          jQuery(".dropdown-menu").addClass('max_content_dropdown');
        }
      }, 50);
    },
    onSubmit: function() {
      self=this;
      jQuery("#error_on_user_list").removeClass('show').addClass('hide');
      this.$validator.validateAll().then(result => {
        if (result) {
          if (jQuery('#new_recommendation .tagName').length > 0) {
            this.$http.post(this.product_recommendation_path+'?&recommendable_id='+this.shareable_id+'&recommendable_type='+this.shareable_type+'&'+jQuery('#new_recommendation').serialize()).then(response => {
              var result = response;
              if(result.body == true){
                // jQuery("#"+self.popup_id+"_share_invite_success_msg").removeClass('hide').addClass('show');
                // jQuery("#"+self.popup_id+"action_for_invite").addClass('hide');
                // jQuery('#copy_share_link_message').css("margin-top","2%");
                // if(!this.from_item_tile_detail){
                //   setTimeout(function() {
                //     self.cancel_share();
                //   }, 3000);
                // }
                self.$root.popUpMessage('Invite has been Sent Successfully')
                setTimeout(function() {
                  self.cancel_share();
                }, 1000);
              }else{
                jQuery("#error_on_user_list").html(result.body.message);
                jQuery("#error_on_user_list").removeClass('hide').addClass('show');
              }
            });
          } else {
            this.$root.popUpMessage("To Email cannot be empty ");
          }

        }
      });
    },
    copyMessage: function() {
      var $temp, value;
      $temp = jQuery("#"+this.popup_id+"_share_invite").find('textarea');
      value = jQuery("#"+this.popup_id+"_share_invite").find('textarea').val();
      $temp.val(value).select();
      document.execCommand("copy");
      this.$root.popUpMessage("Copied");
    }
  }
});

Vue.component('share_link_popup', {
  template:`<div v-bind:id="popup_id+'_share_link'" class="share_type_body" >
              <form class="form-horizontal " id="share_item_link" v-on:submit.prevent="copyText" role="form">
                <div class="share_container mt-3">
                  <div class="form-group m-0">
                    <div class="col-lg-12 col-md-12 col-sm-12 p-0 control">
                      <input type="text" name="share_link_to" class="form-control input-sm border_bottom_0" v-validate="'required'" data-vv-validate-on="none" placeholder="Link" v-model="link_item" id="event_link" readonly>
                      <p class="share_input_subtext string_trunk8">{{$t('share_popup.link_share')}}</p>
                    </div>
                  </div>
                  <div id="action_for_link" class="col-lg-12 col-md-12 col-sm-12 padding_right_0">
                    <div v-if="from_item_tile_detail" class="pull-right pt-4"><button id="copy_share_link" type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" title="Copy Share Link">{{$t('share_popup.copy').toUpperCase()}}  {{$t('share_popup_filter.link').toUpperCase()}}</button>
                    </div>
                    <div v-else class="pull-right"><button id="copy_share_link" type="submit" class="button_share font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('share_popup.copy').toUpperCase()}}</button>
                    </div>
                    <div class="pull-right pt-4 pr-3">
                      <a id="cancel_link" v-on:click="cancel_share" role="button" class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('common.cancel')}}</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>`,
  props: {
    popup_id: String,
    link_item: String,
    from_item_tile_detail: Boolean
  },
  methods: {
    cancel_share: function() {
        this.$parent.$parent.sharePopupOpen = false;
        jQuery('#share_detail').modal('toggle');
    },
    copyText: function(event) {
      var $temp, value;
      if(event.target.id == "share_item_link"){
        $temp = jQuery("#"+this.popup_id+"_share_link").find('input');
        value = jQuery("#"+this.popup_id+"_share_link").find('input').val();
      }
      $temp.val(value).select();
      document.execCommand("copy");
      this.$root.popUpMessage("Copied");
    }
  }
});

Vue.component('share_embed_popup', {
  template:`<div v-bind:id="popup_id+'_embed_code'" class="share_type_body">
              <form class="form-horizontal " id="share_item_embed_code" v-on:submit.prevent="copyText" role="form">
                <div class="share_container mt-3">
                  <div class="form-group m-0">
                    <div class="col-sm-12 p-0">
                      <textarea v-bind:id="popup_id+'_embed_code_textarea'" class="form-control font_barlow_semi_condensed_light_14 share-text-area-embed txtarea-resize" name="share_embed_script" placeholder="Embed" v-model="embed_item" readonly></textarea>
                      <p class="share_input_subtext string_trunk8">{{$t('share_popup.embed_share')}}</p>
                    </div>
                  </div>
                  <div class="form-group margin_bottom_2 padding_bottom_5 padding_top_5">
                    <div class="col-sm-12 p-0">
                      <div class="p-0 col-sm-12 col-lg-12 col-md-12">
                        <div id="add_thumbnail_div" class="checkbox share_type_checkbox custom-controls custom-checkbox">
                          <input v-on:click="markCheckboxEmbed" id="add_thumbnail" class="share_checkbox custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="thumbnail">
                          <label class="string_trunk8 custom-control-label" for="add_thumbnail">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_07"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_06"></i>
                            <span>{{$t('share_popup.embed_show_thumbnail')}}</span>
                          </label>
                        </div>

                      </div>
                      <div class="p-0 col-sm-12 col-lg-12 col-md-12">
                        <div id="add_thumbnail_div" class="checkbox share_type_checkbox custom-controls custom-checkbox">
                          <input v-on:click="markCheckboxEmbed" id="add_description" class="share_checkbox lxa-bs-checkboxbtn" type="checkbox" value="description">
                        <label class="string_trunk8 custom-control-label" for="add_description">
                          <i class="fad fa-stop lxa_icon_size lxa_text_color_07"></i>
                          <i class="fad fa-check-square lxa_icon_size lxa_text_color_06"></i>
                          <span>{{$t('share_popup.embed_show_description')}}</span>
                         </label>
                        </div>
                      </div>
                      <div class="p-0 col-sm-12 col-lg-12 col-md-12">
                        <div id="add_thumbnail_div" class="checkbox share_type_checkbox custom-controls custom-checkbox">
                          <input v-on:click="markCheckboxEmbed" id="add_title" class="share_checkbox lxa-bs-checkboxbtn" type="checkbox" value="title">
                          <label class="string_trunk8 custom-control-label" for="add_title">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_07"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_06"></i>
                            <span>{{$t('share_popup.embed_show_title')}}</span>
                          </label>
                        </div>

                      </div>
                      <div class="p-0 col-sm-12 col-lg-12 col-md-12">
                        <div id="add_thumbnail_div" class="checkbox share_type_checkbox custom-controls custom-checkbox">
                          <input v-on:click="markCheckboxEmbed" id="add_line_seperator" class="share_checkbox lxa-bs-checkboxbtn" type="checkbox" value="line_separator">
                          <label class="string_trunk8 custom-control-label" for="add_line_seperator">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_07"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_06"></i>
                            <span>{{$t('share_popup.embed_show_line_separator')}}</span>
                          </label>
                        </div>

                      </div>
                      <!-- <div class="p-0 col-sm-12 col-lg-12 col-md-12" v-if="!from_content_player">
                        <div id="add_learning_div" class="checkbox share_type_checkbox custom-controls custom-checkbox">
                          <input v-on:click="markCheckboxEmbed" id="add_learning" class="share_checkbox lxa-bs-checkboxbtn" type="checkbox" value="learning">
                          <label class="string_trunk8 custom-control-label" for="add_learning">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_07"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_06"></i>
                            <span>{{$t('share_popup.embed_show_add_learning')}}</span>
                          </label>
                        </div>

                      </div> -->
                    </div>
                  </div>
                  <div id="action_for_embed">
                    <div v-if="from_item_tile_detail" class="pull-right"><button id="copy_embed_code" type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('share_popup.copy').toUpperCase()}}</button><!--share_submit-->
                    </div>
                    <div v-else class="pull-right"><button id="copy_embed_code" type="submit" class="button_share font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('share_popup.copy').toUpperCase()}}</button>
                    </div>
                    <div class="pull-right pb-1 pr-3">
                      <a id="cancel_embed" v-on:click="cancel_share" class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('common.cancel')}}</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>`,
  props: {
    popup_id: String,
    embed_item: String,
    embed_url: String,
    from_item_tile_detail: Boolean,
    from_content_player: {default: false, type: Boolean}
  },
  mounted: function(){
    setTimeout(function() {
      jQuery("#add_learning").trigger("click");
      jQuery("#add_thumbnail").trigger("click");
      jQuery("#add_description").trigger("click");
      jQuery("#add_title").trigger("click");
      jQuery("#add_line_seperator").trigger("click");
    }, 50);

    jQuery("#"+this.popup_id+'_embed_code_textarea').niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"});
  },
  methods: {
    cancel_share: function() {
        this.$parent.$parent.sharePopupOpen = false;
        jQuery('#share_detail').modal('toggle');
    },
    updateEmbedCode: function() {
      var embedUrl = this.embed_url;
      var embedOptions = [], urlWithEmbedOptions = '';
      jQuery("#"+this.popup_id+"_embed_code").find('.share_type_checkbox input[type=checkbox]').each(function(index, checkbox) {
        if (jQuery(checkbox).prop("checked") == true){
          embedOptions.push(jQuery(checkbox).val() + '=1');    //e.g. thumbnail=1
        }
      });
      if(embedOptions.length > 0) {
          urlWithEmbedOptions = embedUrl + '?' + embedOptions.join('&');
      } else {
          urlWithEmbedOptions = embedUrl;
      }
      var re = /src=\"(.*?)\"/,
          embedCode = this.embed_item;

      embedCode = embedCode.replace(re, function() {
          var src = "src=\"";
          src += urlWithEmbedOptions;
          src += "\"";
          return src;
      });
      jQuery("#"+this.popup_id+"_embed_code").find('textarea').val(embedCode);
    },
    markCheckboxEmbed: function(event) {
      if(jQuery(event.target).attr('Checked')){
        jQuery("#"+event.target.id+"_div").find('.tick_local').removeClass("tick_span");
        jQuery(event.target).removeAttr('Checked');
      }else{
        jQuery("#"+event.target.id+"_div").find('.tick_local').addClass("tick_span");
        jQuery(event.target).attr('Checked','Checked');
      }
      this.updateEmbedCode();
    },
    copyText: function(event) {
      var $temp, value;
      if(event.target.id == "share_item_embed_code"){
        $temp = jQuery("#"+this.popup_id+"_embed_code").find('textarea');
        value = jQuery("#"+this.popup_id+"_embed_code").find('textarea').val();
      }
      $temp.val(value).select();
      document.execCommand("copy");
      this.$root.popUpMessage("Copied");
    }
  }
});

Vue.component('share_social_popup', {
  template:`<div v-bind:id="popup_id+'_social_share'" class="share_type_body" >
              <form id="share_social" class="form-horizontal" role="form" v-on:submit.prevent="cancel_share">
                <div class="share_container mt-3">
                  <div class="form-group m-0">
                    <div class="col-sm-12 p-0 control mb-4">
                      <div id="social_media_share_icons" class="social_icons">
                        <div :class="[{'padding_right_34': from_item_tile_detail}]">
                          <a id="share_fb" target="_blank" data-share="facebook" :data-title="title" :data-link="link_item" ><span id="icon_fb" v-on:click="share_social_media"><i class="fab fa-facebook-square lxa_font_size_10 lxa_text_color_06"></i></span></a>
                        </div>
                        <div :class="[{'padding_right_34': from_item_tile_detail}]">
                          <a id="share_twit" target="_blank" data-share="twitter" :data-title="title" :data-link="link_item" ><span id="icon_twit" v-on:click="share_social_media"><i class="fab fa-twitter-square lxa_font_size_10 lxa_text_color_07"></i></span></a>
                        </div>
                        <div :class="[{'padding_right_34': from_item_tile_detail}]">
                          <a id="share_gplus" target="_blank" data-share="googleplus" :data-title="title" :data-link="link_item"><span id="icon_gplus" v-on:click="share_social_media"><i class="fab fa-google-plus-square lxa_font_size_10 lxa_text_color_01"></i></span></a>
                        </div>
                      </div>
                      <div class="m-0 p-0 col-lg-12 col-sm-12 col-md-12">
                        <p class="string_trunk8 share_input_subtext">{{$t('share_popup.social_share')}}</p>
                      </div>
                    </div>
                  </div>
                  <div id="action_for_social" class="padding_top_2">
                    <div v-if="from_item_tile_detail" class="pull-right"><button type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('common.done').toUpperCase()}}</button></div><!--share_submit-->
                    <div v-else class="pull-right"><button type="submit" class="button_share font_open_sans_semibold_10_blue btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('common.done').toUpperCase()}}</button></div>
                    <div class="pull-right pb-1 pr-3">
                      <a id="cancel_embed" v-on:click="cancel_share" class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('common.cancel')}}</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>`,
  props: {
    popup_id: String,
    link_item: String,
    title: String,
    from_item_tile_detail: Boolean

  },
  methods: {
    cancel_share: function() {
      if(this.from_item_tile_detail == false){
        this.$parent.$parent.sharePopupOpen = false;
      }else {
        jQuery('#share_detail').modal('toggle');
      }
    },
    share_social_media: function(event){
      switch(event.currentTarget.getAttribute("id")) {
        case "icon_fb":
          jQuery("#share_fb").trigger("click");
          break;
        case "icon_twit":
          jQuery("#share_twit").trigger("click");
          break;
        case "icon_gplus":
          jQuery("#share_gplus").trigger("click");
          break;
      }
    }
  }
});

Vue.component('share_for_edit_popup', {
  template:`<item-loader v-if="!items_requested" />
            <div v-else v-bind:id="popup_id+'_share_for_edit'" class="share_type_body">
              <div v-if="!from_item_tile_detail" class="height_25 margin_right_5 lxa_font_size_04">
                <input type="text" id="search_user" class="form-control input-sm lxa_border_radius lxa_text_color_02 primary_bgcolor lxa_font_size_04 border-0" v-model="search_item" :placeholder="$t('search.search_users')" v-on:keyup="search_users"/>
                <img src="/images/single_page/search.png" class="search_user_img lxa_text_color_05 lxa_font_size_04"></img>
              </div>
              <form class="form-horizontal " v-on:submit.prevent="onSubmit" role="form">
                <div class="share_container mt-3">
                  <div class="form-group list_container_share_for_edit">
                    <div class="col-sm-12 p-0 control">
                      <div id="can_edit_users" class="padding_left_0 padding_right_0 margin_top_1 col-sm-12 col-lg-12 col-md-12">
                        <div :class="[{'paddingBottom10': from_item_tile_detail}, 'padding_left_0 padding_right_0 col-sm-12 col-lg-12 col-md-12 margin_top_7']">
                          <label v-if="from_item_tile_detail" class="label_bold string_trunk8 padding_bottom_5 padding_left_0 padding_right_0 col-sm-12 col-lg-12 col-md-12" style="color:#808080">Who can edit this Course?</label>
                          <label v-else class="label_bold string_trunk8 padding_bottom_3 padding_left_0 padding_right_0 col-sm-12 col-lg-12 col-md-12">{{$t('share_popup.can_edit_users')}}</label>
                          <div id="selected_users" class="padding_left_0 padding_right_0 col-sm-12 col-lg-12 col-md-12">
                            <label v-if="selected_items_sorted_Array.length == 0" class="padding_left_0 padding_right_0 col-sm-12 col-lg-12 col-md-12">{{$t('common.none')}}</label>
                            <ul id="selected_items_list" v-if="selected_items_sorted_Array.length != 0" :class="[{'col-sm-12 col-lg-12 col-md-12': from_item_tile_detail, 'col-sm-10 col-lg-10 col-md-10' : !from_item_tile_detail}, 'padding_left_0 padding_right_0']">
                              <li v-bind:id="'user_list' + item.id" v-on:click="unselect_user($event,item,index)" v-for="(item,index) in selected_items_sorted_Array" v-bind:key="item.id" class="stop_close padding_left_0 padding_right_0 col-sm-12 col-lg-12 col-md-12">
                                <div class="stop_close checkbox share_type_checkbox">
                                  <input v-bind:id="'checkbox_' + item.id" type="checkbox" class="stop_close share_checkbox">
                                  <span v-bind:id="'tick_id' + item.id" class="stop_close tick_local tick_span"></span>
                                </div>
                                <label v-bind:id="'label' + item.id" :class="[{'margin_left_10': from_item_tile_detail}, 'stop_close p-0 col-sm-11 col-lg-11 col-md-11']">{{item.title}}</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <hr v-if="from_item_tile_detail" class="m-0 share_edit_border"/>
                        <div v-if="from_item_tile_detail" class="height_25 margin_right_5">
                          <input type="text" id="search_user" class="form-control input-sm lxa_border_radius lxa_text_color_02 primary_bgcolor lxa_font_size_04 border-0" v-model="search_item" :placeholder="$t('search.search_users')" v-on:keyup="search_users"/>
                          <div class="search_user_img"><i class="fad fa-search lxa_text_color_05 lxa_font_size_04"></i></div>
                        </div>
                        <div v-if="from_item_tile_detail" class="select_all_users paddingBottom10">


                        <div id="every_one_wrapper" class="every_one_wrapper custom-controls custom-checkbox">
                        <input v-on:click="select_all_user()" id="every_one" name="every_one" class="every_one select_all_checkbox custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="select all">
                        <label class="string_trunk8 custom-control-label" for="every_one">
                        <i class="fad fa-stop lxa_icon_size lxa_text_color_01"></i>
                        <i class="fad fa-check-square lxa_icon_size lxa_text_color_01"></i>
                        <span class="every_one_label ml-2">{{$t('announcement.select_all')}}</span>
                        </label>
                        </div>


                        </div>
                        <div class="padding_left_0 padding_right_0 col-sm-12 col-lg-12 col-md-12 margin_top_5">
                          <label v-if="!from_item_tile_detail" class="label_bold padding_bottom_3 padding_left_0 padding_right_0 col-sm-12 col-lg-12 col-md-12" >{{$t('common.available_users')}}</label>
                          <div id="available_users" class="padding_left_0 padding_right_0 col-sm-12 col-lg-12 col-md-12">
                            <label v-if="available_items_sorted_Array.length == 0">{{$t('common.none')}}</label>
                            <ul id="available_items_list" v-if="available_items_sorted_Array.length != 0" :class="[{'col-sm-12 col-lg-12 col-md-12': from_item_tile_detail, 'col-sm-10 col-lg-10 col-md-10' : !from_item_tile_detail}, 'padding_left_0 padding_right_0']">
                              <li v-bind:id="'user_list' + item.id" v-on:click="select_user($event,item,index)" v-for="(item,index) in available_items_sorted_Array" v-bind:key="item.id" class="stop_close padding_left_0 padding_right_0 col-sm-12 col-lg-12 col-md-12">
                                <div class="stop_close checkbox share_type_checkbox">
                                  <input v-bind:id="'checkbox1_' + item.id" type="checkbox" class="stop_close share_checkbox">
                                </div>
                                <label v-bind:id="'label_' + item.id" :class="[{'margin_left_10': from_item_tile_detail}, 'stop_close p-0 m-0 col-sm-11 col-lg-11 col-md-11']">{{item.title}}</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-bind:id="popup_id+'action_for_share_edit'" :class="[{'submit_container': from_item_tile_detail}]">
                    <div v-if="from_item_tile_detail" class="pull-right padding_bottom_5"><button type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('common.save').toUpperCase()}}</button>
                    </div>
                    <div v-else class="pull-right"><button type="submit" class="button_share font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('common.save').toUpperCase()}}</button>
                    </div>
                    <div v-if="!from_item_tile_detail" class="pull-right pr-2">
                      <a id="cancel_invite" v-on:click="cancel_share" class="btn btn-secondary btn-sm">{{$t('common.cancel')}}</a>
                    </div>
                  </div>
                  <div class="success_msg hide" v-bind:id="popup_id+'_share_edit_success_msg'">{{$t('share_popup.share_edit')}}</div>
                </div>
              </form>
            </div>`,
  props: {
    popup_id: String,
    shareable_id: Number,
    shareable_type: String,
    product_permission_path: String,
    from_item_tile_detail: Boolean
  },
  data: function() {
    return {
      search_item: '',
      share_selected_items: [],
      share_available_items: [],
      product_permission_create_path: '',
      first_scroll_available: false,
      first_scroll_selected: false,
      items_requested: false,
      selectAllUser: false
    }
  },
  computed: {
      available_items_sorted_Array: function() {
        this.share_available_items.sort(( a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase())
            return -1;
          if (a.title.toLowerCase() > b.title.toLowerCase())
            return 1;
          return 0;
        });
        return this.share_available_items;
      },
      selected_items_sorted_Array: function() {
        this.share_selected_items.sort(( a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase())
            return -1;
          if (a.title.toLowerCase() > b.title.toLowerCase())
            return 1;
          return 0;
        });
        return this.share_selected_items;
      }
    },
  mounted: function(){
    var self = this;
    jQuery("#"+this.popup_id).find('.circular_loader').removeClass('hidden');
    jQuery("#"+this.popup_id).find('svg').attr("width", "15px");

    this.$http.get(this.product_permission_path+'.json?'+'&associated_id='+this.shareable_id+'&associated_type='+this.shareable_type).then(response => {
      var result = response.body;
      if(result.success == true){
        this.share_available_items = result.available_items;
        this.share_selected_items = result.selected_items;
        this.product_permission_create_path = result.product_permission_create_path;
        this.items_requested = true;

        setTimeout(function() {
          self.$parent.$options.methods.truncate_share_strings();
        }, 50);
      }
    });

    jQuery('#'+this.popup_id).on('DOMNodeInserted', '#'+this.popup_id+'_share_for_edit', function(){
      jQuery("#"+self.popup_id+" #available_users").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"});
      jQuery("#"+self.popup_id+" #selected_users").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"});
    });

  },
  methods: {
    search_users: function(){
      search_and_highlight("available_items_list", this.search_item, 'inline-flex');
      search_and_highlight("selected_items_list", this.search_item, 'inline-flex', false);
    },
    cancel_share: function() {
        this.$parent.$parent.sharePopupOpen = false;
    },
    select_user: function(event,item,index){
      this.share_available_items.splice(index, 1);
      this.share_selected_items.splice(index, 0, item);
      this.resizeNiceScroll();
    },
    unselect_user: function(event,item,index){
      this.share_selected_items.splice(index, 1);
      this.share_available_items.splice(index, 0, item);
      this.resizeNiceScroll();
    },
    onSubmit: function() {
      self=this;
      var products = [];
      for (var i = 0; i < this.share_selected_items.length; i++) {
        products[i] = this.share_selected_items[i].id;
      }
      var product_id = JSON.stringify(products);
      this.$http.post(this.product_permission_create_path+'.json?'+'&associated_id='+this.shareable_id+'&associated_type='+this.shareable_type+'&json=true'+'&items='+product_id).then(response => {
        var result = response;

        if(result.body == true){
          jQuery("#"+self.popup_id+"_share_edit_success_msg").removeClass('hide').addClass('show');
          jQuery("#"+self.popup_id+"action_for_share_edit").addClass('hide');
          setTimeout(function() {
            self.cancel_share();
          }, 3000);
        }
      });
    },
    resizeNiceScroll: function() {
      var self = this;
      setTimeout(function() {
        jQuery("#"+self.popup_id+" #available_users").getNiceScroll().resize();
        jQuery("#"+self.popup_id+" #selected_users").getNiceScroll().resize();
      }, 300);
    },
    select_all_user: function(){
      this.selectAllUser = !this.selectAllUser;
      if (this.selectAllUser == true) {
        if (this.share_available_items.length > 0) {
          this.share_selected_items.push.apply(this.share_selected_items, this.share_available_items);
          this.share_available_items = [];
        }
      }
      else {
        this.select_none_user();
      }
    },
    select_none_user: function(){
      if (this.share_selected_items.length != 0) {
        this.share_available_items.push.apply(this.share_available_items, this.share_selected_items);
        this.share_selected_items = [];
      }
    }
  }
});

Vue.component('share-item-filter', {
  template:`<div v-bind:id="popup_id+'share_item_filter_popup'" class="share-filter custom_popover fade bottom in hide">
              <div class="arrow_img"><img src="/images/single_page/arrow.png"></div>
              <div class="popover-content">
                <ul id="share_item_filter_list" class="filter">
                  <li v-if="!share_invite_disabled" v-on:click="onClick" id="invite">
                    {{$t('share_popup_filter.invite')}}<span class="pull-right"><img src="/images/single_page/tick.png"></span>
                  </li>
                  <li v-if="!share_invite_disabled" v-on:click="onClick" id="link">{{$t('share_popup_filter.link')}}</li>
                  <li v-if="!share_invite_disabled" v-on:click="onClick" id="embed">{{$t('share_popup_filter.embed')}}</li>
                  <li v-if="!share_social_disabled" v-on:click="onClick" id="social">{{$t('share_popup_filter.social')}}</li>
                  <li v-if="!share_invite_disabled && show_share_for_edit" v-on:click="onClick" id="share_for_edit">{{$t('share_popup_filter.share_for_edit')}}</li>
                </ul>
              </div>
            </div>`,
  props: {
    popup_id: String,
    show_share_for_edit: Boolean
  },
  data: function() {
    return {
      share_invite_disabled: this.$store.state.invite_disabled,
      share_social_disabled: this.$store.state.social_disabled
    }
  },
  mounted: function(){
  },
  methods: {
    onClick: function(event) {
      var self = this;
      if(event.target.id != this.$parent.share_type_open)
      {
        jQuery("#share_item_filter_list li").each(function(index){
          jQuery(this).find('span').remove();
        });
        jQuery(event.target).append('<span class="pull-right"><img src="/images/single_page/tick.png"></span>');

        this.setData(event,this.$t('share_popup_filter.'+event.target.id));
        if(event.target.id == "social"){
          setTimeout(function() {
            jQuery("#social_media_share_icons").socialSharers();
          }, 50);
        }
        setTimeout(function() {
          self.$parent.$options.methods.truncate_share_strings();
        }, 50);
        this.$parent.search_item = '';
      }
    },
    setData: function(event, filter){
      this.$parent.share_type_open = event.target.id;
      this.$parent.active_share_filter = filter;
      jQuery('#share_menu_header').find('.share_header').remove();
      jQuery('#share_menu_header').append('<span class="string_trunk8 share_header">'+filter+'</span>');
    }
  }
});

Vue.component('item-bookmark', {
  template: `<div v-if="bookmarked" class="bookmark inline_block">
              <img src="/images/single_page/bookmarked.png" alt="bookmarked" title="Bookmarked">
            </div>
            <div v-else class="bookmark inline_block" v-bind:class="{padding_right_10: hide_more_icon}">
              <img v-on:click="onBookmarkClick" v-on:mouseover="onMouseOver" v-on:mouseleave="onMouseLeave" src="/images/single_page/bookmark.png" class="cursor_pointer" alt="bookmark" title="Bookmark">
            </div>`,
  props: {
    bookmarked: Boolean,
    bookmarks_url: String,
    hide_more_icon: Boolean,
    logged_in: Boolean
  },
  methods: {
    onBookmarkClick: function(event) {
      if(this.logged_in) {
        this.$http.post(this.bookmarks_url).then(response => {
          jQuery(event.target).attr('src', '/images/single_page/bookmarked.png');
          jQuery(event.target).attr('title', 'Bookmarked');
          jQuery(event.target).removeClass('cursor_pointer');
          jQuery(event.target).addClass('disabled');
        });
      } else {
        show_login_popup(this.$root);
      }
    },
    onMouseOver: function(event) {
      if(!jQuery(event.target).hasClass('disabled')) {
        jQuery(event.target).attr('src', '/images/single_page/bookmarked.png');
      }
    },
    onMouseLeave: function(event) {
      if(!jQuery(event.target).hasClass('disabled')) {
        jQuery(event.target).attr('src', '/images/single_page/bookmark.png');
      }
    }
  }
});

Vue.component('item-rating', {
  template: `<div v-if="from_item_tile_detail">
              <div class="rating_container">
                <span v-on:click="rate_item" class="cursor_pointer"><i class="cursor_pointer fad fa-star font_barlow_semi_condensed_light_16 lxa_text_color_05"></i></span>
                <span v-on:click="rate_item" class="cursor_pointer average_rating lxa_section_light"> {{rating}} </span>
                <tile-rate-item v-if="item_rate" :ratings_url="ratings_url" :rates_count="rates_count" :average_rating="average_rating" :logged_in="logged_in" :product="product" :popup_id="popup_id" :completed="completed" :rated_by_user="rated_by_user"/>
              </div>
            </div>
            <div v-else>
              <div v-if="rated_by_user" class="rating_container padding_top_10 pull-left">
                <input disabled="disabled" v-bind:id="ratings_id" type="text" class="kv-uni-star rating-loading" :value="rating" data-size="xs" title="">
                <span v-if="count > 0" v-on:click="showRating" class="count cursor_pointer"> ({{count}}) </span>
                <rating-detail-popup v-if="show_popup" v-click-outside="outside" :rating_details="rating_details" :popup_id="popup_id"/>
              </div>
              <div v-else class="rating_container padding_top_10 pull-left">
                <input v-on:click="showRating" v-bind:id="ratings_id" type="text" class="kv-uni-star rating-loading" :value="rating" data-size="xs" title="">
                <span v-if="count > 0" v-on:click="showRating" class="count cursor_pointer"> ({{count}}) </span>
                <rating-detail-popup v-if="show_popup" v-click-outside="outside" :rating_details="rating_details" :popup_id="popup_id"/>
              </div>
            </div>`,
  props: {
    ratings_id: String,
    ratings_url: String,
    rates_count: Number,
    average_rating: Number,
    rated_by_user: Boolean,
    logged_in: Boolean,
    product: Object,
    from_item_tile_detail: Boolean
  },
  data: function() {
    return {
      count: this.rates_count,
      rating: this.average_rating,
      show_popup: false,
      popup_id: "rating_details_"+new Date().getTime(),
      rating_details: [],
      item_rate: false,
      completed: Object.keys(this.product.enrollment).length === 0 ? false : (this.product.enrollment.progress_percent == 100 ? true : false)
    }
  },
  watch: {
    count: function () {
      if(this.from_item_tile_detail == true) {
        EventBus.$emit('CourseRatingCount', this.count);
      }
    }
  },
  mounted: function() {
    var self = this;
    var curr = this;
    jQuery('#'+this.ratings_id).rating({
      theme: 'krajee-uni',
      filledStar: '&#x2605;',
      emptyStar: '&#x2606;',
      showClear: false,
      showCaption: false,
      step: 1
    });
    jQuery('#'+this.ratings_id).on('rating.change', function(event, target) {
      if(self.logged_in) {
        jQuery(this).rating("refresh", {disabled:true, showClear:false});
        curr.$http.post(curr.ratings_url+'&rating='+this.value).then(response => {
          curr.rating = response.body;
          curr.count += 1;
          curr.rated_by_user = true;
          jQuery(this).rating("refresh", {disabled:true, showClear:false});
        });
      } else {
        show_login_popup(self.$root);
      }
    });
  },
  methods: {
    showRating: function() {
      var url = this.ratings_url;
      var curr = this;
      this.$http.get(curr.ratings_url).then(response => {
        curr.show_popup = true;
        curr.rating_details = response.body;
      });
    },
    outside: function() {
      if(this.show_popup) {
        this.show_popup = false;
      } else {
        this.show_popup = true;
      }
    },
    rate_item: function() {
      this.item_rate = true;
      setTimeout(function(){
        jQuery('#rate_detail').insertAfter(jQuery('body'));
        jQuery('#rate_detail').modal('toggle');
      },50);
    }
  }
});

Vue.component('rating-detail-popup', {
  template:`<div v-bind:id="popup_id" class="custom_popover fade bottom in show">
              <div class="arrow_img"><img src="/images/single_page/arrow.png"></div>
              <div class="popover-content rating_score_breakup">
                <ul class='filter'>
                  <li class="widget_title_medium">{{this.rating_details.title}}</li>
                  <li v-for="score in [5, 4, 3, 2, 1]">
                    <div class="col-lg-2 rating_text"> {{score+' '}} star </div>
                    <div class="col-lg-6 empty_progress">
                      <div class="filled_progress" v-bind:style="{width:[(100 * score_breakup[score])/total_ratings]+'px'}"></div>
                    </div>
                    <div class="col-lg-3 score_count"> {{score_breakup[score]}} </div>
                  </li>
                </ul>
                <hr>
                <a id="cancel_name" v-on:click="onClick" class="cancel pull-right no_underline">{{$t('common.cancel')}}</a>
              </div>
            </div>`,
  props: {
    popup_id: String,
    rating_details: Object
  },
  data: function() {
    return {
      score_breakup: this.rating_details.score_breakup,
      total_ratings: this.rating_details.total_ratings
    }
  },
  methods: {
    onClick: function() {
      this.$parent.show_popup = false;
    }
  }
});

Vue.component('enrolled-items-menu', {
  template:`<div id="enrolled_items_menu_popup" class="menu_large custom_popover fade bottom in hide">
              <div class="arrow_img"><img src="/images/single_page/arrow.png"></div>
              <div class="popover-content">
                <ul id="enrolled_items_filter_list" class="filter font_open_sans_regular_14">
                  <li v-on:click="onClick">
                    <div class="col-sm-10 filter_label" id="in_progress"> {{$t('enrolled_item_filter.in_progress')}} </div> <div class="col-sm-2 tick_mark"> <span class="pull-right"><img src="/images/single_page/tick.png"></span> </div>
                  </li>
                  <li v-on:click="onClick"><div class="col-sm-10 filter_label" id="recently_completed"> {{$t('enrolled_item_filter.recently_completed')}} </div></li>
                  <li v-on:click="onClick"><div class="col-sm-10 filter_label" id="all_completed"> {{$t('enrolled_item_filter.all_completed')}} </div></li>
                  <li v-on:click="onClick"><div class="col-sm-10 filter_label" id="cancelled"> {{$t('enrolled_item_filter.cancelled')}} </div></li>
                  <li v-on:click="onClick"><div class="col-sm-10 padding_bottom_8 filter_label" id="available_soon"> {{$t('enrolled_item_filter.due_soon')}} </div></li>
                </ul>
              </div>
            </div>`,
  data: function() {
    return {
      enrolled_items_active_filter: "in_progress"
    }
  },
  methods: {
    resetSortFilter: function(event) {
      resetSortFilterOptions(this, "enrolled_items", event);
    },
    onClick: function(event) {
      this.resetSortFilter(event);
      jQuery("#enrolled_items_filter_list li").each(function(index){
        jQuery(this).find('.tick_mark').remove();
      });
      jQuery('<div class="col-sm-2 tick_mark"> <span class="pull-right"><img src="/images/single_page/tick.png"></span> </div>').insertAfter(event.target);
      this.enrolled_items_active_filter = event.target.id;
      switch(event.target.id) {
        case "in_progress":
          this.$http.get('/my_learnings/upcoming.json?per_page='+items_per_page()).then(response => {
            this.$parent.recently_completed = false;
            this.$parent.cancelled = false;
            this.$parent.all_completed = false;
            this.$parent.available_soon = false;
            this.$parent.in_progress = true;
            this.$parent.in_progress_items = response.body;
            filter_callback(response.body, "enrolled_items");
          });
          break;
        case "recently_completed":
          this.$http.get('/my_learnings/completed_items.json?per_page='+items_per_page()).then(response => {
            this.$parent.in_progress = false;
            this.$parent.all_completed = false;
            this.$parent.cancelled = false;
            this.$parent.available_soon = false;
            this.$parent.recently_completed = true;
            this.$parent.recently_completed_items = response.body;
            filter_callback(response.body, "enrolled_items");
          });
          break;
        case "all_completed":
          this.$http.get('/my_learnings/completed_items.json?all=true').then(response => {
            this.$parent.in_progress = false;
            this.$parent.recently_completed = false;
            this.$parent.cancelled = false;
            this.$parent.available_soon = false;
            this.$parent.all_completed = true;
            this.$parent.all_completed_items = response.body;
            filter_callback(response.body, "enrolled_items");
          });
          break;
        case "cancelled":
          this.$http.get('/my_learnings/upcoming/canceled.json').then(response => {
            this.$parent.in_progress = false;
            this.$parent.recently_completed = false;
            this.$parent.cancelled = true;
            this.$parent.all_completed = false;
            this.$parent.available_soon = false;
            this.$parent.cancelled_items = response.body;
            filter_callback(response.body, "enrolled_items");
          });
          break;
        case "available_soon":
          this.$http.get('/my_learnings/available_soon_items.json?all=true').then(response => {
            this.$parent.in_progress = false;
            this.$parent.recently_completed = false;
            this.$parent.cancelled = false;
            this.$parent.all_completed = false;
            this.$parent.available_soon = true;
            this.$parent.available_soon_items = response.body;
            filter_callback(response.body, "enrolled_items");
          });
          break;
      }
    }
  }
});

Vue.component('enrolled-items-more-link', {
  template: `<div v-bind:id="popup_id" class="custom_popover fade bottom in hide">
              <div class="arrow_img"><img src="/images/single_page/arrow.png"></div>
              <div class="popover-content">
                <ul class="widget_menu_medium filter font_open_sans_regular_14">
                  <li v-on:click="onClick" id="progress_details"> {{$t('enrolled_item_more_link.progress')}} <span id="progress_details">{{progress_percent}}%</span>
                    <content-progress v-for="content in contents" :item="content" :key="content.id"/>
                  </li>
                  <li v-on:click="onClick" id="questions" class="color_grey" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#questionaire_modal">{{$t('enrolled_item_more_link.questions')}}</li>
                  <li v-if="show_cancel" v-on:click="onClick" id="cancel">{{$t('common.cancel')}}</li>
                </ul>
              </div>
            </div>`,
  props: {
    popup_id: String,
    progress_percent: Number,
    enrollment_id: Number,
    cancel_url: String,
    item_id: String,
    product_question_url: String,
    item: Object,
    thumbnail_url: String
  },
  data: function() {
    return {
      contents: [],
      show_cancel: true
    }
  },
  mounted: function() {
    if ((this.$root.$children[0].enrolled_items_active_filter == 'recently_completed') || (this.$root.$children[0].enrolled_items_active_filter == 'all_completed')) {
      this.show_cancel = false;
    }
  },
  methods: {
    onClick: function(event) {
      switch(event.target.id) {
        case "progress_details":
          if(jQuery(event.target).hasClass('expanded')) {
            this.contents = [];
            jQuery("#"+this.popup_id).css('width', '145px');
            jQuery("#"+this.popup_id).css('margin-left', '-122px');
            jQuery(event.target).removeClass('expanded');
            jQuery(event.target).find('span').removeClass('expanded');
          } else {
            this.$http.get('/'+this.enrollment_id+'/progress_scores.json').then(response => {
              this.contents = response.body;
              jQuery("#"+this.popup_id).css('width', '280px');
              jQuery("#"+this.popup_id).css('margin-left', '-244px');
              jQuery(event.target).addClass('expanded');
              jQuery(event.target).find('span').addClass('expanded');
            });
          }
          break;
        case "cancel":
          if(confirm(this.$t('enrolled_item_more_link.cancel_text'))) {
            this.$http.delete(this.cancel_url).then(response => {
              jQuery("#"+this.item_id).remove();
            });
          }
          break;
        case "questions":
          var self = this;
          this.$http.get(this.product_question_url+'?&lightbox=true'+'&single_page=true').then(response => {
            EventBus.$emit("ShowQuestions",response.body,this.item,this.thumbnail_url);
          });
          break;
      }
    }
  }
});

Vue.component('available-items-list', {
  template: `<div v-bind:class="[{padding_bottom_15: total_pages == 1}, 'category_list']" :id="category.replace(' ','_')">
              <p v-bind:key="new Date().getTime()" class="padding_left_15 category_title"> {{category}} </p>
              <available-item-category-list v-bind:container_name="container_name" :list="list" :logged_in="logged_in" :ref="list" :shared_item= "shared_item" v-for="list in list_arr" />
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <span v-if="current_page != total_pages" v-on:click="onSeeMoreClick" class="see_more">{{$t('item.see_more')}}</span>
                <span v-if="total_pages > 1 && current_page == total_pages" v-on:click="onSeeLessClick" class="see_more">{{$t('item.see_less')}}</span>
              </div>
            </div>`,
  props: {
    item: Object,
    logged_in: Boolean,
    block_name: String,
    shared_item: Number
  },
  data: function() {
    var obj = Object.values(this.item)[0];
    return {
      list_arr: [this.item],
      category: Object.keys(this.item)[0],
      current_page: obj.params.current_page,
      total_pages: obj.params.total_pages,
      category_id: obj.params.category_id,
      search_string: obj.params.q,
      price_min: obj.params.price_min,
      price_max: obj.params.price_max,
      content_type: obj.params.content_type,
      average_rating: obj.params.average_rating,
      order_by: obj.params.order_by,
      order: obj.params.order,
      next_list: [],
      container_name: this.block_name+'_container',
    }
  },
  mounted: function() {
    var self = this;
    setTimeout(function(){
      truncateItemText(self.block_name);
    },500);
  },
  methods: {
    onSeeMoreClick: function(event) {
      var page = this.current_page + 1;
      var params = 'page='+page+'&per_page='+items_per_page()+'&from_single_page=true&others=true';
      if(this.search_string) {
        params += '&q='+this.search_string;
      } else if(this.category_id) {
        params += '&category_id[]='+[this.category_id];
      }
      if(this.order_by && this.order) {
        params += '&order_by='+this.order_by+'&order='+this.order;
      }
      if(this.price_min && this.price_max) {
        params += '&price_min='+this.price_min+'&price_max='+this.price_max;
      }
      if(this.content_type) {
        params += '&content_type='+JSON.stringify(this.content_type);
      }
      if(this.average_rating) {
        params += '&average_rating='+JSON.stringify(this.average_rating);
      }
      this.$http.get('/contents.json?'+params).then(function(response) {
        var res = response.body[0];
        this.list_arr.push(res);
        this.current_page = Object.values(res)[0].params.current_page;
        this.total_pages = Object.values(res)[0].params.total_pages;
        jQuery("html, body").animate({ scrollTop: jQuery(event.target).offset().top }, 2000);
        var block_name = Object.keys(res)[0].replace(' ','_')+'_'+Object.values(res)[0].params.current_page;
        setTimeout(function() {
          truncateItemText(block_name);
        },500);
      });
    },
    onSeeLessClick: function(event) {
      var curr = this;
      jQuery("html, body").animate({ scrollTop: jQuery(event.target).parent().parent().offset().top }, 2000);
      curr.list_arr.forEach(function(item, i) {
        if(i !== 0)
          curr.list_arr.splice(i);
      });
      curr.current_page = Object.values(curr.item)[0].params.current_page;
      curr.total_pages = Object.values(curr.item)[0].params.total_pages;
      curr.category_id = Object.values(curr.item)[0].params.category_id;
    }
  }
});

Vue.component('available-items-menu', {
  template:`<div id="available_items_menu_popup" class="menu_large custom_popover fade bottom in hide">
              <div class="arrow_img"><img src="/images/single_page/arrow.png"></div>
              <div class="popover-content">
                <ul id="available_items_filter_list" class="filter font_open_sans_regular_14">
                  <li v-on:click="onClick" v-for="(item, index) in filter_list" :id="(item instanceof Object) ? Object.values(item)[0] : item" :data-categoryid="(item instanceof Object) ? Object.keys(item)[0] : ''">
                    {{(item instanceof Object) ? Object.values(item)[0] : item}} <span v-if="index==0" class="pull-right tick_mark"><img v-if="index==0" src="/images/single_page/tick.png"></span>
                  </li>
                </ul>
              </div>
            </div>`,
  props: {
    filter_list: Array
  },
  data: function() {
    return {
      available_items_active_filter: this.$t('available_item_filter.all')
    }
  },
  methods: {
    resetSortFilter: function(event) {
      resetSortFilterOptions(this, "available_items", event);
    },
    onClick: function(event) {
      this.resetSortFilter(event);
      this.$parent.items = [];
      jQuery("#available_items #no_items").addClass('hidden');
      showHideLoader('#available_items', true);
      jQuery("#available_items_filter_list").each(function() {
        jQuery(this).find('.tick_mark').remove();
      });
      jQuery(event.target).append('<span class="pull-right tick_mark"><img src="/images/single_page/tick.png"></span>');
      var params = 'item_type='+event.target.id+'&per_page='+items_per_page();
      this.available_items_active_filter = event.target.id;
      if(event.target.id != this.$t('available_item_filter.all')) {
        params += '&others=true';
      }
      if(jQuery(event.target).attr('data-categoryid')) {
        var cat_id = jQuery(event.target).attr('data-categoryid');
        params += '&category_id[]='+[cat_id];
      }
      this.$http.get('/contents.json?'+params).then(function(response) {
        this.$parent.items = response.body;
        filter_callback(response.body, "available_items");
        showHideLoader('#available_items', false);
      });
    }
  }
});

Vue.component('available-items-more-link', {
  template: `<div v-bind:id="popup_id" class="custom_popover fade bottom in hide">
              <div class="arrow_img"><img src="/images/single_page/arrow.png"></div>
              <div class="popover-content">
                <ul class="widget_menu_medium filter font_open_sans_regular_14">
                  <li v-if="edit_privilege" class="color_grey" id="edit" data-backdrop="static" data-keyboard="false">{{$t('common.edit')}}</li>
                  <li v-if="item_type === 'Course'&&preview_privilege" v-on:click="onClick" id="preview">{{$t('available_item_more_link.preview')}}</li>
                  <li v-on:click="onClick" :id="popup_id+'comment'">{{$t('available_item_more_link.comment')}}</li>
                  <li v-if="!share_invite_disabled || !share_social_disabled" :id="popup_id+'share'" v-on:click="onClick"> {{$t('available_item_more_link.share')}}</li>
                  <li v-show="show_delete && false" v-on:click="onClick" :id="popup_id+'delete'">{{$t('common.delete_string')}}</li>
                  <li v-show="show_delete" :id="popup_id+'delete_disabled'" class="delete_link">
                    <a v-bind:id="popup_id+'delete_link'" data-toggle="tooltip" data-placement="auto right" :title=message>{{$t('common.delete_string')}}</a>
                  </li>
                  <li v-bind:id="popup_id+'delete_hidden'" class="hide" v-on:click="afterDelete"></li>
                </ul>
              </div>
            </div>`,
  props: {
    popup_id: String,
    share_popup_id: String,
    available_item_more_link_data: Object,
    edit_url: String,
    item_id: Number,
    item_type: String,
    preview_privilege: Boolean,
    edit_privilege: Boolean
  },
  data: function() {
    var show_delete, can_be_deleted, message, delete_url;
    if(this.available_item_more_link_data == null){
      show_delete = false;
    }else{
      show_delete = true;
      can_be_deleted = this.available_item_more_link_data.can_be_deleted;
      if(!can_be_deleted){
        if(this.available_item_more_link_data.message_1){
          message = (this.available_item_more_link_data.message_1 + '\n' + this.available_item_more_link_data.message_2)
        }
      }else{
        message = this.available_item_more_link_data.delete_confirmation_message;
        delete_url = this.available_item_more_link_data.delete_url+".json";
      }
    }
    return {
      message: message,
      thumbnail_id: this.$parent.item_id,
      delete_url: delete_url,
      show_delete: show_delete,
      can_be_deleted: can_be_deleted,
      thumbnail_id: this.$parent.item_id,
      modal_id: "myModal_"+new Date().getTime(),
      share_invite_disabled: this.$store.state.invite_disabled,
      share_social_disabled: this.$store.state.social_disabled
    }
  },
  mounted: function() {
    // jQuery('[data-toggle="tooltip"]').tooltip({html: true});
  },
  methods: {
    afterDelete: function() {
      self = this;
      jQuery.ajax({
        type: 'delete',
        url: this.delete_url,
        success: function(response) {
          if(response.success){
            for(var i = self.$parent.$parent.$parent.list.length - 1; i >= 0; i--) {
              list_id = self.$parent.$parent.$parent.list[i].id;
              list_type = self.$parent.$parent.$parent.list[i].item_type;
              item_id = self.$parent.item_id;
              item_type = self.$parent.item_type;
              if((list_id === item_id) && (list_type === item_type)) {
                self.$parent.$parent.$parent.list.splice(i, 1);
              }
            }
            if(response.flash_message){
              message = response.flash_message;
              flash(message, 'flash_notice');
            }
          }else if(response.flash_message){
            message = response.flash_message;
            type = response.part_of_bundle ? 'flash_notice': 'flash_warning';
            flash(message, type);
          }else if(response){
            flash(response);
          }
        }
      });

    },
    onClick: function(event) {
      switch(event.target.id) {
        case this.popup_id + "comment":
          this.$parent.newCommentPopupOpen = true;
          break;
        case this.popup_id + "share":
          jQuery("#"+this.share_popup_id).removeClass("hide").addClass("show");
          this.$parent.sharePopupOpen = true;
          break;
        case "preview":
          this.$parent.play_course();
          break;
        case "edit":
          if (this.item_type == "InPersonEvent") {
            var self = this;
            this.$http.get(this.edit_url+'.json?').then(response => {
              self.$parent.edit_popup = true;
              self.$parent.edit_item_details = response.body;
              setTimeout(function() {
                jQuery('#inperson_event_edit_'+self.item_id).modal({backdrop: 'static', keyboard: false});
              },20);
            });
          }
          break;
        case this.popup_id + "delete":
          if(this.can_be_deleted) {
            confirmAction(this.message, null, this.popup_id+'delete_hidden', true);
          }
          break;
      }
      jQuery("#"+this.popup_id).removeClass("show").addClass("hide");
    }
  }
});

Vue.component('subheader-filter', {
  template: `<ul class="list-inline">
              <li class="padding_right_20"><section-search v-bind:from_filter_id="from_filter_id" :autocomplete_url="autocomplete_url"/></li>
              <li class="padding_right_15 padding_left_0">
                <a id="section-filter-link" v-on:mouseover="openPopupSection" v-on:mouseleave="closePopupSection"><img src="/images/single_page/section_filter.png">
                  <section-filter v-bind:from_filter_id="from_filter_id" :content_types="content_types" />
                </a>
              </li>
              <li>
                <a id="sort_link" v-on:mouseover="openPopup" v-on:mouseleave="closePopup"><img src="/images/single_page/sortby.png">
                  <div v-bind:id="from_filter_id + '_sort'" class="subheader_sort custom_popover fade bottom in hide">
                      <div class="arrow_img"><img src="/images/single_page/arrow.png"></div>
                      <section-sort v-bind:from_filter_id="from_filter_id" />
                  </div>
                </a>
              </li>
            </ul>`,
  props: {
    from_filter_id: String,
    active_filter: String,
    autocomplete_url: String,
    content_types: Array
  },
  data: function() {
    return {
      active_item_filter: this.active_filter,
      active_sortByOrder: "created_at",
      content_type: [],
      average_rating: [],
      price_min: 0,
      price_max: 5000
    }
  },
  methods: {
    openPopup: function() {
      jQuery("#" + this.from_filter_id + " .subheader_sort").removeClass('hide').addClass('show');
    },
    closePopup: function() {
      jQuery("#" + this.from_filter_id + " .subheader_sort").removeClass('show').addClass('hide');
    },
    openPopupSection: function() {
      jQuery("#" + this.from_filter_id + " .subheader_section_filter").removeClass('hide').addClass('show');
    },
    closePopupSection: function() {
      jQuery("#" + this.from_filter_id + " .subheader_section_filter").removeClass('show').addClass('hide');
    },
    getSortOrder: function(type) {
      var params = ''
      switch(type) {
        case "title_asc":
          params = 'order_by=title&order=asc';
          break;
        case "title_desc":
          params = 'order_by=title&order=desc';
          break;
        case "pricing_H2L":
          params = 'order_by=price&order=desc';
          break;
        case "pricing_L2H":
          params = 'order_by=price&order=asc';
          break;
        case "rating_H2L":
          params = 'order_by=ratings_count&order=desc';
          break;
        case "rating_L2H":
          params = 'order_by=ratings_count&order=asc';
          break;
        case "popular_items":
          params = 'order_by=enrollments_count&order=desc';
          break;
        default:
          params = 'order_by=created_at&order=desc';
          break;
      }
      return params;
    }
  }
});

var searchMixin = {
  props: {
    from_filter_id: String,
    autocomplete_url: String
  },
  data: function(){
    return {
      search_item : '',
      item_type: ''
    }
  },
  mounted: function() {
    var self = this;
    jQuery("#"+ this.from_filter_id +" .search-bar").typeahead({
      ajax: {
        url: self.autocomplete_url+".json",
        triggerLength: 1,
        preDispatch: function (query) {
          return self.getAutoCompleteUrlParam(query);
        }
      },
      onSelect: function(e) {
        var text = e.text;
        self.search_item = text;
        self.onSubmit();
      }
    });
  }
}

Vue.component('section-search', {
  template:  `<form class="search-box" v-on:submit.prevent="onSubmit">
                <span class="icon-magnifier search-icon" v-click-outside="searchOut"><img type="submit" id="search_icon_id" v-on:click="searchIn" src="/images/single_page/search.png" alt="Search" /></span>
                <input type="text" v-bind:id="search_bar_id" class="search-bar tab newTagInput yui-ac-input class_1504400137" autocomplete="off" :placeholder="$t('search.search_str')" v-model="search_item"/>
              </form>`,
  mixins: [searchMixin],
  data: function() {
    return {
      search_icon_id: this.from_filter_id + '_search_icon',
      search_bar_id: this.from_filter_id + '_search_bar'
    }
  },
  methods: {
    getAutoCompleteUrlParam: function(query) {
      var obj = {type: this.item_type, query: query};
      if(this.from_filter_id == "available_items") {
        switch(this.item_type) {
          case this.$t('available_item_filter.featured_items'):
            obj.only_featured = true;
            break;
          case this.$t('available_item_filter.all'):
            obj.without_category = true;
            break;
          default:
            obj.category_id = parseInt(jQuery('#'+this.item_type).attr('data-categoryid'));
            break;
        }
      }
      return obj
    },
    onSubmit: function() {
      if(this.from_filter_id == "enrolled_items") {
        this.onEnrolledItemSearch();
      } else {
        this.onAvailableItemSeacrh();
      }
    },
    onAvailableItemSeacrh: function() {
      var self = this;
      this.checkItemFilter();
      self.$parent.$parent.items = [];
      showHideLoader('#available_items', true);
      var params = 'from_single_page=true&q='+this.search_item+'&per_page='+items_per_page()+'&item_type='+this.item_type;
      if(this.item_type != this.$t('available_item_filter.all')) {
        params += '&others=true';
      }
      if(jQuery('#'+this.item_type).attr('data-categoryid')) {
        params += '&category_id[]='+[jQuery('#'+this.item_type).attr('data-categoryid')];
      }
      jQuery.ajax({
        type: 'GET',
        url: this.autocomplete_url+'.json?'+params,
        success: function(response) {
          self.$parent.$parent.$children[0].resetSortFilter();
          self.$parent.$parent.items = response;
          showHideLoader('#available_items', false);
        }
      });
    },
    onEnrolledItemSearch: function() {
      this.checkItemFilter();
      var self = this;
      self.$parent.$parent[this.item_type+'_items'] = [];
      self.$parent.$parent[this.item_type] = false;
      showHideLoader('#enrolled_items', true);
      jQuery.ajax({
        type: 'GET',
        url: this.autocomplete_url+".json?q="+this.search_item + "&type=" +this.item_type,
        success: function(response) {
          self.$parent.$parent.$children[0].resetSortFilter();
          self.$parent.$parent[self.item_type+'_items'] = response;
          self.$parent.$parent[self.item_type] = true;
          showHideLoader('#enrolled_items', false);
        }
      });
    },
    checkItemFilter: function() {
      var length = this.$parent.$parent.$children.length;
      var flag = 1;
      for(var i = 0; i< length && flag; i++){
        if (this.$parent.$parent.$children[i][this.from_filter_id+'_active_filter']) {
          this.item_type = this.$parent.$parent.$children[i][this.from_filter_id+'_active_filter'];
          flag = 0;
        }
      }
    },
    searchIn: function(event) {
      jQuery("#"+ this.from_filter_id +" .search-bar").addClass("search-bar-open");
      jQuery("#"+ this.from_filter_id +" .search-icon").addClass("search-icon-open");
      jQuery("#"+ this.from_filter_id +" .search-bar")[0].focus();
      this.checkItemFilter();
    },
    searchOut: function(e) {
      if(e.target.id != 'search_icon' && e.target.id != (this.from_filter_id + '_search_bar')) {
        jQuery("#"+ this.from_filter_id +" .search-bar").removeClass("search-bar-open");
        jQuery("#"+ this.from_filter_id +" .search-icon").removeClass("search-icon-open");
        //this.search_item = '';
      }
    },
  }
});

Vue.component('section-sort', {
  template:`<ul v-bind:id="from_filter_id + '_sort_dropdown'">
              <li class="sort_by">{{$t('sort.sort_by')}}</li>
              <hr>
              <ul id ="sort_items_filter_list" class="secondary_list">
                <li><img class="padding_right_8 margin_top_min_4" src="/images/single_page/alphabetical_order.png">{{$t('sort.title_caps')}}</li>
                <ul>
                  <li v-on:click="onSubmit" id="title_asc">{{$t('sort.title')}}&nbsp;{{$t('sort.a_z')}}</li>
                  <li v-on:click="onSubmit" id="title_desc">{{$t('sort.title')}}&nbsp;{{$t('sort.z_a')}}</li>
                </ul>
                <li><img class="padding_right_13" src="/images/single_page/pricing.png">{{$t('sort.pricing_caps')}}</li>
                <ul>
                  <li v-on:click="onSubmit" id="pricing_H2L">{{$t('sort.pricing')}}&nbsp;{{$t('sort.h_to_l')}}</li>
                  <li v-on:click="onSubmit" id="pricing_L2H">{{$t('sort.pricing')}}&nbsp;{{$t('sort.l_to_h')}}</li>
                </ul>
                <li><img class="padding_right_10 margin_top_min_4" src="/images/single_page/rating.png">{{$t('sort.rating_caps')}}</li>
                <ul>
                  <li v-on:click="onSubmit" id="rating_H2L">{{$t('sort.rating')}}&nbsp;{{$t('sort.h_to_l')}}</li>
                  <li v-on:click="onSubmit" id="rating_L2H">{{$t('sort.rating')}}&nbsp;{{$t('sort.l_to_h')}}</li>
                </ul>
                <li><img class="padding_right_13" src="/images/single_page/relevance.png">{{$t('sort.relevance')}}</li>
                <ul>
                  <li class="activated" v-on:click="onSubmit" id="recent_items">{{$t('sort.most_recent')}}</li>
                  <li v-on:click="onSubmit" id="popular_items">{{$t('sort.most_popular')}}</li>
                </ul>
              </ul>
            </ul>` ,
  props: {
    from_filter_id: String
  },
  data: function(){
    return{
      in_progress_url: "/my_learnings/upcoming",
      recently_completed_url: "/my_learnings/completed_items",
      all_completed_url: "/my_learnings/completed_items",
      cancelled_url: "/my_learnings/upcoming/canceled",
      available_soon_url: "/my_learnings/available_soon_items"
    }
  },
  methods:{
    onSubmit: function(event) {
      var filter = this.checkItemFilter();
      if(this.$parent.active_item_filter != filter){
        this.$parent.active_item_filter = filter;
        this.$parent.active_sortByOrder = "created_at";
        this.$parent.content_type = [];
        this.$parent.average_rating = [];
        this.$parent.price_max = 5000;
        this.$parent.price_min = 0;
      }
      if(this.from_filter_id == "enrolled_items") {
        this.onEnrolledItemSort(event, filter);
      } else {
        this.onAvailableItemSort(event);
      }
    },
    onAvailableItemSort: function(event) {
      var params = 'from_single_page=true&per_page='+items_per_page() + '&'+this.$parent.getSortOrder(event.target.id);
      var previous_items = this.$parent.$parent.items;
      this.hightlightSelected(event, 'items');
      this.showFilteredItems('/contents', params, previous_items, 'items', '#available_items');
    },
    onEnrolledItemSort: function(event, filter){
      if (this.$parent.active_sortByOrder != event.target.id){
        this.hightlightSelected(event, filter+'_items');
        var params = this.$parent.getSortOrder(event.target.id);
        if (filter == "all_completed" || filter == "available_soon") {
          params = params + "&all=true";
        }
        var previous_items = this.$parent.$parent[filter+'_items'];
        this.showFilteredItems(this[filter+'_url'], params, previous_items, filter+'_items', "#enrolled_items");
      }
    },
    checkItemFilter: function(){
      return this.$parent.$parent.$children[0][this.from_filter_id+'_active_filter'];
    },
    hightlightSelected: function(event, item) {
      this.$parent.active_sortByOrder = event.target.id;
      jQuery("#"+this.from_filter_id+'_sort_dropdown li').each(function(index){
        jQuery(this).removeClass('activated');
      });
      jQuery("#"+this.from_filter_id+'_sort_dropdown').find(event.target).addClass('activated');

      this.$parent.$parent[item] = [];
    },
    showFilteredItems: function(request_url, order_param, previous_items, item, loader_div){
      var self = this
      if(order_param.length > 1){
        order_param = '&' + order_param;
      }
      var filters_delivery = this.$parent.content_type.length == 0 ? '': JSON.stringify(this.$parent.content_type);
      var filters_rating = this.$parent.average_rating.length == 0 ? '': JSON.stringify(this.$parent.average_rating);
      var params = 'content_type='+filters_delivery + '&average_rating='+filters_rating + '&price_min='+this.$parent.price_min + '&price_max='+this.$parent.price_max + order_param;
      params += '&q='+this.$parent.$children[0].search_item;
      if(this.checkItemFilter() == "recently_completed") {
        params += '&per_page='+items_per_page();
      }

      this.requestDataItems(request_url, params, previous_items, item, loader_div);
    },
    requestDataItems: function(request_url, order_param, previous_items, item, loader_div){
      var self = this;
      self.$parent.$parent[item] = [];
      showHideLoader(loader_div, true);
      jQuery.ajax({
        type: 'GET',
        url: request_url +'.json?'+ order_param,
        success: function(response) {
          showHideLoader(loader_div, false);
          return self.$parent.$parent[item] = response;
        },
        error: function() {
          showHideLoader(loader_div, false);
          return self.$parent.$parent[item] = previous_items;
        }
      });
    }
  }
});

Vue.component('section-filter', {
  template: `<div class="subheader_section_filter custom_popover fade bottom in hide" v-bind:id="from_filter_id + '_filter'">
            <div class="arrow_img">
              <img src="/images/single_page/arrow.png"/>
            </div>
            <div class="filter_container half_margin">
              <div class="filter_header">
                <div class="padding_left_0 col-sm-6 font_open_sans_semibold_12_black">{{$t('filter.text')}}</div>
                <div class="reset_filter padding_right_0 font_open_sans_regular_12_blue col-sm-6" v-on:click="resetFilter">{{$t('filter.reset')}}</div>
              </div>
              <hr/>
              <div>
                <pricing-filter v-bind:filter_unique_id="from_filter_id"></pricing-filter>
              </div>
              <div>
                <delivery-type-filter v-bind:content_types="content_types" :filter_unique_id="from_filter_id"></delivery-type-filter>
              </div>
              <div>
                <rating-filter v-bind:filter_unique_id="from_filter_id"></rating-filter>
              </div>
            </div>
          </div>`,
  props: {
    from_filter_id: String,
    content_types: Array
  },
  data: function(){
    return{
      in_progress_url: "/my_learnings/upcoming",
      recently_completed_url: "/my_learnings/completed_items",
      all_completed_url: "/my_learnings/completed_items",
      cancelled_url: "/my_learnings/upcoming/canceled",
      available_soon_url: "/my_learnings/available_soon_items",
      available_items_url: "/contents",
      item_type: ''
    }
  },
  methods:{
    onClick: function(event){
      this.checkItemFilter();
      var self = this;
      var filter_url, previous_items;
      var populate;

      if(this.from_filter_id == "available_items"){
        filter_url = this.available_items_url;
        previous_items = this.$parent.$parent.items;
        this.$parent.$parent.items = [];
        populate = 'items';
      } else {
        filter_url = this[this.item_type+'_url'];
        previous_items = this.$parent.$parent[this.item_type+'_items'];
        this.$parent.$parent[this.item_type+'_items'] = [];
        populate = self.item_type+'_items';
      }

      if(this.$parent.active_item_filter != this.item_type){
        this.$parent.active_item_filter = this.item_type;
        this.$parent.active_sortByOrder = "created_at";
      }

      this.showFilteredItems(event, filter_url, '', previous_items, function(response){
        self.$parent.$parent[populate] = response;
      });
    },
    checkItemFilter: function(){
      this.item_type = this.$parent.$parent.$children[0][this.from_filter_id+'_active_filter'];
    },
    showFilteredItems: function(event, items_url, order_param, previous_items, getData){
      var self = this, type;
      if(order_param.length > 1){
        order_param = '&' + order_param
      }
      if(event.newValue) {
        this.$parent.price_min = event.newValue[0];
        this.$parent.price_max = event.newValue[1];
      } else {
        this.$parent.content_type = this.$children[1].selectedDeliveryType;
        this.$parent.average_rating = this.$children[2].selectedRating;
      }
      var filters_delivery = this.$parent.content_type.length == 0 ? '': JSON.stringify(this.$parent.content_type);
      var filters_rating = this.$parent.average_rating.length == 0 ? '': JSON.stringify(this.$parent.average_rating);
      var params = '?content_type=' + filters_delivery + '&average_rating=' + filters_rating + '&price_min=' + this.$parent.price_min + '&price_max=' + this.$parent.price_max + order_param;
      params += '&'+this.$parent.getSortOrder(this.$parent.active_sortByOrder) + '&q='+this.$parent.$children[0].search_item;
      if(this.from_filter_id == "available_items") {
        params += '&from_single_page=true&per_page='+items_per_page();
      }

      this.requestDataItems(items_url, params, previous_items, function(response){
        showHideLoader('#'+self.from_filter_id, false);
        return getData(response);
      });
    },
    requestDataItems: function(items_url, order_param, previous_items, getDataItems){
      showHideLoader('#'+this.from_filter_id, true);
      jQuery.ajax({
        type: 'GET',
        url: items_url +'.json'+ order_param,
        success: function(response) {
          return getDataItems(response);
        },
        error: function() {
          return getDataItems(previous_items);
        }
      });
    },
    resetFilter: function(e)
    {
      this.$children[0].rangeValue = [0,5000];
      this.$children[1].selectedDeliveryType = [];
      this.$children[2].selectedRating = [];
      this.$parent.content_type = [];
      this.$parent.price_min = 0;
      this.$parent.price_max = 5000;
      this.$parent.average_rating = [];
      this.onClick(e);
    }
  }
});

Vue.component('content-progress', {
  template: `<div class="content_progress_container">
               <div class="row padding_top_10">
                 <div class="col-lg-3">
                   <img v-bind:src="public_filename" class="content_thumbnail" />
                 </div>
                 <div class="col-lg-9">
                   <ul>
                     <li class="widget_body_sec_medium"> {{title}} </li>
                     <li class="widget_body_sec_medium"> {{progress}}% <span>{{$t('content_progress.completed')}}</span> </li>
                     <li class="widget_body_sec_medium"> {{attempts}} </li>
                   </ul>
                 </div>
               </div>
               <hr/>
             </div>`,
  props: {
    item: Object
  },
  data: function() {
    return {
      public_filename: this.item.public_filename,
      title: this.item.title,
      progress: Math.floor(this.item.progress_percent),
      attempts: this.item.attempts
    }
  }
});

Vue.component('add-to-cart-button', {
  template: `<div v-bind:class="[from_details ? 'item_details_add_to_cart' : 'add_to_cart']">
              <div v-bind:class="[{hide: !added_to_library}, 'added_to_my_learning']">
                <div v-bind:id="'tick_circle_'+item_id" class="cartCircle">
                  <b v-if="from_item_tile_detail" class="add_icon" style="margin-left:0px">ADDED TO LEARNING</b>
                  <img v-else src="/images/single_page/check_white.svg" class="tick_icon">
                </div>
              </div>
              <div v-if="!added_to_library" class="add_to_my_learning">
                <div v-bind:id="'animation_circle_'+id+item_id" class="animation_circle hide"></div>
                <div v-if="can_add_to_cart" v-bind:id="'cart_button_'+item_id" class="animate_button">
                  <div v-if="paid_subscription">
                    <div v-if="subscribe_only&&!valid_subscription" class="margin_top_50_percent">
                      <span v-bind:id="'price_bar_'+id+item_id" v-on:click.once="add_to_library" class="product_price lxa_font_size_04 padding_top_8 subscribe_only_text">{{$t('add_to_cart.subscribers_only')}}</span>
                      <span v-bind:id="'add_icon_'+id+item_id" v-on:click.once="add_to_library" class="cartCircle margin_top_min_50">
                        <b v-if="!added_to_cart" class="add_icon cursor_pointer" v-bind:id="'add_img_'+id+item_id" v-on:click.once="add_to_library">+</b>
                        <img v-else src="/images/single_page/shopping-cart-added.svg" class="cart_icon" />
                      </span>
                    </div>
                    <div v-else v-bind:class="{ cursor_pointer: !added_to_cart}">
                      <span v-bind:id="'add_icon_'+id+item_id" v-on:click.once="add_to_library()" class="cartCircle">
                        <b v-if="from_item_tile_detail" class="add_icon">ADD TO LEARNING</b>
                        <b v-else class="add_icon">+</b>
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="!free" v-bind:class="[{cursor_pointer: !added_to_cart, cart_button: !from_details}]">
                      <span v-bind:id="'price_bar_'+id+item_id" v-on:click.once="add_to_library" class="product_price">{{price}}</span>
                      <span v-if="from_item_tile_detail" v-bind:id="'add_icon_'+id+item_id" v-on:click.once="add_to_library()" class="cartCircle">
                        <b v-if="free || !added_to_cart" class="add_icon">ADD TO LEARNING</b>
                        <img v-else src="/images/single_page/shopping-cart-added.svg" class="cart_icon" />
                      </span>
                      <span v-else v-bind:id="'add_icon_'+id+item_id" v-on:click.once="add_to_library" class="cartCircle">
                        <b v-if="free || !added_to_cart" class="add_icon">+</b>
                        <img v-else src="/images/single_page/shopping-cart-added.svg" class="cart_icon" />
                      </span>
                    </div>
                    <div v-else v-bind:class="{ cursor_pointer: !added_to_cart}">
                      <span v-bind:id="'add_icon_'+id+item_id" v-on:click.once="add_to_library()" class="cartCircle">
                        <b v-if="from_item_tile_detail" class="add_icon">ADD TO LEARNING</b>
                        <b v-else class="add_icon">+</b>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="cartCircle full_message">{{$t('add_to_cart.full')}}</div>
                </div>
              </div>
            </div>`,
  props: {
    item_id: String,
    product: Object,
    logged_in: Boolean,
    from_details: Boolean,
    product_id: Number,
    from_item_tile_detail: Boolean
  },
  data: function() {
    var added_to_library = this.product.added_to_library;
    return {
      free: this.product['free?'],
      price: (added_to_library ? 0 : this.number_to_currency(this.product.price)),
      added_to_library: added_to_library,
      added_to_cart: (added_to_library ? false : this.product.added_to_cart),
      paid_subscription: (added_to_library ? false : this.product.paid_subscription),
      valid_subscription: (added_to_library ? false : this.product.valid_subscription),
      subscribe_only: (added_to_library ? false : this.product.subscribe_only),
      can_add_to_cart: (added_to_library ? false : this.product.can_add_to_cart),
      add_to_library_path: (added_to_library ? '' : this.product.add_to_library_path),
      add_to_cart_path: (added_to_library ? '' : this.product.add_to_cart_path),
      id: ''
    }
  },
  mounted: function() {
    this.id = this._uid;
  },
  methods: {
    add_to_library: function() {
      var self = this;
      if (this.logged_in) {
        if (!this.added_to_cart) {
          if (this.free) {
            if (jQuery(".animation_started")[0]) {
              self.add_to_queue();
            }
            else {
              self.add_to_my_learning();
            }
          }
          else {
            this.$http.post(this.add_to_cart_path).then(response => {
              jQuery("#animation_circle_"+self.id+self.item_id).css('margin-left', jQuery("#cart_button_"+self.item_id).width()/2 - 3);
              jQuery("#"+self.item_id+' .animate_button').css('transform', 'scale(0)');
              jQuery("#animation_circle_"+self.id+self.item_id).removeClass("hide");
              jQuery('html, body').delay(2000).animate({ scrollTop: 0 },2000);
              jQuery(".close_cart_circle").trigger('click');
              jQuery("#animation_circle_"+self.id+self.item_id).delay(2000).animate({
                marginLeft: '5px',
                left: (jQuery("#cart_link").offset().left - (jQuery("#"+self.item_id+" .add_to_cart").offset().left)),
                top: (jQuery("#cart_link").offset().top - jQuery("#animation_circle_"+self.id+self.item_id).offset().top),
              }, {
                duration: 2000,
                complete: function (){
                  jQuery("#animation_circle_"+self.id+self.item_id).addClass("bounce");
                  jQuery(".bounce").bind('animationend webkitAnimationEnd', function() {
                    jQuery("#animation_circle_"+self.id+self.item_id).hide();
                    if(parseInt(jQuery("#add_to_cart_badge").html()) > 0) {
                      jQuery("#badge_cart").addClass("hide");
                      jQuery("#add_to_cart_badge").removeClass("hide");
                      jQuery("#add_to_cart_badge").html(parseInt(jQuery("#add_to_cart_badge").html()) + 1);
                    } else {
                      jQuery("#badge_cart").addClass("hide");
                      jQuery("#add_to_cart_badge").removeClass("hide");
                      jQuery("#add_to_cart_badge").html(1);
                    }
                  });
                  jQuery("#"+self.item_id+' .animate_button').css('transform', 'scale(1)');
                  self.added_to_cart = true;
                }
              });
            });
          }
        }
      } else {
        window.scrollTo(0, 0);
        $('#login_modal').modal('show');
      }
    },
    add_to_queue: function() {
      var self = this;
      if (jQuery(".animation_started")[0]) {
        setTimeout(function() {
          self.add_to_queue();
        },20);
      }
      else {
        var scrollTop = jQuery('#'+self.item_id).offset().top;
        jQuery('html, body').animate({ scrollTop: scrollTop-100 },1500);
        self.add_to_my_learning();
      }
    },
    add_to_my_learning: function() {
      var self = this;
      jQuery("#"+self.item_id).addClass("animation_started");
      this.$http.post(this.add_to_library_path+'.json').then(response => {
        if (response.body) {
          self.added_to_library = true;
          self.$root.in_progress_items.splice(0, 0, response.body);
          setTimeout(function(){
            truncateItemText("enrolled_items");
          },500);
          show_no_items_txt(response.body, "enrolled_items");
          self.add_to_library_animation();
        }
        else {
          self.added_to_library = true;
        }
      });
    },
    add_to_library_animation: function() {
      var self = this;
      jQuery("#"+self.item_id+" .tick_icon").addClass("animated fadeIn");
      var bar1 = circle_animation("#tick_circle_"+self.item_id,'#f2958e','#ffffff',4);
      bar1.animate(1.0);
      var bar2 = circle_animation("#add_icon_"+self.id+self.item_id,'#f2958e','#ffffff',4);
      var scrollTop = jQuery("#"+self.item_id).first().offset().top;
      jQuery('html, body').animate({ scrollTop: scrollTop });
      bar2.animate(1.0);
      self.added_to_library = true;
      setTimeout(function() {
        var top = jQuery(".item").first().offset().top;
        jQuery('html, body').animate({ scrollTop: top });
        jQuery('html, body').one("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function(){
          jQuery("#"+self.item_id).removeClass("animation_started");
        });
        jQuery(".item").first().removeClass('visibility_hidden');
        bar1.destroy();
      },500);
    },
    number_to_currency: function(int) {
      return (int).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }
  }
});

Vue.component('item-tile-bookmark', {
  template: `<div v-if="catalog" class="bookmark">
              <item-bookmark :bookmarked="bookmarked" :bookmarks_url="bookmarks_url" :logged_in="logged_in" :bookmark_cancel_url="bookmark_cancel_url" :can_be_bookmarked="true" :list_view="false"/>
              <span v-show="bookmarked">Wishlisted</span>
              <span v-show="!bookmarked">Wishlist</span>
            </div>
            <div v-else class="bookmark pl-3">
              <enrolled-bookmark v-bind:bookmarked="bookmarked" :bookmarks_url="bookmarks_url" :logged_in="logged_in" :bookmark_cancel_url="bookmark_cancel_url" :details="true" :list="false"></enrolled-bookmark>
            </div>`,
  props: {
    bookmarked: Boolean,
    bookmarks_url: String,
    hide_more_icon: Boolean,
    logged_in: Boolean,
    bookmark_cancel_url: String,
    catalog: Boolean
  },
  methods: {
    onBookmarkClick: function(event) {
      if(this.logged_in) {
        this.$http.post(this.bookmarks_url).then(response => {
          jQuery(event.target).attr('title', 'Bookmarked');
          jQuery(event.target).removeClass('cursor_pointer');
          jQuery(event.target).addClass('disabled');
          this.$parent.bookmarked = true;
        });
      } else {
        show_login_popup(this.$root);
      }
    }
  }
});

Vue.component('item-tile-details-header', {
  template: `<div class="col-12 mb-3"><div class="item_detail_header font_barlow_semi_condensed_light_24 d-flex">
               <div class="header_home" v-on:click="back_home()">Home</div>
               <div class="chevron_right ml-2"><i class="fa fa-chevron-right lxa_icon_size lxa_text_color_06"></i></div>
               <div class="header_detail ml-2">Course Details</div>
             </div></div>`,
  methods: {
    back_home: function() {
      this.$root.show_course_details = false;
    }
  }
});

Vue.component('item-tile-details-lightbox', {
  template: `<div class="item_details w-100 col-12" id="item_details_lightbox1" v-if="!show_cart">
    <div class="item_details_data col-12 p-0" v-if="content_loaded">
      <div class="container-fluid p-0">
        <div class="row m-0">
          <div v-if="launch_item" class="w-100">
            <div class="row m-0 mt-3" v-if="logged_in && ['InPersonEvent', 'LiveEvent'].includes(product.item_type)">
              <iframe :src="product.bbb_url" allow="geolocation *; microphone *; camera *; display-capture *;" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" sandbox="allow-same-origin allow-scripts allow-modals allow-forms" style="width:100%;height:100%;border:0" scrolling="no"/>
            </div>
            <content-player ref="ContentPlayer" v-if="show_course_details && product.enrollment.id && !['InPersonEvent', 'LiveEvent'].includes(product.item_type)" :active_content="active_content" :product="product" :logged_in="logged_in" :modal="false"> </content-player>
          </div>
          <div v-else class="col-12 p-0">
            <img v-bind:src="thumbnail_url" class="item_img lxa_border_radius"/>
            <div class="title_detail">
              <div class="lxa_detail_title_light text-white line-height30">{{product.title}}</div>
              <div class="lxa_section_title_light text-white pt-4"> Published on {{product.created_at}} | {{product.creator_firstname}}&nbsp{{product.creator_lastname}}</div>
            </div>
            <div v-if="catalog && (!product['free?'] || paid_subscription)" class="price_block">
              <div v-if="paid_subscription">
                <div v-if="subscribe_only&&!valid_subscription" class="margin_top_50_percent">
                  <span v-bind:id="'price_bar_'+item_id"  class="product_price">{{$t('add_to_cart.subscribers_only')}}</span>
                </div>
              </div>
              <div v-else>
                <div v-if="!product['free?']">
                  <i class="text-white fad fa-shopping-cart"></i>
                  <span v-if="!added_to_cart" v-bind:id="'price_bar_'+item_id" class="text-white pl-1 font-weight-bold">US&nbsp$ {{product.price}}</span>
                  <div class="detail_enroll_button">
                    <button v-if="!added_to_cart" type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" v-on:click="add_to_library">Add to cart</button>
                    <button v-else type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">Added to cart</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="catalog">
              <div class="detail_enroll_button">
                <button type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" v-on:click="add_to_library">Enroll</button>
              </div>
            </div>
            <div v-else>
              <div class="detail_enroll_button">
                <button type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" v-on:click="launch">Launch</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-3">
          <div class="col-7 pl-0">
            <div class="row mx-0 px-3 pb-3 lxa_bg_05 lxa_border_radius _lxah-75">
              <div class="col-12 p-0 h-100">
              <div class="d-flex flex-column h-100">
              <div class="d-flex align-items-center justify-content-start">
                <div class="py-3 lxa_main_title_light lxa_text_color_01 mr-auto">
                 {{$t('item.course_details')}}
                </div>
                <div class="d-flex justify-content-end">
                  <item-rating v-if="rates_count > 0 || (product.enrollment&&(product.enrollment.progress_percent==100))" v-bind:ratings_id="ratings_id" :product="product" :ratings_url="ratings_url" :rates_count="rates_count" :average_rating="average_rating" :rated_by_user="rated_by_user" :logged_in="logged_in" :from_item_tile_detail="true"/>
                  <div v-if="logged_in" class="share_container d-flex align-items-center justify-content-start font_barlow_semi_condensed_light_16">
                    <span id="item_details_share" data-toggle="modal" data-target="#share_detail" class="share cursor_pointer" v-on:click="openSharePopup"><i class="fad fa-share-alt lxa_icon_size cursor_pointer lxa_text_color_04"></i> Share </span>
                    <tile-details-share-popup v-if="shareModalOpen" v-click-outside="outside" v-bind:share_popup_id="share_popup_id" :item_share_link="product.item_share_link" :title="product.title" :shareable_id="product.id" :logged_in="logged_in" :share_type_open="invite" :from_content_player="false"/>
                  </div>
                  <div v-if="show_bookmark" class="bookmark_container d-flex align-items-center justify-content-start font_barlow_semi_condensed_light_16">
                    <!-- <item-tile-bookmark v-bind:bookmarked="bookmarked" :bookmarks_url="bookmarks_url" :catalog="catalog" :hide_more_icon="false" :bookmark_cancel_url="bookmark_cancel_url" :logged_in="logged_in"/> -->
                  </div>
                </div>
                </div>
                <div v-show="truncate">
                  <div class="item_detail_description pb-3" v-html="product.item_description"></div>
                </div>
                <div v-show="!truncate">
                  <div class="pb-3">
                    <span class="lxa_section_light" v-html="product.item_description"></span>
                    <span v-on:click="ToggleContent"><i class="fad fa-chevron-up read_more_arrow"></i></span>
                  </div>
                </div>
                <div id="details_desc_truncate" v-on:click="ToggleContent" class="hide"></div>
              <div class="d-flex align-items-center justify-content-start mt-auto">
                <div class="pr-2">
                  <i v-if="product.item_type == 'Bundle'" data-toggle="tooltip" data-placement="top" title="Bundle" class="fad fa-file-archive font_barlow_semi_condensed_light_16 text-warning pr-1"></i>
                  <i v-else-if="(product.item_type == 'LiveEvent') || (product.item_type == 'InPersonEvent')" data-toggle="tooltip" data-placement="top" title="Event" class="fad fa-calendar-star font_barlow_semi_condensed_light_16 pr-1 text-success"></i>
                  <i v-else class="fad fa-laptop font_barlow_semi_condensed_light_16 text-primary pr-1" data-toggle="tooltip" data-placement="top" title="E-learning"></i>
                </div>
                <div v-if="product.categories.length > 0" class="pr-2">
                  <i data-toggle="tooltip" data-placement="top" title="Categories" class="fad fa-box font_barlow_semi_condensed_light_16 lxa_text_color_06"></i>
                  <span class="font_barlow_semi_condensed_light_16">&nbsp{{product.categories}}</span>
                </div>
                <div class="pr-2">
                  <i data-toggle="tooltip" data-placement="top" title="Duration" class="fad fa-clock font_barlow_semi_condensed_light_16 lxa_text_color_04"></i>
                  <span class="font_barlow_semi_condensed_light_16"><span v-if="product.duration >= 60" class="pl-1">{{$tc('item.duration_hours', parseInt(product.duration/60), {count: parseInt(product.duration/60)})}}</span><span v-if="product.duration%60 != 0" class="pl-1">{{$tc('item.duration_mins', product.duration%60, {count: product.duration%60})}}</span></span>
                </div>
                <div v-if="enrollments_count > 0">
                  <i class="fad fa-money-check-edit lxa_text_color_05 font_barlow_semi_condensed_light_16"></i>
                  <span class="font_barlow_semi_condensed_light_16">{{$tc('item.enrollments', enrollments_count, {count: enrollments_count})}}</span>
                </div>
              </div>
</div>
              </div>
            </div>
            <div class="row mt-3 m-0 lxa_bg_05 lxa_border_radius p-3 _lxah-25">
              <div class="col-12 mb-3 p-0 lxa_main_title_light lxa_text_color_06">{{$t('item.instructor')}}</div>
              <div class="col-12 d-flex pl-0 pb-3">
                <img v-bind:src="product.creator_img_url" class="thumbnail_img"/>
                <div class="lxa_section_light ml-3">
                  <div class="lxa_text_color_06 lxa_section_title_light">{{product.creator_firstname}}&nbsp{{product.creator_lastname}}</div>
                  <div v-show="truncate_summary" class="creator_summary mt-3">{{product.creator_summary}}</div>
                  <div v-show="!truncate_summary">
                    <div class="mt-3">
                      <span class="lxa_section_light pb-3">{{product.creator_summary}}</span>
                      <span v-on:click="ToggleSummary"><i class="fad fa-chevron-up read_more_arrow"></i></span>
                    </div>
                  </div>
                  <div id="summary_desc_truncate" v-on:click="ToggleSummary" class="hide"></div>
                  <div class="pt-2">Joined On {{product.creator_joined_date}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5 lxa_bg_05 lxa_border_radius" :class="{'px-0':!catalog}">
            <div v-if="catalog" class="row m-0 pt-3">
              <div class="col-12 p-0 mb-3 lxa_main_title_light lxa_text_color_04 lxa_font_size_04">
                {{$t('item.what_is_inside')}}
              </div>
              <div class="item_detail_contents col-12 px-0">
              <div class="row mx-0">
              <div v-if="(product.item_type=='InPersonEvent'|| product.item_type=='LiveEvent')" class="col-12 p-0">
                <div class="lxa_title_medium_bold">Start Date: {{items.start_date}}</div>
                <hr class="w-100 line-separator">
                <div class="lxa_title_medium_bold">Start Time: {{items.start_time}}</div>
                <hr class="w-100 line-separator">
                <div class="lxa_title_medium_bold">Language: {{items.language}}</div>
                <hr class="w-100 line-separator">
                <div v-if="items.location != ''" class="lxa_title_medium_bold">Location: {{items.location}}</div>
                <hr v-if="items.location != ''" class="w-100 line-separator">
              </div>
              <div v-else v-for="(item,index) in items.contents" class="col-12 p-0">
                <div class="lxa_title_medium_bold">{{item.content_title}}</div>
                <div class="font_barlow_semi_condensed_light_14 mt-2"><i data-toggle="tooltip" data-placement="top" title="Duration" class="fad fa-clock font_barlow_semi_condensed_light_16 lxa_text_color_04"></i><span v-if="item.duration >= 60" class="pl-1 pr-1">{{$tc('item.duration_hours', parseInt(item.duration/60), {count: parseInt(item.duration/60)})}}</span><span v-if="item.duration%60 != 0">{{$tc('item.duration_mins', item.duration%60, {count: item.duration%60})}}</span></div>
                <hr class="w-100 line-separator">
              </div>
              </div>
              </div>
            </div>
            <div v-else class="col-12 lxa_bg_05 lxa_border_radius pt-3 pb-2 p-0">
              <div class="course_commment_notes lxa_menu_container mb-3 px-3">
                <span v-bind:class="getCourseCommentNotes('comment')" @click="toggleCourseComment('comment')"> Comments </span>
                <span v-bind:class="getCourseCommentNotes('note')" @click="toggleCourseComment('note')"> Notes </span>
              </div>
              <div class="comments_data">
                <comments v-if="comments_loaded && course_comment" v-bind:comments="comments" :product="product" :ratings_url="ratings_url" :logged_in="logged_in" :current_user_details="current_user_details" :rated_by_user="rated_by_user" :user_rating="user_rating" :comments_count="comments_count" :from_item_tile_detail="true"/>
                <content-notes v-if="notes_loaded && !course_comment" v-bind:courses_notes="courses_notes" :product="product" :active_content="active_content"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="catalog" class="row ml-0 mt-3 mr-0">
          <div v-if="comments.length > 0 || logged_in" class="col-5 comments_detail ml-0 pl-0">
            <div class="col-12 lxa_bg_05 lxa_border_radius py-3 p-0">
              <comments v-if="comments_loaded" v-bind:comments="comments" :product="product" :ratings_url="ratings_url" :logged_in="logged_in" :current_user_details="current_user_details" :rated_by_user="rated_by_user" :user_rating="user_rating" :comments_count="comments_count" :from_item_tile_detail="true"/>
            </div>
          </div>
          <div v-bind:class="[(comments.length > 0 || logged_in) ? 'col-7 pt-3 pr-0 lxa_bg_05 lxa_border_radius lxa_text_color_01' : 'col-12 pt-3 pr-0 lxa_bg_05 lxa_border_radius lxa_text_color_01']" id="similar_course_detail" class="overflow-hidden">
            <div class="similar_course_header lxa_main_title_light mb-3">Similar Courses You May Like</div>
            <div class="d-flex row mx-0">
              <!-- <item-tile :item="item" :index="index" :key="item.item_type+'_'+item.id" v-for="(item,index) in similar_products_item" :class="[{'pr-0': index===similar_products_item.length-1, 'lxa-firstrecord-hide-catdetail':(prev_icon == true && index === 0)}]" :logged_in="logged_in" :from_catalog="true" :from_similar_course="true"/> -->
              <item-tile :item="item" :index="index" :key="item.item_type+'_'+item.id" v-for="(item,index) in similar_products_item" :logged_in="logged_in" :from_catalog="true" :from_similar_course="true"/>
            </div>
            <a class="carousel_arrow carousel-control-prev" v-if="prev_icon" v-on:click="prev_page_similar_course()" role="button" data-slide="prev">
              <div class="prev_icon_box">
                <span class="carousel-control-prev-icon" aria-hidden="true">
                <i class="fad fa-chevron-left"></i>
                </span>
                <span class="sr-only">Previous</span>
              </div>
            </a>
            <a class="carousel_arrow carousel-control-next" v-if="next_icon" v-on:click="next_page_similar_course()" role="button" data-slide="next">
              <div class="next_icon_box">
                <span class="carousel-control-next-icon" aria-hidden="true">
                  <i class="fad fa-chevron-right"></i>
                </span>
                <span class="sr-only">Next</span>
              </div>
            </a>
          </div>
        </div>
        <div v-else class="row m-0 mt-3">
          <div class="col-12 pt-2 pr-0 lxa_bg_05 lxa_border_radius overflow-hidden" id="similar_course_detail">
            <div class="similar_course_header lxa_main_title_light pb-2">Similar Courses You May Like</div>
            <div class="d-flex row mx-0">
              <!-- <item-tile :item="item" :index="index" :key="item.item_type+'_'+item.id" v-for="(item,index) in similar_products_item" :class="[{'pr-0': index===similar_products_item.length-1, 'lxa-firstrecord-hide-catdetail':(prev_icon == true && index === 0)}]" :logged_in="logged_in" :from_catalog="true" :from_similar_course="true"/> -->
              <item-tile :item="item" :index="index" :key="item.item_type+'_'+item.id" v-for="(item,index) in similar_products_item" :logged_in="logged_in" :from_catalog="true" :from_similar_course="true"/>
            </div>
            <a class="carousel_arrow carousel-control-prev" v-if="prev_icon" v-on:click="prev_page_similar_course()" role="button" data-slide="prev">
              <div class="prev_icon_box">
                <span class="carousel-control-prev-icon" aria-hidden="true">
                  <i class="fad fa-chevron-left"></i>
                </span>
                <span class="sr-only">Previous</span>
              </div>
            </a>
            <a class="carousel_arrow carousel-control-next" v-if="next_icon" v-on:click="next_page_similar_course()" role="button" data-slide="next">
              <div class="next_icon_box">
                <span class="carousel-control-next-icon" aria-hidden="true">
                <i class="fad fa-chevron-right"></i>
                </span>
                <span class="sr-only">Next</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="clear">
    </div>
  </div>`,
  props: {
    logged_in: Boolean,
    show_course_details: Boolean,
    show_cart: Boolean,
    launch_item: Boolean
  },
  data() {
    return {
      course_comment: true,
      active_content: null,
      truncate: true,
      truncate_summary: true
    }
  },
  mixins: [itemCourseDetailMixins],
  mounted: function() {
    var self = this;
    this.$nextTick(function () {
      self.$root.$on('open-details-modal', this.open);
      // self.$root.$on('toggle-details-page', this.open);
      setTimeout(function() {
        self.$root.$emit('load-content-player', self.product)
      }, 500);
    }.bind(this));
    EventBus.$on('CourseRatingCount', (data) => {
      this.rates_count = data;
    });
    bus.$on("CloseShare", function () {
      self.shareModalOpen = false;
    })
  },
  updated: function(){
    this.$nextTick(() => {
      ellipsis('.item_detail_description', 5, {
        responsive: true,
        replaceStr: '<span onClick="ShowMore(\''+'details_desc_truncate'+'\')" data-toggle="tooltip" title="Click to see more." class="cursor_pointer">...</span>'
      });
      ellipsis('.creator_summary', 5,{
        responsive: true,
        replaceStr: '<span onClick="ShowMore(\''+'summary_desc_truncate'+'\')" data-toggle="tooltip" title="Click to see more." class="cursor_pointer">...</span>'
      });
    })
  },
  methods: {
    toggleCourseComment(label) {
      this.course_comment = (label == 'comment')? true : false
    },
    FetchContent() {
      var self = this;
      var per_page = self.catalog ? 4 : 4
      self.$http.get('/contents.json?name='+self.permalink+'&per_page='+per_page).then(response => {
        self.items = response.body.detail_item;
        self.similar_products_item = response.body.similar_products_item;
        self.current_page = response.body.current_page;
        self.total_pages = response.body.total_pages;
        if(self.total_pages > 1) {
          self.next_icon=true;
        }
        self.score_breakup = self.items.score_breakup;
        self.total_ratings = self.items.total_ratings;
        jQuery(".item_detail_contents").slimScroll({
          barClass:'lxascroll',
          size : '3px',
          // height: '414px',
          width: '100%',
          height : '100%',
          position : 'right',
          start : 'top',
          alwaysVisible : false,
          distance: '0',
          borderRadius: '0',
          // railVisible: true,
          railBorderRadius:0,
          opacity:1,
          allowPageScroll: true,
          touchScrollStep:200,
          enableKeyNavigation: true
        });
        // jQuery('.item_detail_contents').niceScroll({cursorcolor:"#0066b3",cursorwidth:"7px",autohidemode: "scroll", horizrailenabled: false, nativeparentscrolling: false});
      });
    },
    launch() {
      this.$parent.launch_item = true;
    },
    getCourseCommentNotes(label) {
      var active_class = 'active menu_active'
      var default_class = 'menu p-0 pb-1 mr-3 cursor_pointer '
      if (label == 'comment') {
        return this.course_comment ? (default_class + active_class) : default_class
      } else {
        return this.course_comment ? default_class : (default_class + active_class)
      }
    },
    next_page_similar_course: function() {
      var self = this;
      var new_page = this.current_page + 1;
      var params_per_page = '&page='+new_page+'&per_page='+similar_course_per_page();
      this.prev_icon=true;
      if(new_page == this.total_pages) {
        this.next_icon=false;
      }
      this.$http.get('/contents.json?name='+self.permalink+params_per_page).then(function(response) {
        this.similar_products_item = response.body.similar_products_item;
        this.current_page = response.body.current_page;
        this.total_pages = response.body.total_pages;
      });
    },
    prev_page_similar_course: function() {
      var self = this;
      var new_page = this.current_page - 1;
      var params_per_page = '&page='+new_page+'&per_page='+similar_course_per_page();
      if(new_page == 1) {
        this.prev_icon=false;
      }
      if(new_page != this.total_pages) {
        this.next_icon=true;
      }
      this.$http.get('/contents.json?name='+self.permalink+params_per_page).then(function(response) {
        this.similar_products_item = response.body.similar_products_item;
        this.current_page = response.body.current_page;
        this.total_pages = response.body.total_pages;
      });
    },
    openSharePopup: function() {
      this.shareModalOpen = true;
    },
    add_to_library: function() {
      var self = this;
      if (this.logged_in) {
        if (!this.product.added_to_cart) {
          if (this.product['free?']) {
            self.add_to_my_learning();
          }
          else {
            this.$http.post(this.product.add_to_cart_path).then(response => {
              if (response.body) {
                jQuery('#badge_cart').show()
                jQuery('#badge_cart').html(response.body.cart_size)
                this.added_to_cart = true
                this.$root.popUpMessage('Added to cart Successfully')
              }
            });
          }
        }
      } else {
        window.scrollTo(0, 0);
        $('#login_modal').modal('show');
      }
    },
    add_to_my_learning: function() {
      var self = this;
      this.$http.post(this.product.add_to_library_path).then(response => {
        if (response.body) {
          this.$http.get('/my_learnings/upcoming/'+this.permalink+'/get_details.json').then(response => {
            this.product = response.body
            this.$root.popUpMessage("Enrolled Successfully");
            this.catalog = false;
            this.show_bookmark = false;
          });
        }
        else {
          self.added_to_library = true;
        }
      });
    },
    ToggleContent: function() {
      this.truncate = !this.truncate;
    },
    ToggleSummary: function() {
      this.truncate_summary = !this.truncate_summary;
    }
  }
});

Vue.component('tile-rate-item', {
  template: `<div class="modal fade rate_details" id="rate_detail" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content rate_modal_content lxa_border_radius">
        <div class="modal-body padding_0 col-12">
          <div class="close_rate" v-on:click="onClick" data-toggle="tooltip" data-placement="top" title="Close"><i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></div>
          <div v-bind:id="popup_id" class="rating_detail p-3">
            <div class="rating_star_breakup">
              <div class="rate_course lxa_font_size_08 lxa_fontfamily lxa_text_color_02 font_barlow_semi_condensed_light_24"> <span v-if="!rate">Ratings</span><span v-else>Rate Course </span></div>
              <ul class='filter pt-3'>
                <li v-if="!rate" class="widget_title_medium">{{this.rating_details.title}}</li>
                <li v-else class="widget_title_medium">{{this.product.title}}</li>
                <li class="pb-3 d-flex w-100" v-for="star in star_rating">
                  <div class="d-flex align-items-center justify-content-start">
                  <div :id="'rating_radio_wrapper'+ star" class="rating_radio_wrapper custom-controls custom-radio d-flex align-items-center justify-content-start">
                          <input :id="'rating_radio'+ star" name="ratings" class="rating_radio custom-control-input lxa-bs-radiobtn" type="radio" v-bind:value="star">
                          <label class="custom-control-label" :for="'rating_radio'+ star">
                            <i class="fad fa-circle  mr-1 lxa_icon_size lxa_text_color_01"></i>
                            <i class="fad fa-dot-circle  mr-1 lxa_icon_size lxa_text_color_01"></i>
                            <span></span>
                          </label>
                        </div>
                  <div v-bind:id="'star' + star" class="rating_container pl-2">
                    <input disabled="disabled" v-bind:id="ratings_id + star" type="text" class="kv-uni-star rating-loading" data-size="xs" :value="star" title="">
                  </div>
                  <div id="star_score" class="pl-2"> {{score_breakup[star]}} </div>
                  </div>
                </li>
              </ul>
              <div v-if="!rate" class="pull-right widget_submit_padding"><button id="submit_rate" v-on:click="onClick()" type="submit" class="share_submit btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('common.cancel').toUpperCase()}}</button>
              </div>
              <div v-else class="pull-right widget_submit_padding"><button id="submit_rate" v-on:click="onrateSubmit()" type="submit" class="share_submit btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3">{{$t('common.submit').toUpperCase()}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>`,
  props: {
    popup_id: String,
    product: Object,
    ratings_url: String,
    logged_in: Boolean,
    rates_count: Number,
    average_rating: Number,
    rated_by_user: Boolean,
    completed: Boolean
  },
  data: function() {
    return {
      rating_details: [],
      score_breakup: [],
      star_rating: [1,2,3,4,5],
      // rated_by_user: false,
      count: this.rates_count,
      rating: this.average_rating,
      ratings_id: "ratings_" + new Date().getTime(),
      rate: (this.completed && !this.rated_by_user)
    }
  },
  mounted: function() {
    var self = this;
    var url = this.ratings_url;
    var i;
    for (i = 0; i < 6; i++) {
      jQuery('#'+this.ratings_id + i).rating({
        theme: 'krajee-uni',
        filledStar: '&#x2605;',
        emptyStar: '&#x2606;',
        showClear: false,
        showCaption: false,
        step: 1
      });
    }
    this.$http.get(self.ratings_url).then(response => {
      self.rating_details = response.body;
      self.score_breakup = self.rating_details.score_breakup;
    });
    if(this.count > 0) {
      if (this.rated_by_user) {
        var rated_user_rating = this.product.user_rating;
        jQuery("#"+this.popup_id+" input[name=ratings][value=" + rated_user_rating + "]").attr('checked', 'checked');
      }
      jQuery("#"+this.popup_id+" input[name=ratings]").attr('disabled',true);
    }
  },
  methods: {
    onClick: function() {
      // this.$parent.item_rate = false;
      // this.$parent.comment_rate_item =  false;
      jQuery('#rate_detail').modal('toggle');
    },
    onrateSubmit: function() {
      var value = jQuery('input[name="ratings"]:checked').val();
      var self = this;
      if(self.logged_in) {
        self.$http.post(self.ratings_url+'&rating='+value).then(response => {
          self.$parent.$parent.product.user_rating = value;
          self.$parent.rating = response.body;
          self.$parent.count += 1;
          self.$parent.rated_by_user = true;
          self.count += 1;
          self.rate = false;
          jQuery("#"+this.popup_id+" input[name=ratings]").attr('disabled',true);
        });
      } else {
        show_login_popup(self.$root);
      }
      this.onClick();
    }
  }
});

Vue.component('tile-details-share-popup', {
  template: `<div class="modal fade share_details" id="share_detail" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content share_modal_content">
        <h5 class="modal-title">Share</h5>
        <div class="modal-body padding_0 col-lg-12 col-md-12 col-sm-12">
          <div v-if="from_catalog" class="close_share" data-dismiss="modal" data-toggle="tooltip" data-placement="top" title="Close"><i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer" data-dismiss="modal"></i></div>
          <div v-else class="close_share" data-dismiss="modal" data-toggle="tooltip" data-placement="top" title="Close"><i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer" data-dismiss="modal"></i></div>
          <div v-bind:id="share_popup_id" class="share_widget">
            <div class="widget_container col-lg-12 col-md-12 col-sm-12 padding_0">
              <div class="share_links_cont">
                <span class="share_link selected" v-on:click="onClick('invite', $event)">{{$t('share_popup_filter.invite')}}</span>
                <span class="comment_separator d-none"></span>
                <span class="share_link" v-on:click="onClick('link', $event)">{{$t('share_popup_filter.link')}}</span>
                <span class="comment_separator d-none"></span>
                <span class="share_link" v-on:click="onClick('embed', $event)">{{$t('share_popup_filter.embed')}}</span>
                <span class="comment_separator d-none"></span>
                <span class="share_link" v-on:click="onClick('social', $event)">{{$t('share_popup_filter.social')}}</span>
                <span v-if="show_share_for_edit" class="comment_separator d-none"></span>
                <span v-if="show_share_for_edit && !from_content_player" class="share_link" v-on:click="onClick('share_for_edit', $event)">{{$t('share_popup_filter.share_for_edit')}}</span>
              </div>
              <share_invite_popup v-if="share_type_open == 'invite'" v-bind:popup_id="popup_id" :shareable_id="shareable_id" :shareable_type="shareable_type" :product_recommendation_path="product_recommendation_path" :from_item_tile_detail="true"/>
              <share_link_popup v-if="share_type_open == 'link'" v-bind:popup_id="popup_id" :link_item="link_item" :from_item_tile_detail="true"/>
              <share_embed_popup v-if="share_type_open == 'embed'" v-bind:popup_id="popup_id" :embed_item="embed_item" :embed_url="embed_url" :from_item_tile_detail="true" :from_content_player="from_content_player"/>
              <share_social_popup v-if="share_type_open == 'social'" v-bind:popup_id="popup_id" :title="title" :link_item="link_item" :from_item_tile_detail="true"/>
              <share_for_edit_popup v-if="share_type_open == 'share_for_edit' && !from_content_player" v-bind:popup_id="share_popup_id" :shareable_id="shareable_id" :shareable_type="shareable_type" :product_permission_path="product_permission_path" :from_item_tile_detail="true"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>`,
  props: {
    share_popup_id: String,
    item_share_link: Object,
    title: String,
    shareable_id: Number,
    logged_in: Boolean,
    from_catalog: String,
    from_content_player: {default: false, type: Boolean}
  },
  mixins: [itemShareMixins],
  mounted: function() {
    var self = this
    jQuery('#share_detail').on('shown.bs.modal', function() {
      if (jQuery('.modal-backdrop:eq(1)').length > 0) {
        jQuery('#share_detail').css('z-index', 1055);
        jQuery('.modal-backdrop:eq(1)').css('z-index',1050)
      }
    });
    jQuery('#share_detail').on('hidden.bs.modal', function() {
      bus.$emit("CloseShare")
    });
  }
});

Vue.component('tile-comment', {
  template: `<div v-bind:class="[{margin_0: reply},'row']">
              <hr v-if="index > 0" class="w-100 line-separator">
              <div class="col-12 p-0 d-flex">
                <div class="p-0">
                <img class="thumbnail_very_large" :src="comment.user_photo_url">
                </div>
                <div class="pl-2 comment">
                  <div class="row mx-0">
                    <div class="col-8 p-0 font_barlow_semi_condensed_medium_16">
                      {{comment.user_name}}
                    </div>
                    <div class="col-4 p-0 time_container">
                      <span class="time_ago">{{comment.created_at}}</span>
                    </div>
                  </div>
                  <div id="comment_description" class="col-12 p-0 m-0 font_barlow_semi_condensed_light_14">
                    <div v-show="truncate" v-bind:id="'all_comment_text_'+comment_id" class="announcement_text_display lxa_font_size_03 lxa_text_color_02 lxa_light_font">{{comment.text}}</div>
                    <div v-show="!truncate" class="announcement_text_display lxa_font_size_03 lxa_text_color_02 lxa_light_font">{{comment.text}}<span v-on:click="ToggleContent"><i class="fad fa-chevron-up read_more_arrow"></i></span></div>
                  </div>
                  <div v-bind:id="'comment_truncate'+comment_id" v-on:click="ToggleContent" class="hide"></div>
                  <div class="rply_cmt_row mx-0 d-flex pt-2">
                    <div v-if="reply_to_comment" class="rply_cmt_colL">
                      <write_comment v-bind:current_user_details="current_user_details" :product="product" :ratings_url="ratings_url" :logged_in="logged_in" :rated_by_user="rated_by_user" :user_rating="user_rating" :reply="true" :reply_url="comment.reply_url" :from_item_tile_detail="true"/>
                    </div>
                    <div v-else-if="replies.length > 0" class="rply_cmt_colL lxa_text_color_06 font_barlow_semi_condensed_light_14">
                      <span v-if="!hide_reply" v-on:click="hide_reply = true" class="cursor_pointer">{{$tc('item.hide_reply',replies.length , {count: replies.length})}}</span>
                      <span v-else v-on:click="hide_reply = false" class="cursor_pointer">{{$tc('item.show_reply',replies.length , {count: replies.length})}}</span>
                    </div>
                    <div v-else class="rply_cmt_colL">
                    </div>
                    <div id="reply_container" class="rply_cmt_colR ml-auto">
                    <div class="d-flex align-items-center justify-content-end">
                      <span v-if="logged_in" v-on:click="reply_comment(comment.reply_url)" data-toggle="tooltip" data-placement="top" title="Reply"><i class="lxa_text_color_06 fad fa-reply lxa_icon_size cursor_pointer"></i></span>
                      <span class="comment_separator"></span>
                      <span v-on:click="like_unlike_comment(like_unlike_url)" data-toggle="tooltip" data-placement="top" title="Like"><i class="fad fa-thumbs-up lxa_text_color_05 lxa_icon_size cursor_pointer"></i></span>
                      <span v-if="likes_count > 0" class="like_count lxa_bg_01">{{likes_count}}</span>
                      <span v-if="(logged_in&&comment.can_be_deleted)" class="pl-3" v-on:click="delete_comment()" data-toggle="tooltip" data-placement="top" title="Delete"><i class="lxa_text_color_01 fad fa-trash lxa_icon_size cursor_pointer"></i></span>
                    </div>
                    </div>
                  </div>
                  <div v-if="!hide_reply" class="replies_container mt-2">
                    <tile-comment v-for="(comment,index) in replies" :key="'Comment'+index+new Date().getTime()" :index="index" :comment="comment" :reply="true" :product="product" :ratings_url="ratings_url" :rated_by_user="rated_by_user" :user_rating="user_rating" :current_user_details="current_user_details" :logged_in="logged_in"/>
                  </div>
                </div>
              </div>
            </div>`,
  props: {
    comment: Object,
    current_user_details: Object,
    rated_by_user: Boolean,
    user_rating: Number,
    logged_in: Boolean,
    product: Object,
    ratings_url: String,
    reply: Boolean,
    index: Number
  },
  mixins: [itemCommentMixins]
});

Vue.component('details-share-popup', {
  template: `<div v-bind:id="share_popup_id" class="share_widget">
              <div class="widget_container col-lg-12 col-md-12 col-sm-12 padding_0">
                <img class="arrow" src="/images/balloon_popup_down_arrow.png"/>
                <div class="share_links_cont">
                  <span class="share_link" v-on:click="onClick('invite', $event)">{{$t('share_popup_filter.invite')}}</span>
                  <span class="comment_separator">|</span>
                  <span class="share_link selected" v-on:click="onClick('link', $event)">{{$t('share_popup_filter.link')}}</span>
                  <span class="comment_separator">|</span>
                  <span class="share_link" v-on:click="onClick('embed', $event)">{{$t('share_popup_filter.embed')}}</span>
                  <span class="comment_separator">|</span>
                  <span class="share_link" v-on:click="onClick('social', $event)">{{$t('share_popup_filter.social')}}</span>
                  <span v-if="show_share_for_edit" class="comment_separator">|</span>
                  <span v-if="show_share_for_edit" class="share_link" v-on:click="onClick('share_for_edit', $event)">{{$t('share_popup_filter.share_for_edit')}}</span>
                </div>
                <share_invite_popup v-if="share_type_open == 'invite'" v-bind:popup_id="popup_id" :shareable_id="shareable_id" :shareable_type="shareable_type" :product_recommendation_path="product_recommendation_path"/>
                <share_link_popup v-if="share_type_open == 'link'" v-bind:popup_id="popup_id" :link_item="link_item"/>
                <share_embed_popup v-if="share_type_open == 'embed'" v-bind:popup_id="popup_id" :embed_item="embed_item" :embed_url="embed_url"/>
                <share_social_popup v-if="share_type_open == 'social'" v-bind:popup_id="popup_id" :title="title" :link_item="link_item" />
                <share_for_edit_popup v-if="share_type_open == 'share_for_edit'" v-bind:popup_id="share_popup_id" :shareable_id="shareable_id" :shareable_type="shareable_type" :product_permission_path="product_permission_path"/>
              </div>
            </div>`,
  props: {
    share_popup_id: String,
    item_share_link: Object,
    title: String,
    shareable_id: Number,
    logged_in: Boolean,
    share_type_open: String
  },
  mixins: [itemShareMixins]
});

Vue.component('comments', {
  template: `<div class="col-12 p-0">
              <div class="col-12 px-0">
              <div class="row mb-2" :class="{'lxa_commom_border_b2': comments.length>0}">
                <p v-show="comments.length>0" class="col-6 lxa_main_title_light lxa_text_color_05 pl-0"> {{$tc('item.comments', comments.length, {count: comments.length})}} </p>
                <div v-show="comments.length>0" :class="[{'col-6 pr-0 text-right': from_item_tile_detail}, {'col-12': !from_item_tile_detail}]" v-bind:id="[from_item_tile_detail ? 'comments_header' : '']">
                  <span v-if="from_item_tile_detail" v-bind:class="[newest ? 'sort_comments cursor_pointer' : 'sort_comments lxa_text_color_05']" v-on:click="showComments(false)">{{$t('item.most_helpul')}}</span>
                  <span v-else v-bind:class="[newest ? 'font_barlow_semi_condensed_light_14 cursor_pointer' : 'font_barlow_semi_condensed_light_14']" v-on:click="showComments(false)">{{$t('item.most_helpul')}}</span>
                  <span class="comment_separator"></span>
                  <span v-if="from_item_tile_detail" v-bind:class="[newest ? 'sort_comments lxa_text_color_05' : 'sort_comments cursor_pointer']" v-on:click="showComments(true)">{{$t('item.newest_first')}}</span>
                  <span v-else v-bind:class="[newest ? 'font_barlow_semi_condensed_light_14' : 'font_barlow_semi_condensed_light_14 cursor_pointer']" v-on:click="showComments(true)">{{$t('item.newest_first')}}</span>
                </div>
                </div>
              </div>
              <div id="comments_cont" :class="[{'col-12': !from_item_tile_detail}, 'padding_left_0']" v-infinite-scroll="loadMoreComments" infinite-scroll-disabled="busy" infinite-scroll-distance="10" infinite-scroll-immediate-check="false">
                <tile-comment v-if="from_item_tile_detail" v-for="(comment,index) in comments" :index="index" :key="'Comment'+index+new Date().getTime()" :product="product" :reply="false" :ratings_url="ratings_url" :comment="comment" :rated_by_user="rated_by_user" :user_rating="user_rating" :current_user_details="current_user_details" :logged_in="logged_in"/>
                <comment v-else v-for="(comment,index) in comments" :key="'Comment'+index+new Date().getTime()" :comment="comment" :rated_by_user="rated_by_user" :user_rating="user_rating" :current_user_details="current_user_details" :logged_in="logged_in"/>
              </div>
              <p v-show="comments.length==0" class="col-12 font_barlow_semi_condensed_light_14 lxa_text_color_05"> {{$tc('item.be_the_first_to_comment')}} </p>
              <p v-show="comments.length==0" class="col-12 font_barlow_semi_condensed_light_12"> {{$tc('item.no_comments_yet')}} </p>
              <write_comment v-if="logged_in" v-bind:current_user_details="current_user_details" :product="product" :ratings_url="ratings_url" :logged_in="logged_in" :rated_by_user="rated_by_user" :user_rating="user_rating" :reply="false" :from_item_tile_detail="from_item_tile_detail"/>
            </div>`,
  props: {
    comments: Array,
    logged_in: Boolean,
    comments_count: Number,
    current_user_details: Object,
    rated_by_user: Boolean,
    user_rating: Number,
    product: Object,
    ratings_url: String,
    from_item_tile_detail: Boolean
  },
  data: function() {
    return {
      newest: false,
      busy: false,
    }
  },
  computed: {
    comments_list: function(){
      return (this.comments || []);
    }
  },
  mounted: function() {
    jQuery("#comments_cont").slimScroll({
      barClass:'lxascroll',
      size : '3px',
      // height: '414px',
      width: '100%',
      height : '100%',
      position : 'right',
      start : 'top',
      alwaysVisible : false,
      distance: '0',
      borderRadius: '0',
      // railVisible: true,
      railBorderRadius:0,
      opacity:1,
      allowPageScroll: true,
      touchScrollStep:200,
      enableKeyNavigation: true
    });
    // if(!this.from_item_tile_detail) {
    //   jQuery("#comments_cont").slimScroll({
    //     barClass:'lxascroll',
    //     size : '3px',
    //     // height: '414px',
    //     width: '100%',
    //     // height : '100%',
    //     position : 'right',
    //     start : 'top',
    //     alwaysVisible : false,
    //     distance: '0',
    //     borderRadius: '0',
    //     // railVisible: true,
    //     railBorderRadius:0,
    //     opacity:1,
    //     allowPageScroll: true,
    //     touchScrollStep:200,
    //     enableKeyNavigation: true
    //   });
    //   jQuery("#comments_cont").niceScroll({cursorcolor:"#0066b3",cursorwidth:"7px",autohidemode: "scroll", horizrailenabled: false, nativeparentscrolling: false});
    // }
    // else {
    //   jQuery("#comments_cont").niceScroll({cursorcolor:"#fa7d00",cursorwidth:"7px",autohidemode: "scroll", horizrailenabled: false, nativeparentscrolling: false});
    // }
  },
  methods: {
    showComments: function(newest) {
      if (this.newest != newest) {
        if(newest) {
          this.newest = true;
          this.$parent.load_comments(true);
        } else {
          this.newest = false;
          this.$parent.load_comments(false);
        }
      }
    },
    loadMoreComments: function() {
      var self = this;
      self.busy = true;
      if(self.$parent.pagination.page && (self.$parent.pagination.page <= self.$parent.pagination.total_pages)) {
        self.$parent.load_comments(self.$parent.newest, true);
        self.busy = false;
      }
    }
  }
});

Vue.component('comment', {
  template: `<div class="row padding_top_20">
              <div class="pull-left">
                <img class="thumbnail_very_large" src="/images/paperclip/uploaded_data/missing_thumbnail.png">
              </div>
              <div class="pull-left padding_left_10 padding_bottom_10 comment">
                <p class="margin_bottom_0 font_open_sans_regular_12_blue cursor_pointer" style="margin-top: -2px;">
                  <a v-bind:href="comment.user_profile_url" target="_blank">{{comment.user_name}}</a>
                </p>
                <input v-if="rated_by_user" :id="ratings_id" disabled="disabled" type="text" class="kv-uni-star rating-loading" :value="user_rating" data-size="xs" title="">
                <p class="padding_top_5 margin_bottom_0 font_barlow_semi_condensed_light_14">{{comment.text}}</p>
                <p class="margin_bottom_0">
                  <span class="time_ago">{{comment.created_at}}</span>
                  <span v-if="logged_in" class="comment_separator">|</span>
                  <a v-if="logged_in" v-on:click="reply_comment(comment.reply_url)" class="font_open_sans_regular_11_blue">{{$t('common.reply')}}</a>
                  <span class="comment_separator">|</span>
                  <a v-on:click="like_unlike_comment(like_unlike_url)" class="font_open_sans_regular_11_blue">{{liked ? $t('common.unlike') : $t('common.like')}}</a>
                  <span v-if="likes_count > 0" class="like_count">{{likes_count}}</span>
                  <span v-if="logged_in" class="comment_separator">|</span>
                  <a v-if="logged_in && comment.can_be_deleted" v-on:click="delete_comment(comment.delete_url)" class="font_open_sans_regular_11_blue">{{$t('common.delete_string')}}</a>
                </p>
              </div>
              <hr class="comment_border"/>
              <div class="replies_container">
                <comment v-for="comment in replies" :key="'Comment'+comment.id" :comment="comment" :rated_by_user="rated_by_user" :user_rating="user_rating" :current_user_details="current_user_details"/>
              </div>
              <write_comment v-if="reply_to_comment" v-bind:current_user_details="current_user_details" :rated_by_user="rated_by_user" :user_rating="user_rating" :reply="true" :reply_url="comment.reply_url"/>
            </div>`,
  props: {
    comment: Object,
    current_user_details: Object,
    rated_by_user: Boolean,
    user_rating: Number,
    logged_in: Boolean
  },
  mixins: [itemCommentMixins],
  mounted: function() {
    var self = this;
    if(self.rated_by_user) {
      setTimeout(function() {
        jQuery('#item_details_lightbox .kv-uni-star').rating({
          theme: 'krajee-uni',
          filledStar: '&#x2605;',
          emptyStar: '&#x2606;',
          showClear: false,
          showCaption: false,
          step: 1
        });
      }, 300);
    }
  }
});

Vue.component('write_comment', {
  template: `<div v-bind:class="[{'': reply}, reply ? 'w-100' : 'col-lg-12 col-md-12 col-sm-12 padding_top_10']">
              <p v-if="!reply && !from_item_tile_detail" class="margin_bottom_2 comment_txt">{{$tc('item.add_your_comment')}}</p>
              <div v-if="!from_item_tile_detail" class="pull-left">
                <img class="thumbnail_very_large" src="/images/paperclip/uploaded_data/missing_thumbnail.png">
              </div>
              <div class="pull-left add_comment">
                <div v-if="!reply && !from_item_tile_detail" style="margin-top: -9px;">
                  <span class="font_open_sans_regular_14_dark_gray">{{$t('item.your_rating')}}</span>
                  <input v-if="rated_by_user" disabled="disabled" v-bind:id="ratings_id" type="text" class="kv-uni-star rating-loading" :value="user_rating" data-size="xs" title="">
                  <input v-else v-bind:id="ratings_id" type="text" class="kv-uni-star rating-loading" data-size="xs" title="">
                </div>
                <form v-bind:id="form_id" v-on:submit.prevent="onSubmit" role="form" v-bind:class="{'d-flex': reply}">
                  <textarea v-if="!reply" class="w-100 comment_textarea p-3 lxa_font_size_03 lxa_light_font txtarea-resize border-0" name="comment[body]" :placeholder="$t('new_comment.write_a_comment')" v-validate="'required'" data-vv-validate-on="none" v-model="comment"></textarea>
                  <textarea v-else class="w-100 lxa_font_size_02 lxa_light_font comment_reply pl-2 pt-2 txtarea-resize border-0" name="comment[body]" v-validate="'required'" data-vv-validate-on="none" v-model="comment"></textarea>
                  <div class="error_container hide">
                    <span class ="lxa_font_size_02 lxa_text_color_01 inline_block" v-show="errors.has('comment[body]')" id="comment_error">{{ $t('new_comment.enter_comment') }}</span>
                  </div>
                  <div class="text-right pr-0">
                    <button v-if="from_item_tile_detail&&!reply" type="submit" class="btn lxa_bg_02 btn-sm ml-2 mt-2 lxa_button_title_light_1_a lxa_btn_txt_01 lxa_border_radius font_barlow_semi_condensed_14 text-uppercase">{{$t('item.add_your_comment')}}</button>
                    <button v-else-if="reply" type="submit" class="btn lxa_bg_02 btn-sm lxa_button_title_light_1_a lxa_btn_txt_01 lxa_border_radius font_barlow_semi_condensed_14 text-uppercase ml-3">{{$t('common.submit')}}</button>
                    <button v-else type="submit" class="comment_submit btn btn-default">{{$t('item.add_comment')}}</button>
                  </div>
                </form>
              </div>
            </div>`,
  props: {
    current_user_details: Object,
    rated_by_user: Boolean,
    user_rating: Number,
    reply: Boolean,
    reply_url: String,
    product: Object,
    ratings_url: String,
    logged_in: Boolean,
    from_item_tile_detail: Boolean
  },
  data: function() {
    return {
      ratings_id: 'ratings_' + new Date().getTime(),
      form_id: 'post_new_comment_' + new Date().getTime(),
      rating: this.product.user_rating,
      comment: '',
      popup_id: "rating_details_"+new Date().getTime(),
      comment_rate_item: false
    }
  },
  mounted: function() {
    var self = this;
    if(this.from_item_tile_detail == false){
      jQuery('#'+this.ratings_id).rating({
        theme: 'krajee-uni',
        filledStar: '&#x2605;',
        emptyStar: '&#x2606;',
        showClear: false,
        showCaption: false,
        step: 1
      });
    }
    jQuery('#'+this.ratings_id).on('rating.change', function(event, target) {
      self.rating = this.value;
    });
  },
  methods: {
    onSubmit: function() {
      var self = this;
      this.$validator.validateAll().then(result => {
        var cont, url;
        if (result) {
          jQuery("#"+self.form_id).find('button').addClass('disabled');
          var comment_text = jQuery("#"+self.form_id).find('input[type=text], textarea').val();
          if(this.from_item_tile_detail == true){
            var params = '&comment='+encodeURIComponent(comment_text)+'&from_details=true';
          }else {
            var params = '&'+jQuery("#"+self.form_id).serialize()+'&from_details=true';
          }
          if(self.rating && !self.reply)
            params += '&rating='+self.rating;
          if(self.reply) {
            cont = self.$parent.replies;
            url = self.reply_url;
          } else {
            cont = self.$parent.$parent.comments;
            url = self.current_user_details.create_comment_url;
          }
          this.$http.post(url+params).then(response => {
            if(response.body) {
              cont.push(response.body);
              if(self.reply) {
                self.$parent.reply_to_comment = false;
              } else if(self.rating && !self.rated_by_user) {
                self.$parent.$parent.rated_by_user = true;
                self.$parent.$parent.user_rating = parseInt(self.rating);
              }
              jQuery("#"+self.form_id).find('input[type=text], textarea').val('');
              jQuery("#"+self.form_id).find('button').removeClass('disabled');
              this.comment = ''
            }
          });
        } else {
          this.$root.popUpMessage("Comment text Can't empty.");
        }
      });
    },
    rate_by_comment_item: function() {
      this.comment_rate_item = true;
    }
  }
});

Vue.component('item-loader', {
  template: `<div class="circular_loader hidden">
              <svg width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <g transform="rotate(67.5 33 33)">
                  <animateTransform attributeName="transform" type="rotate" values="0 33 33;270 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite"></animateTransform>
                  <circle fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" stroke-dasharray="187" stroke-dashoffset="610" transform="rotate(67.5 33 33)">
                    <animate attributeName="stroke" values="#4184f3" begin="0s" dur="5.6s" fill="freeze" repeatCount="indefinite"></animate>
                    <animateTransform attributeName="transform" type="rotate" values="0 33 33;135 33 33;450 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="stroke-dashoffset" values="187;46.75;187" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite"></animate>
                  </circle>
                 </g>
              </svg>
            </div>`
});

Vue.component('next-arrow-left', {
  template: `<button class="arrow left jcarousel-control-prev hide">
              <svg width="25px" height="40px" viewBox="0 0 50 80" xml:space="preserve">
                <polyline fill="none" stroke="#4184f3" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" points="45.63,75.8 0.375,38.087 45.63,0.375 "/>
              </svg>
            </button>`
});

Vue.component('next-arrow-right', {
  template: `<button class="arrow right jcarousel-control-next hide">
              <svg width="25px" height="40px" viewBox="0 0 50 80" xml:space="preserve">
                <polyline fill="none" stroke="#4184f3" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" points="0.375,0.375 45.63,38.087 0.375,75.8 "/>
              </svg>
            </button>`
});

Vue.component('recommended-items-list', {
  template: `<div class="slider-wrapper">
              <div class="slider_container">
                <available-item-category-list v-bind:container_name="container_name" :list="list" :logged_in="logged_in" :ref="list" :shared_item = "shared_item" v-for="list in list_arr"/>
              </div>
              <a class="slider-prev" v-on:click="onSeePrevClick" v-show="current_page != 1">
                <img src="/images/single_page/left_arrow.svg" />
              </a>
              <a class="slider-next" v-on:click="onSeeNextClick" v-show="current_page != total_pages">
                <img src="/images/single_page/right_arrow.svg"/>
              </a>
            </div>`,
  props: {
    item: Object,
    logged_in: Boolean,
    block_name: String,
    shared_item: Number
  },
  data: function() {
    return {
      list_arr: [this.item],
      params: Object.values(this.item)[0].params,
      current_page: Object.values(this.item)[0].params.current_page,
      total_pages: Object.values(this.item)[0].params.total_pages,
      container_name: this.block_name+'_container',
      processing: false
    }
  },
  methods: {
    onSeePrevClick: function() {
      jQuery("#"+Object.keys(this.item)[0].replace(' ','_')+'_'+this.current_page).hide();
      this.current_page = this.current_page - 1;
      jQuery("#"+Object.keys(this.item)[0].replace(' ','_')+'_'+this.current_page).show();
      var block_name = Object.keys(this.item)[0].replace(' ','_')+'_'+this.current_page;
      truncateItemText(block_name);
    },
    onSeeNextClick: function() {
      if(!this.processing) {
        var self = this;
        self.processing = true;
        var page = self.current_page + 1;
        if(jQuery("#"+Object.keys(this.item)[0].replace(' ','_')+'_'+page).length == 0) {
          var url = '/contents/required_recommended_courses.json';
          url += '?per_page='+items_per_page()+'&page='+page;
          this.$http.get(url).then(function(response) {
            var res = response.body[0];
            jQuery("#"+Object.keys(res)[0].replace(' ','_')+'_'+self.current_page).hide();
            self.params = Object.values(res)[0].params;
            self.current_page = self.params.current_page;
            self.total_pages = self.params.total_pages;
            self.list_arr.push(res);
            setTimeout(function() {
              truncateItemText("recommended_courses");
            }, 500);
            self.processing = false;
          });
        } else {
          jQuery("#"+Object.keys(this.item)[0].replace(' ','_')+'_'+this.current_page).hide();
          this.current_page = this.current_page + 1;
          jQuery("#"+Object.keys(this.item)[0].replace(' ','_')+'_'+this.current_page).show();
          var block_name = Object.keys(this.item)[0].replace(' ','_')+'_'+this.current_page
          truncateItemText(block_name);
          self.processing = false;
        }
      }
    }
  }
});

Vue.component('store-front-items', {
  template: `<div>
              <div class="container-fluid padding_left_0 margin_left_min_3 margin_top_2" id="container">
                <div v-if="logged_in" class="row">
                  <div class="col-sm-11">
                    <img alt="Bird" class="img-responsive" v-bind:src="thumbnail_url" style="display:inline;height: 350px" width="100%">
                  </div>
                  <div class="col-sm-1 padding_0">
                    <img alt="Bird" class="img-responsive padding_2" v-bind:src="thumbnail_url" style="display:inline;height: 100px" width="100%">
                    <img alt="Bird" class="img-responsive padding_2" v-bind:src="thumbnail_url_1" style="display:inline;height: 100px" width="100%">
                    <img alt="Bird" class="img-responsive padding_2" v-bind:src="thumbnail_url_2" style="display:inline;height: 100px" width="100%">
                    <div class="font_open_sans_regular_12_blue text_align_center" style="margin-top: 18px;" v-on:click="handleClick()">{{$t('enrolled_item_question_lightbox.about')}}</div>
                    <div id="about_popup" v-if= "about_content" class="custom_popover fade bottom in show" style="margin-left: -428px;">
                     <div class="arrow_img" style="margin-left: 488px;"><img src="/images/single_page/arrow.png" style="margin-top: -38px;"></div>
                     <div class="about_class">{{$t('enrolled_item_question_lightbox.about')}}:</div>
                      <form class="form-horizontal" v-on:submit.prevent="submitData" role="form">
                        <div class="tiny"></div>
                          <div class="row">
                            <div class="col-sm-12 padding_right_2">
                              <button type="submit" v-on:click="submitData" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('common.save').toUpperCase()}}</button>
                              <a id="row_name" v-on:click="cancelSubmit" style="margin-right: 40px;" class="changedetails signout">Cancel</a>
                            </div>
                          </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div v-else class="row padding_0 margin_0">
                  <div class="col-sm-12 padding_0 margin_0">
                    <img alt="Bird" class="img-responsive" v-bind:src="thumbnail_url" style="display:inline;height: 350px" width="100%">
                  </div>
                </div>
              </div>
            </div>`,
  props: {
    logged_in: Boolean
  },
  data() {
    return {
      thumbnail_url: '/images/letter-blocks.jpg',
      thumbnail_url_1: '/images/single_page/8.jpg',
      thumbnail_url_2: '/images/single_page/12.jpg',
      about_content: false
    }
  },
  methods: {
    handleClick: function() {
      this.about_content= true;
      setTimeout(function() {
        tinymce.init({
          selector: 'div.tiny',
          height: 250,
          menubar: false,
          insertdatetime_formats: ["%H:%M:%S", "%Y-%m-%d", "%I:%M:%S %p", "%D"],
          charmap_append: [
            [0x2600, 'sun'],
            [0x2601, 'cloud']
          ],
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar1: 'undo redo | fontselect | fontsizeselect |' +
          'bold italic underline backcolor charmap ' ,
          toolbar2:'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
          'image link media table removeformat | help ',
          content_css: '//www.tiny.cloud/css/codepen.min.css'
        });
      },500);
    },
    cancelSubmit: function() {
      this.about_content = false;
    },
    submitData: function() {
      this.about_content = false;
      var message = tinymce.activeEditor.getContent({format: 'html'});
      this.$http.put('/home/companies/3.json', { company: { description: message} }).then(response => {
      //this.$http.put('/companies/3.json?&description='+message).then(response => {
        this.$set('alert', response.data);
        }, function (response) {
        this.$set('alert', response.data);
        });
    },
  }
});


Vue.component('content-notes', {
  template: `<div class="row mx-0">
             <div class="col-12">
              <div class="submitted_notes  mt-2 mb-3">
                <div class="content_notes row mx-0" v-for="note in courses_notes">
                  <span class="p-0 col-2 font_weight_bold lxa_font_size_04"> {{ note.content_title }} :</span>
                  <span class="p-0 col-7 lxa_font_size_04 pl-2"> {{ note.notes }} </span>
                  <span class="p-0 col-3 lxa_font_size_04 text_align_right">
                    <span v-if="['video', 'audio'].includes(note.content_type)"> {{ noteTime(note) }} </span>
                    <span class="cursor_pointer green_color pl-2" :title="'Go to ' + note.content_title" @click="playContent(note)" data-toggle="tooltip" data-placement="top" title="Play"> <i class="fad fa-play-circle float_right"></i> </span>
                  </span>
                </div>
              </div>
              <div class="lxa_commom_border_b2 w-100"></div>
              <div class="row mx-0 mt-2">
              <div class="col-12 px-0">
                <textarea class="w-100 lxa_border_radius primary_bgcolor new_note p-3 lxa_font_size_04 lxa_light_font txtarea-resize border-0" v-model="new_note" />
                <div class="w-100 text-right">
                <a @click="addNewNote" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase mt-2"> Add Note </a>
                </div>
               </div>
              </div>
              </div>
            </div>`,
  data() {
    return {
      new_note: ''
    }
  },
  props: ["courses_notes", "product", "active_content"],
  methods: {
    playContent(note) {
      if ($( "#content-head-"+note.content_id).hasClass( "active" )) {
        if (['video', 'audio','weblink'].includes(note.content_type)) {
          var myPlayer = videojs('video-content');
          myPlayer.currentTime(note.time);
          myPlayer.play();
        }
      } else {
        this.$parent.$refs.ContentPlayer.$children[0].notes_time = note.time;
        jQuery("#content_details_"+note.content_id).click();
      }
    },
    addNewNote() {
      if (this.new_note != '') {
        var data = {'noteable_type': this.product.item_type, 'noteable_id': this.product.id, 'notes': this.new_note, 'content_id': this.active_content.content_id}
        if (['video', 'audio','weblink'].includes(this.active_content.content_type)) {
          var myPlayer = videojs('video-content');
          var whereYouAt = myPlayer.currentTime();
          data['time'] = whereYouAt
          myPlayer.pause();
        }
        this.$http.post('/notes', data).then(function(response) {
          this.$parent.load_notes()
        });
        this.new_note = ''
      } else {
        this.$root.popUpMessage("Please enter a note")
      }
    },
    noteTime(note) {
      var seconds = note.time
      const format = val => `0${Math.floor(val)}`.slice(-2)
      const hours = seconds / 3600
      const minutes = (seconds % 3600) / 60

      return [hours, minutes, seconds % 60].map(format).join(':')
    }
  }
});

Vue.component('enrolled-bookmark',{
  template: `<div>
              <span v-show="!bookmarked" data-toggle="tooltip" data-placement="top" title="Bookmark" class="cursor_pointer share-section" v-on:click="onBookmarkClick"><i v-bind:class="[details ? 'fad fa-bookmark fa-swap-opacity lxa_icon_size lxa_text_color_07 font_barlow_semi_condensed_light_16' : 'fad fa-bookmark fa-swap-opacity lxa_icon_size lxa_text_color_07 font_barlow_semi_condensed_light_16']"></i><span v-if="details||list" class="pl-1">Bookmark</span></span>
              <span v-show="bookmarked" class="cursor_pointer share-section" data-toggle="tooltip" data-placement="top" title="Bookmarked" v-on:click="removeBookmark"><i v-bind:class="[details ? 'lxa_icon_size lxa_text_color_07 fad fa-bookmark font_barlow_semi_condensed_light_16' : 'lxa_icon_size lxa_text_color_07 fad fa-bookmark font_barlow_semi_condensed_light_16']"></i><span v-if="details||list" class="pl-1">Bookmarked</span></span>
             </div>`,
  props: {
    bookmarked: Boolean,
    bookmarks_url: String,
    logged_in: Boolean,
    bookmark_cancel_url: String,
    details: Boolean,
    list: Boolean
  },
  methods: {
    onBookmarkClick: function(event) {
      if (this.logged_in) {
        var self = this.details ? this.$parent.$parent : this.$parent;
        this.$http.post(this.bookmarks_url).then(response => {
          self.bookmark_cancel_url = response.body.bookmark_cancel_url
          self.product.bookmark_cancel_url = response.body.bookmark_cancel_url
          self.bookmarked = true
          self.product.bookmarked = true
          self.$root.popUpMessage('Bookmarked Successfully');
        });
      }
    },
    removeBookmark: function() {
      if (this.logged_in) {
        var self = this.details ? this.$parent.$parent : this.$parent;
        this.$http.delete(this.bookmark_cancel_url).then(response => {
          self.bookmark_cancel_url = ''
          self.bookmarked = false
          self.product.bookmark_cancel_url = ''
          self.product.bookmarked = false
          self.$root.popUpMessage('Bookmark Removed');
        });
      }
    }
  }
  });
